import { StringMappingType } from 'typescript'
import { DataTableRow } from './ui-components/DataTable/DataTableTypes'
import { NumberFormat } from 'xlsx'
import { boolean } from 'zod'

export type UserAccount = {
  id: number
  accountId: string
  accountTenantId: string
  email: string
  fullName: string
  countryCode: string
  phoneNumber: string
  country: string
  planId?: number
}

export type LucidAccount = {
  accountId: string
  accountName: string
  accountDescription: string
  accountTenantId: string
  registeredUserId: number
  agreementStatus?: string
  securityGroups?: string
  licenceType?: string
  planId?: number
}

export type GetTokenInput = {
  client_id: string
  scope: string
  grant_type: string
  client_secret: string
}

export type DataPodInfo = {
  id: string
  name: string
  description: string
  accountId: string
  industryId: number
  industryName?: string
  datapodType?: number
}

export type NewDataPod = {
  name: string
  description: string
  accountId: string
  industryId: number
  dataLandingStorageAccount: string
  dataLandingStorageAccountKey: string
  dataPodCode: string
  datapodType?: number
}
export type DataPodType = {
  id: number
  dataPodType1: string
  isActive: boolean
  createdDate: string
  updatedDate: string
  createdBy: string
  updatedBy: string
}

export type DataPod = DataPodInfo & {
  id: string
}

export type DataPodCard = DataPod & {
  subjectAreaName: string
}

export type Industry = {
  id: number
  industryName: string
}

/***
 * Date: 7.06.2024
 * DataSystem  in UI is replaced with Data Source
 */

export type DataSystem = {
  id: number
  dataSystemName: string
  dataSystemDesc: string
  industryId: number
  subjectAreaId: number
  container: string
  datapodId: string
  isActive: boolean
  dataSystemType?: number
  serviceConnectionId: number
  serviceConnectionName?: string
}

export type DataSystemType = {
  id: 0
  dataSystemType1: string
  isActive: boolean
  createdDate: string
  updatedDate: string
  createdBy: string
  updatedBy: string
}

export type DataSystemInput = {
  subjectAreaId: number
  name: string
  container?: string
  description: string
  industryID: number
  dataSystemType?: number
  serviceConnectionId: string
}

/***
 * Date: 7.06.2024
 * Subject Area Names in UI is replaced with Data Domain
 */
export type SubjectArea = {
  id: number
  /*+* subjectAreaName replaced with
   * Data Domain */
  subjectAreaName: string
  subjectAreaDesc: string
  industryName: string
  industryId: number
  datapodId: string
  isActive: true
}

/***
 * Date: 7.06.2024
 * Subject Area Names in UI is replaced with Data Domain
 */
export type NewSubjectArea = {
  name: string
  description: string
  industryname: string
  industryId: number
}

export type SampleDataGenerated = {
  columnName: string
  values: string[]
}

export type GenSubjectArea = {
  SubjectAreaName: string
  SubjectAreaDescription: string
}

export type GenApiResp = {
  result: string
}

export type RecommendedSubjectAreas = GenApiResp & {
  data: GenSubjectArea[]
}

export type RunResponseFormat = {
  run_id: number
  number_in_job: number
}

export type RunAnalyticsGroupProfileBody = {
  analyticsMeasureId: number
}

export type AnalyticGroup = {
  id: number
  analyticsGroupID: number
  analyticsGroupName: string
  analyticsMeasureID: number
  analyticsMeasureName: string
  dataPodId: string
  runid: number
}

export type AnalyticsGroupOutput = {
  profileStatus: string
  result: string
  analyticsGroupOutputs: AnalyticGroup[]
}

export type StatusResponse = {
  job_id: number
  run_id: number
  number_in_job: number
  jobStatus: string
  maxretryAllowed: number
  currentcount: number
  waitInSeconds: number
}

export type AnalyticsGroup = {
  id: number
  analyticsGroupName: string
  analyticsGroupDescription: string
  dataPodId: string
  runId: number
}

export type AnalyticsGroupMapping = {
  id: number
  analyticsGroupId: number
  analyticsGroupName: string
  analyticsMeasureId: number
  analyticsMeasureName: string
  dataPodId: string
  runId: number
  lastUpdated: string
}
export type RunAnalyticsMeasureProfileBody = {
  dataEntityID: number
}

export type AnalyticsMeasureOutputMap = {
  id: number
  analyticsMeasureID: number
  analyticsMeasureName: string
  dataEntityID: number
  dataEntityName: string
  dataPodId: string
  runid: number
}

export type AnalyticsMeasureOutput = {
  profileStatus: string
  result: string
  analyticsMeasureOutputMaps: AnalyticsMeasureOutputMap[]
}

export type AnalyticsMeasure = {
  analyticsMeasureId: number
  analyticsMeasureName: string
  analyticsMeasureDescription: string
  dataPodId: string
  measureQuery: string
  analyticsGroupId: number
  analyticsGroupName: string
  analyticsGroupDescription: string
  dataEntityId: number
  dataEntityName: string
  description: string
  coreEntity: any
  dataEntityType: number
}

export type AnalyticsMeasureType = {
  id?: number
  analyticsMeasureName: string
  measureGroupId?: number
  analyticsMeasureDescription: string
  runId: 0
  measureQuery: string
}

export type AnalyticsMeasureMapping = {
  id: number
  analyticsMeasureId: number
  analyticsMeasureName: string
  dataEntityId: number
  dataEntityName: string
  dataPodId: string
  runId: number
  lastupdated: string
}

export type RunAttributeProfileBody = {
  dataTableID: number
  dataEntityID: number
}

export type NewAttributeMapBody = {
  dataTableId?: number
  dataEntityId: number
  dataColumnId?: number
  dataAttributeId: number
  dataEntityMapId?: number
  runid: number
  dataSourceAttributeId?: number
}

export type NewAttributeMap = NewAttributeMapBody & {
  id: number
  dataPodId: string
  createdOn?: string
}

export type attributeMappingDataBody = NewAttributeMapBody & {
  id: number
  dataTableId: number
  dataTableName: string
  dataEntityId: number
  dataEntityName: string
  dataEntityMapId: number
  dataColumnId: number
  dataColumnName: string
  isUnique: number
  dataAttributeId: number
  dataAttributeName: string
  description: string
  dataAttributeDataType: string
  dataClassification: string
  isBusinessKey: boolean
  dataPodId: string
  dataSystemId: number
  dataSystemName: string
  runid: number
  transformation: {
    transformerID: number
    transformerQuery: string
    transformerText: string
    datatransformerType: string
    transformertypeId: number
  }[]
}

export type UpdateAttributeNameBody = {
  id: number
  name: string
}

export type AttributeProfile = {
  id: number
  dataEntityId: number
  entityName: string
  dataAttributeName: string
  dataColumnName: string
  dataPodId: string
  dataAttributeDataType: string
  dataClassification: string
  description: string
  isBusinessKey: boolean
  dataAttributeMapID?: number
  dataAttributeType?: string
  dataColumnId?: number
}

export type UpdateAttributeMapBody = {
  id: number
  dataTableId: number
  dataEntityId: number
  dataColumnId: number
  dataAttributeId: number
  dataEntityMapId: number
}

export type NewAttribute = {
  dataEntityId: number
  dataAttributeName: string
  dataAttributeDataType: string
  description: string
  isBusinessKey: boolean
  dataClassification: string
  dataAttributeType: string
}

export type DataAttribute = NewAttribute & {
  id: number
  dataPodId?: string
  isActive?: boolean
}
export type UpdateAttributeMapResponse = UpdateAttributeMapBody & {
  dataPodId: string
  runId: number
}

export type GetAttributeProfileOutput = {
  profileStatus: string
  result: string
  attributeEntityMap: AttributeProfileMapping[]
}

export type AttributeProfileMapping = {
  dataTableId: number
  dataTableName: string
  dataEntityId: number
  dataEntityName: string
  dataColumnId: number
  dataColumnName: string
  dataAttributeId: number
  dataAttributeName: string
  dataEnityMapId: number
  dataPodId: string
  runid: number
}

export type DataAttributeBody = {
  dataEntityId: number
  dataAttributeName: string
  dataAttributeDataType: string
  description: string
  isBusinessKey?: boolean
  dataClassification?: string
}

export type DataColumnBody = {
  dataTableId: number
  dataColumnName: string
}

export type DataColumn = DataColumnBody & {
  id: number
  dataPodId: string
  datatype: string
  isPrimary: boolean
  isUnique: number
  isBusinessKey: boolean
}

export type DataEntityQueryBody = {
  dataEntityId: number
  silverDataEntityQuery: string
  profileState: string
  runId: number
  profileName: string
}

export type DataEntityQuery = DataEntityQueryBody & {
  id: number
  silverDataEntityQuery: string
  dataSystemInfo: string
  datapodId: string
  lastupdated: string
}

export type DataEntityQueryData = {
  id: number
  srcId: number
  dataEntityId: number
  dataEntityName: string
  silverDataEntityQuery: string
  profileState: null
  datapodId: string
  runId: number
  lastupdated: number
  profileName: string
  loadStatus: boolean
  containerLocation: null
}

export type DataEntityQueryUpdate = {
  id: number
  silverDataEntityQuery: string
}

export type DataTableBody = {
  name: string
  containerDirectory: string
  dataSystemId: number
  description: string
}

export type DataTable = DataTableBody & {
  id: number
  datapodId: string
  profileStatus: boolean
  mappingStatus: boolean
  findPrimaryKeyStatus: boolean
  isActive?: boolean
}

export type Entity = {
  id: number
  dataEntityName: string
  profileState: string
  datapodId: string
  runId: number
  lastupdated: string
  description: string
  profileName: string
  coreEntity: boolean
  entityType: string
  dataEntityType: number
  canonicalLoad?: boolean
  hasBuisnessKey?: boolean
  dataHubMapId?: number
  dataHubCloneTypeId?: number
}

export type CoreEntity = Entity & {
  dataCoreEntityId: number
  CoreEntity: boolean
}

export type EntityMapping = {
  id: number
  dataTableId: number
  dataTableName: string
  dataEntityId: number
  dataEntityName: string
  description: string
  dataPodId: string
  runId: number
  lastupdated: string
  entityType: string
  dataSystemId: number
  dataSystemName: string
  mapType: string
}

export type RunEntityBody = {
  dataTableId: number
}

export type NewEntity = {
  dataEntityName: string
  profileName: string
  description: string
  coreEntity: boolean
  dataHubMapId?: number
  dataHubCloneTypeId?: number
}

export type DataLakeZone = {
  id: number
  zoneName: string
  zoneDescription: string
  isActive: boolean
}
export type NewParent = NewEntity & {
  entityType: string
  dataLakeZone: number
}

export type EntityProfileOutput = {
  dataEntityId: number
  data_entity: string
  profileStatus: string
}

export type NewEntityMapBody = {
  dataTableId: number
  dataEntityId: number
  runId: number
  profileName: string
}
export type NewEntityMap = NewEntityMapBody & {
  id: number
  dataPodId: string
  lastupdated: string
}
export type UpdateEntityNameBody = {
  id: number
  dataEntityName: string
  profileState: string
  profileName: string
  description: string
  coreEntity: boolean
  entityType: string | undefined
  dataLakeZone?: number
}

export type UpdateEntityMapBody = {
  id: number
  dataTableId: number
  dataEntityId: number
  profileName: string
  guidedPromt: string
  mapType: string
}

export type GoldMeasureResponse = {
  additionalProp: string
}

export type IndustryStandardSubjectAreaResponse = {
  industryName: string
  subjectAreaName: string
  description: string
}

export type IndustryStandardDataSystemResponse = {
  subjectAreaName: string
  dataSystemName: string
  dataSystemDesc: string
}

export type IndustryStandardEntityResponse = {
  subjectAreaName: string
  dataSystemName: string
  dataEntityName: string
  description: string
}

export type IndustryStandardAttributesResponse = {
  subjectAreaName: string
  dataEntityName: string
  dataAttributeName: string
  dataType: string
  description: string
}

export type IndustryStandardAnalyticsGroupResponse = {
  subjectAreaName: string
  dataSystemName: string
  dataEntityName: string
  analyticsGroupName: string
  description: string
}

export type IndustryStandardAnalyticsMeasureResponse = {
  subjectAreaName: string
  dataSystemName: string
  dataEntityName: string
  analyticsGroupName: string
  analyticsMeasureName: string
  description: string
}

export type SilverMeasureResponse = GoldMeasureResponse

export type AppProfile = {
  datapodId: string
  subjectAreaId: number
  subjectAreaName: string
  subjectAreaDesc: string
  dataSystemID: number
  dataSystemName: string
  dataSystemDesc: string
}
export type AttributeDataColumn = {
  dataColumnId: number
  dataColumnName: string
  dataColumnDatatype: string
}

export type AppProfileEntity = AppProfile & {
  dataTableName: string
  dataTableID: number
  dataTableDesc: string
  containerDirectory: string
  dataEntityID: number
  dataEntityName: string
  description: string
  mapId: number
  coreEntity: boolean
  guidedPromt: string
  entityType: string
}

export type AppProfileAttribute = {
  datapodId: string
  subjectAreaId: number
  subjectAreaName: string
  subjectAreaDesc: string
  dataSystemID: number
  dataSystemName: string
  dataSystemDesc: string
  dataTableID: number
  dataTableName: string
  dataTableDesc: string
  containerDirectory: string
  dataEntityID: number
  dataEntityName: string
  dataAttributeID: number
  dataAttributeName: string
  dataAttributeDescription: string
  dataClassification: string
  dataAttributeDataType: string
  dataColumnId: number
  dataColumnName: string
  dataColumnDatatype: string
  dataEntityMapId: number
  isBusinessKey: boolean
  dataAttributeMapID: number
}

export type PaginationParams = {
  recordsPerPage?: number
  pageNumber?: number
}

export type JobRequestParams = {
  dataPodId?: string
  dataSystemId?: number
  industryId?: number
  subjectAreaId?: number
  dataTableId?: number
  entityId?: number
}

export type JobStatusResp = {
  run_id: number
  number_in_job: number
  jobStatus: string
  config_name: string
}

export type RelationShipResponse = {
  id: number
  database: string
  dataTableId: number
  dataTableName: string
  leftEntityId: number
  leftEntityName: string
  leftAttributeId: number
  leftAttributeName: string
  rightEntityId: number
  rightEntityName: string
  rightAttributeId: number
  rightAttributeName: string
  cardinality: string
  similarityScoreByName: number
  similarityScoreByData: number
  dataPodId: string
  sampleOverlapPercentage?: number
  schemaCompatibility?: number | boolean
}

export type TableRelationShips = {
  id: number
  srcId: number
  leftTableId: number
  leftTableName: string
  rightTableId: number
  rightTableName: string
  leftColumnId: number
  leftColumnname: string
  rightColumnId: number
  rightColumnname: string
  cardinality: string
  dataPodId: string
  runId: number
  lastupdated: string
  profileName: string
  userDecision: boolean
  isNewRelationship: boolean
}

export type TableRelationInput = {
  srcId: number
  leftTableId: number
  leftTableName: string
  rightTableId: number
  rightTableName: string
  leftColumnId: number
  leftColumnName: string
  rightColumnName: string
  rightColumnId: number
  cardinality: string
  profileName: string
  validationStatus: string
  validationResult: string
  userDecision: boolean
  isNewRelationship: boolean
}

export type EntityReconData = {
  id: number
  dataCoreEntityId: number
  dataSystemEntityId: number
  parent: string
  child: string
}

export type UpdateEntityReconBody = {
  id: number
  dataCoreEntityId: number
  dataSystemEntityId: number
}

export type NewEntityRelationShip = {
  dataCoreEntityId: number
  dataSystemEntityId: number
  joinCoreAttributeId: number
  joinSystemAttributeId: number
  similarityScoreByName: number
  similarityScoreByData: number
  runId: number
  profileName: string
  cardinality: string
  enrichedEntityId: number | null
  overlapPercentage: number | null
  schemaCompatibility: boolean | null
}

export type EntityRelationship = NewEntityRelationShip & {
  datapodId: string
  id: number
}

export type UpdateAttributeData = {
  id: number
  name: string
  description: string
  dataAttributeDataType: string
  dataClassification: string
  isBusinessKey: boolean
  coreEntity: boolean
  entityType: string
}

export type NewEntityData = {
  dataEntityName: string
  profileName: string
  description: string
  coreEntity: boolean
  entityType: string | undefined
  dataLakeZone: number
  canonicalLoad?: boolean
}

export type CustomEntityData = NewEntityData & {
  id: number
  profileState: string
  datapodId: string
  runId: number
  lastupdated: string
  isActive: boolean
  dataEntityType: number
  dataLakeZone: number
}

export type NewDataTransformer = {
  dataAttributeMapId: number
  transformerText: string
  transformerQuery: string
  dataEntityId: number
  transformerTypeName: string
}

export type DataTransformer = NewDataTransformer & {
  id: number
}

export type DataTransformerDetail = DataTransformer & {
  dataTableId: number
  dataTableName: string
  dataEntityId: number
  dataEntityName: string
  dataColumnId: number
  dataColumnName: string
  dataAttributeId: number
  dataAttributeName: string
  dataEntityMapId: number
  dataSystemId: number
  dataSystemName: string
}

export type DataSourceToTargetAtrributeMap = {
  id: number
  sourceEnityId: number
  sourceEntityName: string
  targetEntityId: number
  targetEntityName: string
  sourceAttributeId: number
  sourceAttributeName: string
  targetAttributeId: number
  targetAttributeName: string
  dataPodId: string
  sourceTableId: number
  sourceTableName: string
  targetTableId: number
  targetTableName: string
  sourceDataSystemId: number
  targetDataSystemId: number
  runId: number
  lastUpdated: string
  profileName: string
  createOn: string
  isActive: boolean
}

export type ServiceConnections = {
  id: number
  dataPodId: string
  serviceConnectionJsonTemplate: string
  serviceConnectionTypeId: string
  serviceTypeId: number
  locationInfo: string
  connectionName: string
  connectionType: string
  serviceTypeName?: string
}

export type AddEntityRecon = {
  dataCoreEntityId: number
  dataSystemEntityId: number
  profileName: string
}

export type NewEntityRecon = AddEntityRecon & {
  id: number
  dataPodId: number
  runId: number
  lastupdated: number
}

export type Pipeline = {
  entityid: number
  dataEntityName: string
  datapodId: string
  dataLakeZone: number
  dataPipelineId: number
  pipelinename: string
  pipelineStatus: string
  scheduledId: number
  connectionName: string
  serviceConnectionId: number
  connectionType: string
  scheduleOrder: number
  dataPipelineScheduleId: number
  dependsOn: number[]
}

export type EntityWithOrder = {
  entityID: number
  scheduleOrder: number
}
/** new pipeline body @requires dataPodId as query string  */
export type NewDataPipeline = {
  entitiesWithOrders: EntityWithOrder[]
  dataLakeZoneId: number
  serviceConnectionId: number
  dataPipelineScheduleId: number
  action: string
}

/***  response after adding new datapipeline ****/
export type DataPipeline = {
  id: number
  dataPodId: string
  entityid: number
  pipelinename: string
  pipelineStatus: string
  scheduledId: number
  serviceConnectionId: number
  scheduleOrder: number
  dataPipelineScheduleId: number
}

/***
 * @requires  datapodId
 *   New datapipelineExecutionStatus body ****/
export type NewPipelineExecutionStatus = {
  pipelineId: number
  pipelineRunId: number
  pipelineCronString: string
  pipelineRunStatus: string
  startTime: string
  endTime: string
  timeZoneFormat: string
}

export type UpdatePipelineExecutionstatus = NewPipelineExecutionStatus & { id: number }

/***
 * @requires  datapodId
 * @requires pipelineId
 * @requires scheduledId --> id
 * response  Get datapipelineExecutionStatus ****/
export type PipelineExtecutionStatus = NewPipelineExecutionStatus & {
  id: number
  dataPodId: string
}

export type NewPipelineSchedule = {
  frequency: string
  startdate?: string
  starttime?: string
  timeZone: string
  name: string
  description: string
  enddate?: string
  endtime?: string
}

export type PipelineSchedule = NewPipelineSchedule & {
  id?: number
}

export type SourceToTargetMapRanking = {
  sourceEntityId: number
  sourceEntity: string
  sourceEntityType: string
  sourceAttributeId: number
  sourceAttribute: string
  sourceAttributeDataType: string
  dataPodId: string
  mapId: number
  targetEntityId: number
  targetEntity: string
  targetEntityType: string
  targetAttributeId: number
  targetAttribute: string
  targetAttributeDataType: string
  confidenceRate: number
  rank: number
}

//Measure Notebook types

export type MeasureNotebook = {
  datapodID: string
  measureId: number
  notebookToRun: string
  commitMessage: string
}

export type GenerateMeasurePayload = {
  dataPodId: string
  analyticsGroupId: number
}

export type GenerateKPIType = {
  dataPodId: string
  measureID: number
  promptText: string
  dimension_data?: {
    [key: string]: string[]
  }[]
}

export type AdvAnalyticsPayload = {
  dataPodId: string
  measure: string
}

export type AdvAnalyticsResponse = {
  result: string
  data: TechniqueDetails[]
}

export type TechniqueDetails = {
  TechniqueDescription: string
  TechniqueName: string
}

export type DataSourceColumnSelectedTargetsMapV2 = {
  id: number
  dataPodId: string
  dataSystemId: number
  dataTableId: number
  sourceColumnId: number
  targetColumnId: number
  sourceDataSystemName: string
  sourceDataTableName: string
  sourceDataTableDescription: string
  sourceDataColumnName: string
  sourceDataColumnDescription: string
  targetDataSystemName: string
  targetDataTableName: string
  targetDataTableDescription: string
  targetDataColumnName: string
  targetDataColumnDescription: string
  sourceDataType: string
  targetDataType: string
  lucidMean: number
}

export type DataColumnMapLucidMeansV2 = {
  dataPodId: string
  dataSystemId: number
  dataTableId: number
  sourceColumnId: number
  targetColumnId: number
  sourceDataSystemName: string
  sourceDataTableName: string
  sourceDataTableDescription: string
  sourceDataColumnName: string
  sourceDataColumnDescription: string
  targetDataSystemName: string
  targetDataTableName: string
  targetDataTableDescription: string
  targetDataColumnName: string
  targetDataColumnDescription: string
  mean: number
  lucidMean: number
  lucidMeanRank: number
  sourceDataType: string
  targetDataType: string
}

export type MapDataColumnMapLucidMeansV2 = {
  mapId: number
  dataPodId: string
  dataSystemId: number
  dataTableId: number
  sourceColumnId: number
  targetColumnId: number
  sourceDataSystemName: string
  sourceDataTableName: string
  sourceDataTableDescription: string
  sourceDataColumnName: string
  sourceDataColumnDescription: string
  targetDataSystemName: string
  targetDataTableName: string
  targetDataTableDescription: string
  targetDataColumnName: string
  targetDataColumnDescription: string
  mean: number
  lucidMean: number
  lucidMeanRank: number
  sourceDataType: string
  targetDataType: string
}

export type addDataTargetToSourceColumnMapV2body = {
  sourceColumnId: number
  targetColumnId: number
  runId: number
  profileName: string
  sourceDataType: string
  targetDataType: string
  lucidMean: number
}
export type updateDataTargetToSourceColumnMapV2body = {
  id: number
  sourceColumnId: number
  targetColumnId: number
  runId: number
  profileName: string
  sourceDataType: string
  targetDataType: string
  lucidMean: number
}
export type getDataSourceToTargetColumnMapV2 = {
  id: number
  sourceColumnId: number
  targetColumnId: number
  dataPodId: string
  runId: number
  lastUpdated: string
  profileName: string
  createOn: string
  isActive: boolean
  sourceDataType: string
  targetDataType: string
  lucidMean: number
}

export type GenAiGenerateMapping = {
  dataPodId: string
  dataSystemId: number
  dataTableId: number
}

export type GenAiGenerateMappingResponse = GenAiGenerateMapping & {
  result: string
  data: GenAiGenerateMappingDetials[]
}

export type GenAiGenerateMappingDetials = {
  columns: string[]
  attributes: string[]
  dataClassification: string[]
  table_name: string
  entity_name: string
  entity_description: string
  entity_type: string
  attribute_description: string[]
}
export type GenAIRelationsBody = {
  dataPodId: string
  dataCoreEntityId: number
}
export type GenAICoreEntityAttribute = {
  dataPodId: string
  coreEntityId: number
}

export type GenAIReconcilationBody = {
  dataPodId: string
  promptInput?: string
}

export type generateGenAISubjectAreasBody = {
  dataPodId: string
}

export type GenAIReconcilationResponse = {
  result: string
  data: CustomDictionary
}
export type GenAIEnrichedCoreEntityAttribute = {
  dataPodId: string
  enrichedEntityName: string
  entityType: string
  description: string
  canonicalEntities: number[]
}
export type GenAIEditEntityMapping = {
  dataPodId: string
  dataSystemId: number
  dataTableId: number
  dataEntityId: number
}

export type GenAIGetNewEntityMapping = {
  dataPodId: string
  dataSystemId: number
  dataTableId: number
  dataEntityName: string
  dataEntityDescription: string
}
export type GenAISchemaCompatibilty = {
  dataPodId: string
  dataEntityId: number
}

export type GenAISchemaCompatibiltyAttribute = {
  dataPodId: string
  dataEntityId: number
  attributeId: number
  flag: string
  dataType: string
}

export type GenAIRelationResponse = {
  result: string
  data: [
    {
      dataCoreEntityId: number
      dataSystemEntityId: number
      joinCoreAttributeId: number
      joinSystemAttributeId: number
      similarityScoreByName: number
      similarityScoreByData: number
      dataPodId: string
      run_id: number
      lastupdated: string
      profileName: string
      cardinality: string
    },
  ]
}

export type GetSubjectAreaTablesBody = {
  dataPodId: string
  subjectAreaId: number
  promptInput: string
}

export type GetSubjectAreaTablesBodyResponse = {
  result: string
  data: [
    {
      table_name: string
      Columns: string[]
    },
  ]
}

export type SubjectAreaTable = {
  table_name: string
  Columns: string[]
  values?: DataTableRow[]
}

export type GetSubjectAreaTableDataBody = {
  dataPodId: string
  GenTableAPIoutput: {
    context: {
      industry: string
      subject_area_name: string
      subject_area_description: string
    }
    data: {
      table_name: string
      Columns: string[]
    }
  }
  promptInput: string
  numberOfRecords: number
}

export type ConfigurationType = {
  id: number
  name: string
  isActive: boolean
}

export type Configuration = {
  id: number
  key: string
  value: string
  configurationType?: number
}

export type Role = {
  id: number
  roleName: string
  roleDescription: string
  isActive: true
  permissions: []
  users: User[]
}

export type User = {
  id: number
  accountId: string
  email: string
}

export type addDataLakeBody = {
  entityid: number
  dataLakezone: string
}

export type UpdateDataPipelinesParams = {
  id: number
  entityid: number
  pipelinename: string
  pipelineStatus?: string
  scheduledId: number
  serviceConnectionId: number
  scheduleOrder: number
  dataPipelineScheduleId: number
}

export type NotebookParams = CustomDictionary

export type RunNewJobParams = {
  notebookParams: NotebookParams
  url: string
  jobId: number
  token: string
}

export type RunIdStatus = {
  runId: number
  status: string
  url: string
  token: string
}

export type FabricRunNewJobRequest = {
  workspaceId: string
  itemId: string
  jobType: string
  token: string
  executionData: NotebookParams
  environmentId: string
}

export type FabricNotebookCreateRequest = {
  workspaceId: string
  displayName: string
  description: string
  accessToken: string
  code: string
  executionData: NotebookParams
  environmentId: string
}

export type FabricJobStatusResponse = {
  id: string
  itemId: string
  jobType: string
  workspaceId?: string
  status?: string
  startTimeUtc?: string
  endTimeUtc?: string
  token?: string
  jobDetails?: JobDetails
}

export type FabricJobStatusRequest = {
  workspaceId?: string
  itemId: string
  JobInstanceId: string
  jobType?: string
  token?: string
}

export type dataBricksTriggerTypes =
  | 'SourceScan'
  | 'TableScan'
  | 'Measures'
  | 'Entity'
  | 'Data Lake Load'
  | 'Generate Mappings'
  | 'Data Compatibility Check'
  | 'Data Warehouse Load'
  | 'Custom Attribute Code Execution'
  | 'Enriched Entity Code Execution'
  | 'Data Quality Code Execution'
  | 'KPI Code Execution'
  | 'AdvanceTableScan'
  | 'Custom Enriched Table And Column Profiling'

export enum ApiStatus {
  Initiated = 'Initiated',
  Success = 'Success',
  Failed = 'Failed',
}

export type GenAIApiStatus = {
  GenAIApiStatusId: number
  title: string
  status: ApiStatus
  description: string
  redirectUrl?: string
  apiUrl: string
}

export type JobDetails = {
  triggerType: dataBricksTriggerTypes 
  parameters: { 
    id: string | number | undefined 
    name: string | undefined 
    route: string | undefined
  }
}

export type RunStatusResponse = {
  jobId?: number
  runId: number
  runPageUrl?: string
  runName?: string
  state: {
    lifeCycleState: string
    resultState: string
    stateMessage?: string
    userCancelledOrTimedOut?: string
  }
  params: {
    runId: number
    url: string
    token: string
  }
  jobDetails?: JobDetails
}

export type CreateJobParams = {
  dataPodId: string
  measureID?: number
  customEntityID?: number
  customAttributeID?: number
  dataQualityRuleId?: number
  jobClusterConfig?: JobClusterConfig
}

export type JobClusterConfig = {
  url: string
  token: string
  clusterKey: string
  sparkVersion: string
  nodeTypeId: string
}

export type CreateJobResponse = {
  runId: number
  url: string
  token: string
}

export type coreEntityAttributes = {
  result: string
  data: [
    {
      AttributeName: string
      AttributeDescription: string
    },
  ]
}

export type coreEntityAttributeData = {
  AttributeName: string
  AttributeDescription: string
}
export type getCoreEntityAttributeResponse = {
  id: number
  dataCoreEntityId: number
  dataCoreAttributeName: string
  dataPodId: string
  dataCoreAttributeDataType: string
  dataCoreClassification: string
  dataEntityId:number | null
  description: string
  isBusinessKey: boolean
  isActive: boolean
  dataAttributeType: string
}
export type AddCoreEntityAttribute = {
  dataCoreEntityId: number
  dataCoreAttributeName: string
  dataPodId: string
  dataCoreAttributeDataType: string
  dataCoreClassification: string
  description: string
  isBusinessKey: boolean
  dataAttributeType: string
  dataEntityId?:number
}

export type GenereateKPIResponse = {
  result: string
  data: string
  dimension_data: [
    {
      [key: string]: string[]
    },
  ]
}

export type GenerateKPI = {
  time: number
  notebook: string
  dimensionData: DimensionData[]
}

export type DimensionData = {
  entityName: string
  attributes: string[]
}

export type CustomDictionary = {
  [key: string]: string
}

// export type reconDictionary = {
//   [key: string]: {
//     coreEntity: string,
//     coreEntityDescription: string
//   },
// }

export type RuleSubCategory = {
  id: number
  ruleCategoryId: number
  name: string
  description: string
  isActive: boolean
}

export type Rules = {
  [key: string]: RuleSubCategory[]
}

export type DataQualityRule = {
  id: number
  name: string
  ruleCategoryId: number
  ruleSubCategoryId: number
  entityId: number
  attributeIds: number[]
  promptText: string
  targetAttribute?: string
}

export type DataQualityCodeRequest = {
  dataPodId: string
  entityId: number
  attributeId_list: number[]
  cleansing_rule: string
  promptText: string
  targetAttributeName: string
  language: string
}

export type DataQualityNBRequest = {
  dataPodId: string
  dataQualityRuleId: number
  notebookToRun: string
  commitMessage: string
}

export type DataTableProfiling = {
  id: number
  dataTableId: number
  profilingData: string
  dataPodId: string
  isactive: boolean
  dataTableName: string
  maptype: string
  dataTableProfilingData: string
  overlapPercentage: number
  relationshipType: string
  schemaCompatibility: boolean
}

export type DataTableColumnProfiling = DataTableProfiling & {
  dataColumnId: number
  dataColumnName: string
}

export type GenBusinessKey = {
  dataPodId: string
  entityId: number
}

export type GenAttributeDataTypes = {
  dataPodId: string
  dataEntityId: number
  dataTableId: number
}

export type DataTableUpdateParams = {
  id: number
  name: string
  containerDirectory: string
  dataSystemId: number
  description: string
  profileStatus: boolean
  mappingStatus: boolean
  isActive: boolean
}

export type VnetConfig = {
  id: number
  accountId: string
  subscriptionId: string
  resourceGroupName: string
  vnetname: string
  vnetaddress: string
}

export type ConnectionType = {
  id: string
  serviceConnectionType1: string
  serviceConnectionTypeDescription: string
  isActive: number
}

export type ServiceConnectionConfig = {
  id: number
  serviceName: string
  serviceDescription: string
  isActive: boolean
  serviceConnectionTypeId: string
  jsonConfiguration: string
}

export type NewServiceConnection = {
  serviceConnectionJsonTemplate: string
  serviceConnectionTypeId: string
  serviceTypeId: number
  locationInfo: string
  connectionName: string
  connectionType: string
}

export type UpdateServiceConnection = NewServiceConnection & {
  id: number
}

export type DatapodServiceConnection = NewServiceConnection & {
  id: number
  dataPodId: string
  isActive: boolean
  serviceTypeName: string
}

export type OperationState = {
  display?: boolean
  message?: string
  result: 'success' | 'error' | 'info' | 'warning' | undefined
}
export type EnterprisePlans = {
  id: number
  name: string
  description: string
  price: number
  validityDays: number
}

export type UserPlans = {
  id: number
  name: string
  description: string
  price: number
  validityDays: number
}

export type DatapodUsers = {
  id: number
  datapodId: string
  email: string
  userId: number
  roleId: number
}

/** DataQualtityRecommendations Payload
 * @dataPodId req
 * @dataEntityId req
 * @attribute_ids_list req
 */
export type GenDQRecommendationReq = {
  dataPodId: string
  dataEntityId: number
  attribute_ids_list: number[]
}

/** DataQualtityRecommendations
 */
export type DataQualityRuleRecomm = {
  CategoryName: string
  SubcategoryName: string
  EntityName: string
  EntityID: number
  Attributes: string[]
  AttributeIDs: number[]
  RuleDescription: string
  RuleExplanation: string
}

export type GeneratedDQRecommedations = {
  result: string
  data: DataQualityRuleRecomm[]
}

export type DQRecommendations = {
  id: number
  dataEntityId: number
  dataAttributeId: number
  dataQualityData: string
  dataPodId: string
  isActive: boolean
}

export type AttributeColumnDetail = {
  column: string
  attribute: string
  attribute_description: string
  dataClassification: string
  entity_name: string
  table_name: string
  entity_description: string
  entity_type: string
}

export type DataAttributeMappingRequest = {
  dataTableId: number
  dataEntityId: number
  dataEntityMapId: number
  attributeColumnDetails: AttributeColumnDetail[]
}

export type DataEntityFlow = {
  id: number
  coreEntityId: number
  coreEntityName: string
  dataPodId: string
  dataFlow: string
}
export type UpdateDataProfilingData = {
  id: number
  dataTableId: number
  profilingData: string
}

export type ResultStatusSeverity = 'success' | 'error' | 'info' | 'warning' | undefined

export type createDataHubBody = {
  dataHubName: string
  dataHubDesc: string
  accountId: string
}

export type updateDataHubBody = createDataHubBody & {
  id: string
}

export type getDataHubResponse = {
  id: string
  dataHubName: string
  dataHubDesc: string
  accountId: string
  isActive: boolean
  createdBy: string
  createdDate: string
  modifiedBy: string
  modifiedDate: string
}

export type AddDataHubEntityMap = {
  dataHubId: string
  dataEntityId: number
  dataPodId: string
}

export type getDataHubEntityMapResponse = {
  dataEntityId: number
  dataEntityname: string
  dataHubId: string
  dataPodId: string
  description: string
  entityType: string
  id: number
  isActive: boolean
}

export type GeneratedAttributesResponse = {
  entityId: number,
  attributes: string[],
}
export type addEntityMapResponse = {
  id: number
  dataHubId: string
  dataEntityId: number
  dataPodId: string
  isActive: boolean
}

export interface Child {
  childId: number
  childName: string
  parentId: number
}
export type EntityWithAttrsDictionary = {
  entity: Entity;
  attrsDictionary: {
    [columnId: number]: DataAttributeBody;
  }
}

export type LocalLLmRelationship =  {
  LeftEntity: string;
  LeftAttribute: string;
  RightEntity: string;
  RightAttribute: string;
}

// Define the structure for the main entity response
export type LocalLLmEntityData = {
  entity_name: string;
  entity_type: string;
  entity_description: string;
  children_entities: string[];
  children_attributes: {
    [key: string]: string[]; // Key will be "Policy", "Claim", etc., and value will be an array of attributes
  };
  relationships: LocalLLmRelationship[];
  transformations: string;
}

export type getPySparkCodeResponse = {
  original_script:string,
  pyspark_code:string,
  Databricksjson?:string,
  generated_filename?:string
}


export type ConversionMetricsRequest = {
  source_code: string
  source_codeType: string
  pyspark_code:string
}

export type GenericConversionMetricsRequest = {
  source_code: string
  target_code: string
  source_codeType: string
  target_type:string
}
export type getConversionMetricsResponse = {
  is_conversion_successful: boolean

  llmLogicalSimilarity: number
  
  pyspark_code: string
  reason: string
  
  similarity_score: number
  
  source_code:string
}

export type addNotebookRequest = {
  filePath: string
  notebook: string
  commitMessage: string
}

export type ConvertedFiles = {
  id: number,
  dataPodId: string,
  serviceConnectionId: number,
  fileName: string,
  convertedLink: string,
  isActive: boolean,
  lucidConfidenceScore: number,
  reasonConversionLog: string

}

export type columnProfileUpdateBody = {
  id: number,
  dataTableId: number,
  dataColumnId: number,
  profilingData: string
}