import { useMemo, useEffect, useState, FormEvent } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import { cardContainer } from 'styles/globalStyles'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import ActionPage from 'ui-components/ActionPage'
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable'

import { Configuration, DatapodUsers, UserAccount } from 'businessObjects'
import { DataTableRow } from 'ui-components/DataTable/DataTableTypes'
import ResultStatus from 'ui-components/ResultStatus'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { DeletePopUp } from 'pages/DataProcess/DataMapping/DataMappingPopUps'
import { useAssignRole, useDeleteUserRole, useEditUserRole, useGetRoles, useGetUsersByDatapodId } from 'hooks/rolesHooks'
import { useGetUsersByTenantId } from 'hooks/userHooks'
import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import constants from 'pages/constants'
import { ROUTES } from 'Routes/constants'

type ViewResultsParams = {
  datapodId: string
}

type Props = {}

const DataContextManageAccess = (props: Props) => {
  const { datapodId } = useParams<ViewResultsParams>()

  const { lucidAccount } = useLucidAuthContext()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isAdding, setIsAdding] = useState<boolean>(false)

  const [tableRows, setTableRows] = useState<DataTableRow[]>([])
  const [tenantUsers, setTenantUsers] = useState<UserAccount[]>([])
  const [noAccessUsers, setNoAccessUsers] = useState<UserAccount[]>([])
  const [datapodUsers, setDatapodUsers] = useState<DatapodUsers[]>([])

  const { allRoles } = useGetRoles()
  const { assignRole } = useAssignRole()
  const { editUserRole } = useEditUserRole()
  const { deleteUserRole } = useDeleteUserRole()
  const { getUsersByTenantId } = useGetUsersByTenantId()
  const { getUsersByDatapodId } = useGetUsersByDatapodId()

  const [searchString, setSearchString] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<UserAccount | null>(null)
  const [selectedUsers, setSelectedUsers] = useState<UserAccount[]>([])
  const [selectedRoleId, setSelectedRoleId] = useState<number | undefined>(constants.defaultRoleId)

  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)
  
  const navigate = useNavigate()


  useEffect(() => {
    const getUsersPromise = getUsersByDatapodId(datapodId)

    getUsersPromise.then((datapodUsersResult) => {
      setDatapodUsers(datapodUsersResult)

      if (lucidAccount) {
        const response = getUsersByTenantId(lucidAccount.accountTenantId)
  
        response.then((tenantUsersResult) => {         
          setTenantUsers(tenantUsersResult)
        })
      }
    })
  }, [datapodId])

  useEffect(() => {
    if(datapodUsers && tenantUsers) {
      //Filtering the users out who don't have access.
  
      const noAccess = tenantUsers.filter(
        (user) => !datapodUsers.find((datapodUser) => datapodUser.email == user.email),
      )

      setNoAccessUsers(noAccess)
    }
  }, [datapodUsers, tenantUsers])

  useEffect(() => {
    if (!errorState) {
      setErrorMessage(undefined)
    } else if (!successState) {
      setSuccessMessage(undefined)
    }
  }, [errorState, successState])

  useEffect(() => {
    const tablerowsData = datapodUsers.map((datapodUser) => ({
      id: String(datapodUser.id),
      values: [
        datapodUser.email,
        allRoles.find((role) => role.id == datapodUser.roleId)?.roleName || '',
        'EDIT',
        'DELETE',
      ],
    }))

    setTableRows(tablerowsData)
  }, [datapodUsers, allRoles])

  const filteredUsers = noAccessUsers.filter((user) => user.email.toLowerCase().includes(searchString.toLowerCase()))

  const tableColumns = useMemo(() => {
    return [
      {
        label: 'User Email',
        sortableColumn: true,
      },
      {
        label: 'Role',
      },
      {
        label: 'EDIT',
      },
      {
        label: 'DELETE',
      },
    ]
  }, [])

  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    const datapodUserId = Number(rowId)
    const datapodUser = datapodUsers.find((datapodUser) => datapodUser.id == datapodUserId)

    if (datapodUser) {
      setSelectedRoleId(datapodUser.roleId)
      
      const user = tenantUsers.find((tenantUser) => tenantUser.id == datapodUser.userId)

      if (user) {
        setSelectedUsers([user])
        
        if (actionType === ShadowTableAction.Editable) {
          setSearchString(user.email)
          setIsAdding(false)
          setOpenDialog(true)
        } else if (actionType === ShadowTableAction.Deletable) {
          setShowDeleteWarning(true)
        }
      }
    }
  }

  const confirmDeleteUserRole = async () => {
    if (!selectedUsers.length || !selectedRoleId) {
      return
    }

    const isDeleted = await deleteUserRole(selectedUsers[0].id, selectedRoleId, datapodId || '')
    if (isDeleted) {
      setDatapodUsers((prevValue) =>
        prevValue.filter((userRole) => userRole.userId !== selectedUsers[0].id),
      )
      setSelectedUsers([]) 
    
      setSelectedRoleId(constants.defaultRoleId)
      setShowDeleteWarning(false)
      setSuccessMessage('Access revoked successfully.')
      setSuccessState(true)
    } else {
      setErrorMessage('Failed to assign access.')
      setErrorState(true)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedUsers([])
  }

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      if(isAdding) {
        await assignRoleToUser()
      } else {
        await editRoleOfUser()
      }
    } catch (error) {
      setErrorMessage('Internal server error.')
      setErrorState(true)
    }
      
    setSelectedUsers([])
    setSelectedRoleId(constants.defaultRoleId)

    setOpenDialog(false)
  }

  const assignRoleToUser = async () => {
    if (!selectedUsers.length || !selectedRoleId)
      return
    
    const assignedUsers = selectedUsers.map(async (user) => {
      const userRoleId = await assignRole(user.id, selectedRoleId, datapodId || '')

      if (userRoleId) {
        setDatapodUsers((prevValue) => [
          ...prevValue,
          {
            id: userRoleId,
            datapodId: datapodId || '',
            email: user.email,
            userId: user.id,
            roleId: selectedRoleId,
          },
        ])
        setSuccessMessage('Access assigned successfully.')
        setSuccessState(true)
      } else {
        setErrorMessage('Failed to assign access.')
        setErrorState(true)
      }
    })
  }
    
  

  const editRoleOfUser = async () => {
    if (!selectedUsers.length || !selectedRoleId)
      return
    const user = selectedUsers[0]
    const userRoleId = await editUserRole(user.id, selectedRoleId, datapodId || '')

    if(userRoleId) {
      const editedData = datapodUsers.map(datapodUser => {
        if(datapodUser.userId == user.id){
          datapodUser.roleId = selectedRoleId
        }

        return datapodUser
      })

      setDatapodUsers([...editedData])
      setSuccessMessage('Access updated successfully.')
      setSuccessState(true)
    } else {
      setErrorMessage('Failed to edit access.')
      setErrorState(true)
    }
    // await Promise.all(assignedUsers)
  }

  return (
    <ActionPage>
      <Grid item container spacing={2} display={'flex'} justifyContent={'flex-end'}>
        <Grid item xs={2}>
          <IconButton onClick={() => navigate(generatePath(ROUTES.AddDataPod + `?dataPodId=${datapodId}`))}>
            <ArrowBackIcon />
            <Typography>Back</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Button
        onClick={() => {
          setIsAdding(true)
          setOpenDialog(true)
        }}
      >
        {' '}
        + Assign access{' '}
      </Button>
      {/* <ProgressBar loading={loadingAttrbibutes} /> */}
      {successMessage && (
        <ResultStatus
          severtiy="success"
          showStatus={successState}
          closeStatus={setSuccessState}
          alertMessage={successMessage}
        />
      )}
      {errorMessage && (
        <ResultStatus
          severtiy="error"
          showStatus={errorState}
          closeStatus={setErrorState}
          alertMessage={errorMessage}
        />
      )}
      {/* <ResultStatus
      severtiy="warning"
      showStatus={editWarning}
      closeStatus={setEditWarning}
      alertMessage={`Warning: Editing Attribute "${selectedAttributeRow?.dataAttributeName}" will impact corresponding dependencies.`}
    /> */}
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmDeleteUserRole}
        closeDeleteWarning={() => setShowDeleteWarning(false)}
        itemToDelete={`Access to ${selectedUsers[0]?.email}`}
      />

      <Box style={cardContainer}>
        <Grid item container spacing={2}>
          <Grid item xs={14} justifyContent={'center'} alignItems={'stretch'} container>
            <ShadowTable
              rows={tableRows || []}
              columns={tableColumns}
              tableActionParams={{
                onButtonClick: onButtonClick,
                actions: [ShadowTableAction.Editable],
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{isAdding ? 'Assign access' : 'Edit access'}</DialogTitle>
        <DialogContent>
          <form id="assignUserToDatapod" onSubmit={handleFormSubmit}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <Autocomplete
                multiple
                id="email"
                disabled={!isAdding}
                options={searchString.trim() === '' ? noAccessUsers : filteredUsers}
                getOptionLabel={(option) => option.email}
                value={selectedUsers}
                onChange={(event, newValue) => {
                  setSelectedUsers(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Email Address"
                    type="text"
                    fullWidth
                    onChange={(event) => setSearchString(event.target.value)}
                    
                  />
                )}
              />
            </FormControl>
            <FormControl variant="filled" fullWidth sx={{ m: 1 }}>
              <InputLabel id="role_select">Role *</InputLabel>
              <Select
                labelId="role_select"
                label="Role *"
                value={selectedRoleId}
                onChange={(event) => setSelectedRoleId(Number(event.target.value))}
                required
              >
                {allRoles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <Button type="submit" form="assignUserToDatapod" disabled={!selectedUsers || !selectedRoleId}>
                {isAdding ? 'Add' : 'Update'}
              </Button>
              <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </ActionPage>
  )
}

export default DataContextManageAccess
