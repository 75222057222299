  import {
  Box,
  Grid,
  IconButton,
  Typography,
  Card,
  TextField,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  InputAdornment,
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Container,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material'
import { Dispatch, useEffect, useMemo, useState } from 'react'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { green } from '@mui/material/colors'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import { ROUTES } from '../../../Routes/constants'
import ActionPage from '../../../ui-components/ActionPage'
import { cardContainer } from '../../../styles/globalStyles'
import { DataQualityRule, DataQualityRuleRecomm, Entity, EntityMapping, RunNewJobParams, RunStatusResponse, ServiceConnections } from '../../../businessObjects'
import {
  useGetAllEntitiesByDataPodId,
  useGetEntity,
  useGetEntityMapping,
  useGetEntityMappingByEntityIdAndPodId,
} from '../../../hooks/entityHooks'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import { useGenerateBusinessKey } from '../../../hooks/generateDatapodHooks'
import {
  useGetDataAttributes,
  useGetDataAttributesByEntityId,
  useGetDataAttributesMapping,
  useDeleteAttributeId,
  useGetDataAttributesBySelectedEntityId
} from '../../../hooks/dataAttrbutesHooks'
import {useGenerateDataQualityRecommendation} from '../../../hooks/generateDatapodHooks'
import { useGetAllDataSystemsByPodId, useGetDataSystemTypes } from '../../../hooks/dataSystemHooks'
import { useNotebookToQueryString } from '../../../hooks/notebookHooks'
import { useGetQueryString } from '../../../hooks/queryStringHook'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import { usePostDatabricksJobRunNew } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import { useDeleteDataQualityRule, useGetDataQualityRecommendations, useGetDataQualityRules } from '../../../hooks/dataQualityHooks'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ArrowBackIos, ArrowDownwardOutlined, ArrowForwardIos, ArrowUpwardOutlined } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {
  useGetDataColumnProfilingByAttributeId,
  useGetDataTableProfilingByTableId,
} from 'hooks/generativeIntegartionHooks'
import { ColumnProfilingCanType, ProfilingDataType } from '../profilingDataTypes'
import { useUpdateEntityMapFunc } from 'hooks/entityHooks'
import LucidWidePopUp from 'ui-components/LucidWidePopUp'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import { GenBusinessKey } from 'businessObjects'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import React from 'react'
import Link from '@mui/material/Link'
import { serviceConnections } from 'apiServices/serviceConnections'
import AnimatedLoader from 'ui-components/AnimatedLoader'
import LoadingButton from '@mui/lab/LoadingButton'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
export const DataQualityRecommendation = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const typeId = useGetQueryString('typeId')
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  // const [tableView, setTableView] = useState<boolean>(true)
  // const [cardView, setCardView] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  // const [dataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const [entities] = useGetEntity({}, dataPodId, undefined, typeId && typeId ? Number(typeId) : undefined)
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>(entities)
  const [refetchEntities, setRefetchEntities] = useState<boolean>(false)
  // const {fetchEntity} = useGetEntity;

  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])

  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredEntities(entities)
    } else {
      setFilteredEntities(
        entities?.filter((entity) => entity.dataEntityName.toLowerCase().includes(searchText.trim().toLowerCase())),
      )
    }
  }, [searchText])

  const allEntities = useMemo(() => {
    if (searchText.trim() === '') {
      return entities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
    }
    return filteredEntities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
  }, [entities?.length, filteredEntities?.length])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  // const confirmDeleteAttributeId = async (attributeId: number) => {
  //   if (attributeId) {
  //     const deleteMapSuccess = await deleteAttributeId(attributeId)
  //     if (deleteMapSuccess) {
  //       console.log('Success')
  //       // const filteredRows = tableRows?.filter((r) => r.id !== String(selectedAttributeRow?.id))
  //       // refetch()
  //       // setTableRows(filteredRows)
  //       // setDeleteSuccess(true)
  //       // closeDeleteWarning()
  //     }
  //   }
  // }

  return (
    <ActionPage>
     
      <Box style={cardContainer}>
       
        <Grid item container spacing={0}>
          <Grid item xs={12} container alignItems={'center'}>
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={14}>
            <DataModelEntityAttibuteProps dataEntities={allEntities} dataPodId={dataPodId} />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

type DataModelEntityAttibutePropsProps = {
  dataEntities: Entity[]
  dataPodId: string | undefined
}

const DataModelEntityAttibuteProps = ({ dataEntities, dataPodId }: DataModelEntityAttibutePropsProps) => {
  const navigate = useNavigate()

  const [selectedEntityID, setSelectedEntityID] = useState<number>()
  // const [dataEntityTypes, setDataEntityType] = useState<number>(dataEntities[0]?.dataEntityType)
  // const [entityMappings] = useGetEntityMapping({}, selectedEntityID)
  //const [entityMappingsByTable] = useGetEntityMapping({})
  // const { dataAttributes,loadingAttributes, getDataAttributes } = useGetDataAttributesBySelectedEntityId(selectedEntityID?[selectedEntityID]: [])
  const [selectedEntityInfo, setSelectedEntityInfo] = useState<Entity>()
  // const [selectedEntityMappings, setSelectedEntityMappings] = useState<EntityMapping>()
  // const [showPopup, setShowPopup] = useState<boolean>(false)
  // const [fetchAttributesMappingData, attributesMappingData] = useGetDataAttributesMapping()
  // const [notebookString, setNotebookString] = useState<string>()
  const [isTableAsc, setIsTableAsc] = useState<boolean>(true)
  const [DQRulesRecommended, setDQRulesRecommended] = useState<DataTableRow[]>([])
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
  const [filterApplied, setFilterApplied] = useState<'In Canonical' | 'Not In Canonical' | 'all'>('all')
 
  // const [viewTableProfiling, setViewTableProfiling] = useState<boolean>(false)
  // const [openColumnProfiling, setOpenColumnProfiling] = useState<boolean>(false)
  // const { fetchEntityMapping } = useGetEntityMappingByEntityIdAndPodId(dataPodId)
  // const [deleteAttributeIdStatus, setDeleteAttributeIdStatus] = useState<boolean>(true)

   const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(selectedEntityID)
   const  {existingRecommendations, loadingCurrent, getDataQualityRecommendations} =  useGetDataQualityRecommendations(dataPodId, selectedEntityID)

  
  const [leftTableEntities, setLeftTableEntities] = useState<Entity[]>([])
  const [entitiesIdsToLoad, setEntityIdsToLoad] = useState<number[]>([])
  // const [selectedTableId, setSelectedTableId] = useState<number>()

  const [allDataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const { allDataSystemTypes } = useGetDataSystemTypes()
  // const { deleteAttributeId } = useDeleteAttributeId()
  // const [canonicalLoadType, setCanonicalLoadType] = React.useState('')


  const [fetchAllEntities, entity] = useGetAllEntitiesByDataPodId()
  // const { tableProfiling } = useGetDataTableProfiling(dataPodId, selectedTableId)

  // const { getDataTableProfilingData } = useGetDataTableProfilingByTableId(dataPodId)
  const [tablesProfilingInfo, setTablesProfilingInfo] = useState<ProfilingDataType[]>([])

 
 const {generateDataQualityRules, loadingDQRules } = useGenerateDataQualityRecommendation(dataPodId, selectedEntityID)
  const sortTableFn = (t1: Entity, t2: Entity) => {
    if(t1.dataEntityName < t2.dataEntityName) {
      return isTableAsc ? -1 : 1
    }
    if(t1.dataEntityName > t2.dataEntityName) {
      return isTableAsc ? 1 : -1
    }
    return 0
  }

  const { getDataColumnProfilingByAttributeId, tableColumnProfiling } = useGetDataColumnProfilingByAttributeId()

  
  const DQfromDB = useMemo(() => {
    if(!existingRecommendations.length) return []
    try {
      const allAtr = existingRecommendations.map((dqr) => dqr.dataQualityData)
      let allDQRules:DataQualityRuleRecomm[] = []

      allAtr.forEach((at) => {
        const atrep:DataQualityRuleRecomm[] = JSON.parse(at)
        allDQRules = [...allDQRules, ...atrep]
      })

      // console.log({allDQRules})

      return allDQRules.map((dqr) => ({
        // id must be unique. Best possible Unique id for this dataQualityRecommendation can be made with combination of categoryName and
        // first attributeId from AttributeIDs, as there will be only one Attibute in AttributeIDs list.
        //  Useful to Extract attributeId when manipulating tableData
        id: dqr.CategoryName + dqr.AttributeIDs[0],
        values: [
          dqr.CategoryName,
          dqr.SubcategoryName,
          dqr.Attributes.join(', '),
          dqr.RuleDescription,
          dqr.RuleExplanation,
          // <Tooltip title={'RuleDescription'}>
          //   <span>{dqr.RuleDescription}</span> 
          // </Tooltip>,
          // <Tooltip title={'RuleExplanation'}>
          //   <span>{dqr.RuleExplanation}</span> 
          // </Tooltip>,
          'ADD',
        ],
      }))

    } catch (error) {
      
    }
  },[existingRecommendations])

  // console.log({DQfromDB})
  

  

  useEffect(() => {
    if(!selectedEntityID && dataEntities.length) {
      setSelectedEntityID(dataEntities[0].id)
    }
  },[dataEntities])

  // const generatedTableColumns = useMemo(() => {
  //   return [
  //     { label: 'Attribute Name', sortableColumn: true },
  //     { label: 'Attribute Description', sortableColumn: true },
  //     { label: 'Attribute Type', sortableColumn: true },
  //     { label: 'Data Type', sortableColumn: true },
  //     { label: 'Data Classification', sortableColumn: true },
  //     { label: 'isBusinessKey', sortableColumn: true },
  //     {label: 'Actions'}
     
  //     //{ label: 'View Details' },
  //   ]
  // }, [])

  const ruleRecommendationColumns = useMemo(() => {
    return [
      { label: 'CategoryName', sortableColumn: true },
      { label: 'SubCategoryName', sortableColumn: true },
      { label: 'Attributes', sortableColumn: true },
      { label: 'Rule Description', sortableColumn: true },
      { label: 'Rule Explanation', sortableColumn: true },
      {label: 'Add DataQuality'}
    ]
  }, [])

  // const ruleRecommendations = useMemo(() => {
    
  //   if(!generatedDataQualityRules.length) return []
  //   return generatedDataQualityRules.map((dqr, ix) => ({
  //     id: String(ix),
  //     values: [dqr.CategoryName, dqr.SubcategoryName, dqr.Attributes.join(', '), dqr.RuleDescription, dqr.RuleExplanation]
  //   }))
  // },[generatedDataQualityRules ])

  

  

  const sortedEntites = useMemo(() => {
    if (!dataEntities.length) return []
    // if (!entity.length) return []
    if (entity.length) {
      return entity
    } else {
      return dataEntities
    }
  }, [dataEntities, entity])

  useEffect(() => {
    if (sortedEntites.length) {
      setLeftTableEntities(sortedEntites)
      if (selectedEntityID) {
        if (!sortedEntites.some((item) => item.id === selectedEntityID)) {
          const firstEntityId = sortedEntites[0]?.id
          setSelectedEntityID(firstEntityId)
        }
      } else {
        const firstEntityId = sortedEntites[0]?.id
        setSelectedEntityID(firstEntityId)
      }
    }
  }, [sortedEntites])

  useEffect(() => {
    if (selectedEntityID) {
      
      const selectedEntity = sortedEntites.find((entity) => entity.id === selectedEntityID)
      setSelectedEntityInfo(selectedEntity)
      // getDataAttributes()
    } 
  }, [selectedEntityID])

 


  const handleFilterSelect = (id: typeof filterApplied) => {
    setFilterApplied(id)
    switch (id) {
      case 'In Canonical':
        const canonicalLoaded = sortedEntites.filter((dt) => dt.canonicalLoad && dt.canonicalLoad)
        // console.log({ canonicalLoaded })
        setLeftTableEntities(canonicalLoaded)
        setFilterAnchor(null)
        break
      case 'Not In Canonical':
        const canonicalUnLoaded = sortedEntites.filter((dt) => !dt.canonicalLoad)
        // console.log({ canonicalUnLoaded })
        setLeftTableEntities(canonicalUnLoaded)
        setFilterAnchor(null)
        break
      case 'all':
        setLeftTableEntities(sortedEntites)
        setFilterAnchor(null)
        break
    }
  }

  const sortedEntities = useMemo(() => {
    return leftTableEntities.sort(sortTableFn)
  }, [leftTableEntities, isTableAsc])

  

  const generateRecommendations = async () => {
    let dt: DataTableRow[] = []
    if(attributeProfiles.length) {
      for (let i=0; i< attributeProfiles.length; i++) {
        const recomm = await generateDataQualityRules([attributeProfiles[i].id])
        if (recomm?.length) {
          const recommened = recomm.map((dqr) => ({
        // id must be unique. Best possible Unique id for this dataQualityRecommendation can be made with combination of categoryName and 
        // first attributeId from AttributeIDs, as there will be only one Attibute in AttributeIDs list.
        //  Useful to Extract attributeId when manipulating tableData
            id: dqr.CategoryName + dqr.AttributeIDs[0],
            values: [dqr.CategoryName, dqr.SubcategoryName, dqr.Attributes.join(', '), dqr.RuleDescription, dqr.RuleExplanation, 'ADD']
          }))
          dt = [...dt, ...recommened]
        }
      }
      setDQRulesRecommended(dt)
    }
  }

  // useEffect(() => {
  //   if(attributeProfiles.length) {
  //     generateRecommendations()
  //   }
  // },[attributeProfiles])



 

  const columnProfilingData = useMemo(() => {
    if (!tableColumnProfiling.length) return []
    return tableColumnProfiling
  }, [tableColumnProfiling])

  // const colProfile = useMemo(() => {
  //   if (!columnProfilingData) return
  //   try {
  //     return JSON.parse(columnProfilingData.profilingData) as ColumnProfilingType
  //   } catch (error) {}
  // }, [columnProfilingData])

  const colProfile: ColumnProfilingCanType[] = useMemo(() => {
    if (!columnProfilingData) return []

    try {
      const parsedProfiles = columnProfilingData.map((item) => {
        return JSON.parse(item.profilingData) as ColumnProfilingCanType
      })
      // console.log(parsedProfiles)
      return parsedProfiles
    } catch (error) {
      console.error('Error parsing column profiling data:', error)
      return []
    }
  }, [columnProfilingData])

  const scrollableContainer = {
    maxHeight: '30vh',
    overflowY: 'auto',
  }


  const disableRecommandations = useMemo(() => {
    return  loadingDQRules || !attributeProfiles.length
  },[loadingDQRules, attributeProfiles])
  


  const recommendedDQTableRows = DQfromDB?.length? DQfromDB: DQRulesRecommended
  

 
  function onButtonClick(rowId: string, actionType: ShadowTableAction): void {
    const categoryNameAndAttribute = rowId.split(/(\d+)/).filter((val) => val && val)
    const categoryName = categoryNameAndAttribute[0]
    const attributeId = Number(categoryNameAndAttribute[1])
    const addDataQualityRoute = generatePath(ROUTES.DataModelingCanonicalModelAddDataQuality, { dataPodId: dataPodId,entityID: selectedEntityID })
    navigate(addDataQualityRoute + `?attributeId=${attributeId}&categoryName=${categoryName}`)
  }

  const [isListCollapsed, setIsListCollapsed] = useState<boolean>(false);

  return (
    <Grid item  container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      
      {/* <AnimatedLoader height='50%' width='40%' loading={loadingDQRules} /> */}
      
      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}>
        <Button variant="contained" disabled={!entitiesIdsToLoad.length} onClick={generateRecommendations}>
          Generate Data Quality Recommendations
        </Button>
      </Grid> */}
      <Grid item xs={15} />
      
        {!isListCollapsed && 
      <Grid item xs={isListCollapsed ? 0.5 : 2.5} sx={{ml: -6}}>  
        <Card sx={{ backgroundColor: 'white', marginLeft: 0 }}>
          <CardHeader
            disableTypography 
            sx={{ backgroundColor: 'grey' }}
            title={
              <Grid item xs={12} container alignItems="baseline" spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontSize: 16 }} gutterBottom>
                    {'List of Entites'}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    title={`sort ${isTableAsc ? 'asc' : 'desc'}`}
                    onClick={() => setIsTableAsc(!isTableAsc)}
                  >
                    {isTableAsc ? <ArrowDownwardOutlined fontSize="small" /> : <ArrowUpwardOutlined fontSize="small" />}
                  </IconButton>
                </Grid>
                <Grid item xs={1}>
                  <IconButton title="Filter" onClick={({ currentTarget }) => setFilterAnchor(currentTarget)}>
                    <FilterAltOutlinedIcon fontSize="small" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={filterAnchor}
                    open={Boolean(filterAnchor)}
                    onClose={handleFilterSelect}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      'aria-multiselectable': 'true',
                    }}
                  >
                    {['In Canonical', 'Not In Canonical', 'all'].map((itm) => (
                      <MenuItem
                        selected={filterApplied === itm}
                        key={itm}
                        onClick={() => handleFilterSelect(itm as typeof filterApplied)}
                      >
                        {itm}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
            }
          />
          <Divider />
          <List dense>
            {sortedEntities.map((p, ix) => (
              <ListItem
                key={ix}
                sx={{
                  color: selectedEntityID === p.id ? 'blue' : 'inherit',
                }}
              >
                {/* <Checkbox
                  sx={{
                    color: 'green',
                    '&.Mui-checked': {
                      color: green[600],
                    },
                  }}
                  edge="start"
                  tabIndex={-1}
                  checked={entitiesIdsToLoad.includes(p.id)}
                  onChange={() => handleEntityToLoad(p.id)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  // disabled={p.canonicalLoad} 
                  disableRipple
                /> */}
                <ListItemButton
                  onClick={() => {
                    
                    setSelectedEntityID(p.id)
                  }}
                >
                  <ListItemText primary={p.dataEntityName} />
                </ListItemButton>
                {/* {!!p.hasBuisnessKey ? (
                  <></>
                ) : (
                  <ErrorOutlineIcon fontSize="small" color="warning" titleAccess="No Business Key" />
                )}
                {!!p.canonicalLoad ? (
                  <CheckCircleIcon fontSize="small" color="primary" titleAccess="Loaded to Canonical" />
                ) : (
                  <></>
                )} */}
              </ListItem>
            ))}
          </List>
        </Card>
      </Grid>
        }
      <Grid item xs={isListCollapsed ? 12 : 9.5} sx={{mr: 0}} alignContent="baseline" container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 2, mb: 2 }}>
            <IconButton
              onClick={() => setIsListCollapsed(!isListCollapsed)}
              sx={{
                // position: 'absolute',
                // top: 8,
                // right: -16,
                // backgroundColor: '#f5f5f5',
                // color: 'primary.main',
                // '&:hover': {
                //   backgroundColor: '#e0e0e0',
                // },
                // boxShadow: 1,
              }}
            >
              {isListCollapsed ?
                // <Tooltip title={'Expand List'} arrow>
                  <ArrowForwardIos fontSize="small" />
                // </Tooltip>
                :
                // <Tooltip title={'Collapse List'} arrow>
                  <ArrowBackIos fontSize="small" />
                // </Tooltip>
              }
            </IconButton>
            <Grid item xs={15} container alignItems="flex-end"  spacing={1}>
              <Grid item xs/>
              <Grid item xs={4} >
                <LoadingButton 
                  variant="contained"
                  fullWidth 
                  disabled={disableRecommandations} 
                  onClick={generateRecommendations}
                  loading={loadingDQRules}
                >
                  Generate Data Quality Recommendations
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item  xs={15}>
              <Grid item xs={10} >
                <Typography sx={{ fontSize: 18 }} color="primary">
                  Selected Entity Details
                </Typography>
              </Grid>
              <Grid item xs={12} container sx={{ marginBottom: 2 }}>
                <Grid item xs={4}>
                  <Typography variant="body1" textAlign={'left'}>
                    Entity Id
                  </Typography>
                  <Typography color="text.secondary" textAlign={'left'}>
                    {selectedEntityInfo?.id}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" textAlign={'left'}>
                    Entity Name
                  </Typography>
                  <Typography color="text.secondary" textAlign={'left'}>
                    {selectedEntityInfo?.dataEntityName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" textAlign={'left'}>
                    Entity Type
                  </Typography>
                  <Typography color="text.secondary" textAlign={'left'}>
                    {selectedEntityInfo?.entityType}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ marginBottom: 2 }}>
                {/* <Grid item xs={4}>
                  <Typography variant="body1" textAlign={'left'}>
                    Is Core
                  </Typography>
                  <Typography color="text.secondary" textAlign={'left'}>
                    {selectedEntityInfo?.coreEntity?'true': 'false'}
                  </Typography>
                </Grid> */}
                <Grid item xs={8}>
                  <Typography variant="body1" textAlign={'left'}>
                    Entity Description
                  </Typography>
                  <Typography color="text.secondary" textAlign={'left'}>
                    {selectedEntityInfo?.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={10} container alignItems="flex-end">
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom color="primary">
                  Data Quality Recommendation Rules
                  </Typography>
                </Grid>
                <Grid item xs={4} />
              </Grid>
              <Grid item xs={15} />
              <ShadowTable
                rows={recommendedDQTableRows}
                columns={ruleRecommendationColumns}
                tableActionParams={{
                    onButtonClick: onButtonClick,
                    actions: [ShadowTableAction.Add],
                  }}
              
              />
            </Grid>
          </Card>
        </Grid>


        {/* <Grid item xs={12} container sx={{ marginBottom: 4 }} spacing={1}>
          <Grid item xs={10} container alignItems="flex-end">
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom color="primary">
                Source Attributes
              </Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Grid item xs={12} />
          <ShadowTable
            rows={generatedAttributes}
            columns={generatedTableColumns}
          
          />
        </Grid> */}
        

        

      </Grid>
    </Grid>
  )
}
