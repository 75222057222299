import { ConvertedFiles } from "businessObjects"
import { sendRequest } from "requestManager/sendRequest"

const convertedFilesPath = {
  getConvertedFiles: "adminutils/ConvertedFile/getConvertedFiles",
  addConvertedFiles: "adminutils/ConvertedFile/addConvertedFiles",
}

const getConvertedFiles = async (token: string, datapodId:string, serviceConnectionId: number) => {
    const endPoint = convertedFilesPath.getConvertedFiles + `?dataPodId=${datapodId}&serviceConnectionId=${serviceConnectionId}`
    return await sendRequest(endPoint, 'GET', token)
}

const addConvertedFiles = async (token: string, datapodId:string, convertedFiles: ConvertedFiles[]) => {
  const endPoint = convertedFilesPath.addConvertedFiles + `?dataPodId=${datapodId}`
  return await sendRequest(endPoint, 'POST', token, convertedFiles)
}

export const convertedFilesService = {
  getConvertedFiles,
  addConvertedFiles
}
