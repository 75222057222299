import { CardContent, Card, Grid, Typography, TextField, IconButton, Tooltip } from '@mui/material'
import ActionPage from '../ActionPage'
import { EditNote, HomeOutlined } from '@mui/icons-material'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'

type Props = {
  dataPodId?: string
  dataPodName?: string
  dataPodIndustryName?: string
}

const DataPodDetailRibbon = ({ dataPodId, dataPodName, dataPodIndustryName }: Props) => {
  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = `${ROUTES.AddDataPod}?dataPodId=${dataPodId}`
    navigate(dataProfilingRoute)
  }
  
  return (
    <ActionPage>
      <Card sx={{ backgroundColor: 'white', borderRadius: 3, mt: 2 }}>
        <CardContent>
          <Grid container spacing={2} display={'flex'} justifyContent={'space-between'}>
            <Grid item xs={4} display={'flex'} alignItems={'center'}>
              <Typography component="span" variant="subtitle1" sx={{ fontSize: 12, width: 125 }}>
                Data Pod Name
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-dataSource"
                variant="outlined"
                value={dataPodName}
                required
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={4} display={'flex'} alignItems={'center'}>
              <Typography component="span" variant="subtitle1" sx={{ fontSize: 12, width: 90 }}>
                Data Pod Id
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-dataSource"
                variant="outlined"
                value={dataPodId}
                required
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={3.5} display={'flex'} alignItems={'center'}>
              <Typography component="span" variant="subtitle1" sx={{ fontSize: 12, width: 120 }}>
                Industry Name
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-dataSource"
                variant="outlined"
                value={dataPodIndustryName}
                required
                size="small"
                disabled
              />
            </Grid>
            <Grid item xs={.5}>
              <Tooltip title='Edit datapod details'>
                <IconButton>
                  <EditNote fontSize='small' onClick={onClickHome}/>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {/* <Grid item xs={2} /> */}
        </CardContent>
      </Card>
    </ActionPage>
  )
}

export default DataPodDetailRibbon
