import React, { useState, useEffect } from 'react';
import {
  Grid, Card, CardContent, CardHeader, Typography, Box,
  Alert, CircularProgress, IconButton, Tooltip, Paper,
  Snackbar, Button
} from '@mui/material';
import { useGetSessionPodInfo } from "hooks/getSessionPodInfo";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "Routes/constants";
import { PreviousPage } from 'ui-components/Button/PreviousPage';
import { ContentCopy, Download, InfoOutlined } from '@mui/icons-material';

interface ComparisonData {
  sourceCode: string;
  convertedCode: string;
  sourceFileName: string;
  metrics: {
    lucidConfidenceScore: number;
  };
}

const CodePanel: React.FC<{
  title: string;
  code: string;
  onCopy: () => void;
  onDownload: () => void;
}> = ({ title, code, onCopy, onDownload }) => (
  <Paper elevation={2}>
    <Box className="p-3 bg-gray-100 border-b flex justify-between items-center">
      <Typography variant="subtitle1">{title}</Typography>
      <Box>
        <Tooltip title="Copy Code">
          <IconButton size="small" onClick={onCopy}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download">
          <IconButton size="small" onClick={onDownload}>
            <Download fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
    <Box
      className="p-4 max-h-[70vh] overflow-auto"
      sx={{
        '& pre': {
          margin: 0,
          padding: '1rem',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
          fontFamily: 'monospace',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-all'
        }
      }}
    >
      <pre>{code}</pre>
    </Box>
  </Paper>
);

export const ComparingMigrationFiles: React.FC = () => {
  const dataPodInfo = useGetSessionPodInfo();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [comparisonData, setComparisonData] = useState<ComparisonData | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const loadComparisonData = () => {
      try {
        
        const storedData = sessionStorage.getItem('comparisonData');
        console.log('Retrieved stored data:', storedData);

        if (!storedData) {
          throw new Error('No comparison data found in session storage');
        }

        const parsedData = JSON.parse(storedData) as ComparisonData;
        console.log('Parsed comparison data:', parsedData);

        // Validate the parsed data
        if (!parsedData.sourceCode || !parsedData.convertedCode || !parsedData.sourceFileName) {
          throw new Error('Invalid comparison data format');
        }

        setComparisonData(parsedData);
        sessionStorage.removeItem('comparisonData');
      } catch (err) {
        console.error('Error in loadComparisonData:', err);
        setError(err instanceof Error ? err.message : 'Failed to load comparison');
      } finally {
        setIsLoading(false);
      }
    };

    loadComparisonData();
  }, []);

  const handleCopyCode = async (code: string, type: 'source' | 'converted') => {
    try {
      await navigator.clipboard.writeText(code);
      setSnackbarMessage(`${type === 'source' ? 'Source' : 'Converted'} code copied to clipboard`);
      setShowSnackbar(true);
    } catch (err) {
      setSnackbarMessage('Failed to copy code');
      setShowSnackbar(true);
    }
  };

  const handleDownload = (code: string, type: 'source' | 'converted') => {
    try {
      const extension = type === 'source' ? '.sas' : '.py';
      const fileName = `${comparisonData?.sourceFileName.split('.')[0]}_${type}${extension}`;
      const blob = new Blob([code], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setSnackbarMessage(`${type === 'source' ? 'Source' : 'Converted'} code downloaded`);
      setShowSnackbar(true);
    } catch (err) {
      setSnackbarMessage('Failed to download code');
      setShowSnackbar(true);
    }
  };

  const onClickBack = () => {
    const dataModelingRoute = generatePath(ROUTES.DataModelingLakeMigrationModels, {
      dataPodId: dataPodInfo?.id,
    });
    navigate(dataModelingRoute);
  };

  return (
    <div className="flex flex-col h-screen p-4">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PreviousPage onClick={onClickBack} pageName={'Back'} />
        </Grid>
      </Grid>

      {isLoading ? (
        <Box className="flex items-center justify-center flex-1">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" className="m-4">{error}</Alert>
      ) : comparisonData ? (
        <Card className="flex-1 mt-4">
          <CardHeader
            title={
              <Box className="flex items-center gap-2">
                <Typography variant="h6">
                  {`File Comparison: ${comparisonData.sourceFileName}`}
                </Typography>
              </Box>
            }
            subheader={
              <Typography color="textSecondary">
                Confidence Score: {comparisonData.metrics.lucidConfidenceScore.toFixed(2)}%
              </Typography>
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CodePanel
                  title="Source Code (.sas)"
                  code={comparisonData.sourceCode}
                  onCopy={() => handleCopyCode(comparisonData.sourceCode, 'source')}
                  onDownload={() => handleDownload(comparisonData.sourceCode, 'source')}
                />
              </Grid>
              <Grid item xs={6}>
                <CodePanel
                  title="Converted Code (PySpark)"
                  code={comparisonData.convertedCode}
                  onCopy={() => handleCopyCode(comparisonData.convertedCode, 'converted')}
                  onDownload={() => handleDownload(comparisonData.convertedCode, 'converted')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : null}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ComparingMigrationFiles;