import {
  Box,
  Grid,
  IconButton,
  Typography,
  Card,
  MenuItem,
  TextField,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  InputAdornment,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  Tooltip,
  Menu,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import InsightsIcon from '@mui/icons-material/Insights'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'

import { useGetProfileEntitites } from '../../../hooks/dataProfilingHooks'

import { useGetAllDataSystemsByPodId, useGetDataSystemByDataSystemId } from '../../../hooks/dataSystemHooks'
import { useGetSubjectArea } from '../../../hooks/subjectAreaHooks'

import {
  AppProfileEntity,
  DataTable,
  Entity,
  NewEntity,
  RunNewJobParams,
  RunStatusResponse,
} from '../../../businessObjects'

import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import { green } from '@mui/material/colors'
import { RootState } from '../../../app/store'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
  useAddNewEntity,
  useDeleteEntityMap,
  useGetDataLakeZones,
  useGetEntities,
  useUpdateEntity,
  useUpdateEntityMapFunc,
  useUpdateEntityName,
} from '../../../hooks/entityHooks'
import CancelIcon from '@mui/icons-material/Cancel'
import ResultStatus from '../../../ui-components/ResultStatus'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import { DataMappingResultsEditPopUp, DeletePopUp } from './DataMappingPopUps'
import { useGenerateMappings, useScanAndGenerateMappings } from '../../../hooks/dataMappingHooks'
import { useGetTableByDataPodAndDataSystemId, useUpdateDataTable } from '../../../hooks/dataTableHooks'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { useGetServiceConnection } from 'hooks/dataEngineeringHooks'
import { usePostDatabricksJobRunNew } from 'hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { filterActiveRunIds, initiateActiveRunId } from 'features/notification/notificationSlice'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
export const DataMappingResults = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const [selectedSourceID, setSelectedSourceID] = useState<number>()

  const [searchText, setSearchText] = useState<string>('')

  const [dataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const { dataSystemInfo } = useGetDataSystemByDataSystemId(selectedSourceID)
  const { subjectAreaInfo } = useGetSubjectArea(dataSystemInfo?.subjectAreaId)
  const { generateSuccess, generating, generatingError, scanAndGen, data } = useGenerateMappings(
    dataPodId,
    selectedSourceID ? Number(selectedSourceID) : undefined,
  )
  const { dataTables, refetchDataTables } = useGetTableByDataPodAndDataSystemId(dataPodId, selectedSourceID)

  // const [selectedTableIds, setSelectedTableIds] = useState<number[]>([])
  const [selectedTableIds, setSelectedTableIds] = useState<number | undefined>()
  // const [disableGenerateTableIds, setDisableGenerateTableIds] = useState<number[]>([])
  const [disableGenerateTableIds, setDisableGenerateTableIds] = useState<number | undefined>()
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])

  useEffect(() => {
    if (generatingError) {
      setErrorMessage('GenAI server is busy. Try again in some time.')
      setErrorState(true)
    } else if (generateSuccess) {
      setSuccessMessage('Generated Mappings Success.')
      setSuccessState(true)
    }
    console.log(generatingError)
  }, [generateSuccess, generatingError])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const dataSources = useMemo(() => {
    if (!dataSystems.length) return []
    return dataSystems
  }, [dataSystems])

  const dataSourceTables = useMemo(() => {
    if (!dataTables?.length) return []
    return dataTables
  }, [dataSystemID, dataTables])

  // useEffect(() => {
  //   if (generateSuccess) {
  //     refetchDataTables()
  //   }
  // }, [generateSuccess])

  // function handleSearch(value: string): void {
  //   return
  // }
  // const refreshEntityData = () => {
  //   refetchEntities && refetchEntities()
  // }

  const confirmGenerate = async () => {
    // await scanAndGen(dataSourceTables.filter((dt) => selectedTableIds.includes(dt.id)))
    await scanAndGen(dataSourceTables.filter((dt) => selectedTableIds == dt.id))
  }

  const changeDataSource = (sourceId: string) => {
    setSelectedSourceID(Number(sourceId))
    const newSourceMapRoute = generatePath(ROUTES.DataMappingResults, {
      dataPodId: dataPodId,
      dataSystemID: sourceId,
    })
    navigate(newSourceMapRoute)
  }
  return (
    <ActionPage>
      {/* <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid> */}

      <ResultStatus
        severtiy="success"
        showStatus={deleteSuccess}
        closeStatus={setDeleteSuccess}
        alertMessage={`SUCCESS: Deleted configuration`}
      />
      {successMessage && (
        <ResultStatus
          severtiy="success"
          showStatus={successState}
          closeStatus={setSuccessState}
          alertMessage={successMessage}
        />
      )}
      {generatingError && (
        <ResultStatus
          severtiy="error"
          showStatus={generatingError}
          closeStatus={setErrorState}
          alertMessage={'GenAI server is busy. Try again in some time.'}
        />
      )}
      <Box style={cardContainer}>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ mt: 0 }}>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item container xs={15} spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Data Source Name</Typography>
                  </Grid>
                  <Grid item />

                  <Grid item xs={5}>
                    <Typography variant="subtitle1">Data Domain Name</Typography>
                  </Grid>
                  <Grid item xs={12} md={15} />

                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id="outlined-select-dataSource"
                        select
                        variant="outlined"
                        value={selectedSourceID ?? 0}
                        required
                        size="small"
                        type="number"
                        onChange={({ target }) => {
                          // setSelectedSourceID(Number(target.value))
                          // setSearchText('')
                          changeDataSource(target.value)
                        }}
                      >
                        {dataSources.map((d) => (
                          <MenuItem key={d.id} value={d.id}>
                            <Typography fontSize={'small'}> {d.dataSystemName}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        id="outlined-select-dataSource"
                        variant="outlined"
                        value={subjectAreaInfo?.subjectAreaName ?? ''}
                        required
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} container alignItems={'center'} spacing={1}>
            <Grid item xs={5}>
              <TextField
                placeholder="Search"
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<InsightsIcon fontSize="small" />}
                // disabled={
                //   !selectedTableIds.length || selectedTableIds.some((tid) => disableGenerateTableIds.includes(tid) || generating)
                // }
                disabled={
                  !selectedTableIds || disableGenerateTableIds === selectedTableIds || generating
                }
                onClick={confirmGenerate}
              >
                Generate Mappings
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} />

          <Grid item container xs={14}>
            <DataMappingTable
              dataTables={dataSourceTables}
              tableIds={selectedTableIds}
              refreshDataTables={refetchDataTables}
              updateSelectedTableIds={setSelectedTableIds}
              dataPodId={dataPodId}
              dataSystemId={selectedSourceID}
              searchText={searchText}
              generateError={generatingError}
              generating={generating}
              generateSuccess={generateSuccess}
              updateGenDisabledTableIds={setDisableGenerateTableIds}
            />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

type DataMappingTableProps = {
  dataTables: DataTable[]
  // tableIds: number[]
  tableIds:number | undefined
  // updateSelectedTableIds: (ids: number[]) => void
  updateSelectedTableIds: (ids: number | undefined) => void
  // updateGenDisabledTableIds: (ids: number[]) => void
  updateGenDisabledTableIds: (ids: number |undefined) => void
  refreshDataTables: () => Promise<void>
  generating: boolean
  generateSuccess: boolean
  generateError: boolean
  dataPodId?: string
  dataSystemId?: number
  searchText?: string
}
type EditOptions = 'Edit current entity content' | 'Remap to existing entity' | 'New entity'

const DataMappingTable = ({
  dataTables,
  tableIds,
  updateSelectedTableIds,
  refreshDataTables,
  dataSystemId,
  dataPodId,
  searchText,
  generateError,
  generating,
  generateSuccess,
  updateGenDisabledTableIds,
}: DataMappingTableProps) => {
  // const [selectedTableIDs, setSelectedTableID] = useState<number[]>([])
  const [dsTables, setDSTables] = useState<AppProfileEntity[]>([])
  const [showPopUp, setShowPop] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [editWarning, setEditWarning] = useState<boolean>(false)
  const [entityMapId, setEntityMapId] = useState<number>()
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState<AppProfileEntity>()
  const [newEntity, setNewEntity] = useState<Entity>()
  const [selectedEntityId, setSelectedEntityId] = useState<number>()
  const [contextPromptText, setContextPromptText] = useState<string>('')
  const [rightTable, setRightTable] = useState<DataTableRow[]>([])
  const [rightTableFilter, setRightTableFilter] = useState<DataTableRow[]>([])
  const [isTableAsc, setIsTableAsc] = useState<boolean>(true)
  const [selectedRowEntityName, setSelectedRowEntityName] = useState<string | undefined>('')
  const [leftTable, setLeftTable] = useState<DataTable[]>([])

  const [editOptions, setEditOptions] = useState<'editContents' | 'editMapping' | 'newEntity'>('editContents')
  const [isNewEntity, setIsNewEntity] = useState<boolean>(true)
  const { profileEntities, refetchEntities } = useGetProfileEntitites(
    dataPodId,
    dataSystemId,
    undefined,
    { recordsPerPage: 2600, pageNumber: 1 },
    searchText,
  )
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
  const [filterApplied, setFilterApplied] = useState<'mapped' | 'unmapped' | 'all'>('all')
  const [toScanTables, setToScanTables] = useState<DataTable[]>([])
  const options = ['Edit current entity content', 'Remap to existing entity', 'New entity']

  const sortTableFn = (t1: DataTable, t2: DataTable) => {
    if (t1.name < t2.name) {
      return isTableAsc ? -1 : 1
    }
    if (t1.name > t2.name) {
      return isTableAsc ? 1 : -1
    }
    return 0
  }

  // const { confirmUpdateTable, updatingDataTable } = useUpdateDataTable(dataPodId)

  // const { generateSuccess, generating, generatingError, scanAndGen } = useScanAndGenerateMappings(
  //   dataPodId,
  //   dataSystemId ? Number(dataSystemId) : undefined,
  // )

  const { entities } = useGetEntities(dataPodId)
  const { deleteEntityMapId } = useDeleteEntityMap()

  const navigate = useNavigate()

  const { saveNewEntity } = useAddNewEntity(selectedRow?.datapodId ?? '')

  const { updateEntityFunc } = useUpdateEntity()

  const { updateEntityMapFunc } = useUpdateEntityMapFunc()


  const noEntries = useMemo(() => {
    if (searchText && !profileEntities?.length) return false
    return !profileEntities?.length
  }, [profileEntities, searchText])

  const pEntities = useMemo(() => {
    return profileEntities?.length ? profileEntities : []
  }, [profileEntities, searchText])

  useEffect(() => {
    if (profileEntities && dataSystemId) {
      // setSelectedTableID([])
      updateSelectedTableIds(undefined)
      const sortedProfileEntites = profileEntities.sort((p1, p2) => (p1.dataTableName > p2.dataTableName ? 1 : -1))
      setDSTables(sortedProfileEntites)
      const rtRows = sortedProfileEntites
        .sort((t1, t2) => (t1.dataTableName > t2.dataTableName ? 1 : -1))
        .map((dt) => ({
          id: `${dt.dataTableID}`,
          values: [dt.dataTableName, dt.dataEntityName, dt.description, 'EDITSELECTION', 'VIEW', 'DELETE'],
        })) as DataTableRow[]
      // updateGenDisabledTableIds(sortedProfileEntites.map((sp) => sp.dataTableID))
      updateGenDisabledTableIds(sortedProfileEntites.length? sortedProfileEntites[0].dataTableID : undefined)
      setRightTable(rtRows)
      setRightTableFilter(rtRows)
      //setProcessingData(false)
    }
  }, [profileEntities, dataSystemId, searchText])

  useEffect(() => {
    if (generateSuccess) {
      refreshDataTables()
      refetchEntities()
    }
  }, [generateSuccess, generating])

  const rightTableColumns = useMemo(() => {
    return [
      {
        label: 'Table Name',
        sortableColumn: true,
      },
      {
        label: 'Entity Name',
        sortableColumn: true,
      },
      {
        label: 'Entity Description',
        sortableColumn: true,
      },
      {
        label: 'Fine Tune',
      },
      {
        label: 'Attribute Mapping',
      },
      {
        label: 'Delete',
      },
    ]
  }, [])

  // const leftTableColumns = useMemo(() => {
  //   return [{ label: 'List Of Tables' }]
  // }, [])

  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    const tbID = Number(rowId)
    const selRow = dsTables.find((r) => Number(r.dataTableID) === tbID)
    setSelectedRow(selRow)
    setSelectedRowEntityName(selRow?.dataEntityName)
    if (selRow) {
      if (actionType === ShadowTableAction.Editable) {
        setEditWarning(true)
        setShowPop(true)
      }

      if (actionType === ShadowTableAction.Viewable) {
        const attributesRoute = generatePath(ROUTES.DataMappingAttribues, {
          dataPodId: selRow?.datapodId,
          dataSystemID: selRow.dataSystemID,
          tableID: selRow.dataTableID,
          entityID: selRow.dataEntityID,
        })
        navigate(attributesRoute)
      }

      if (actionType === ShadowTableAction.Deletable) {
        setEntityMapId(selRow?.mapId)
        setShowDeleteWarning(true)
      }
    }
  }

  const onEditSelectionClick = (rowId: string, actionType: ShadowTableAction, option: EditOptions) => {
    const tbID = Number(rowId)
    const selRow = dsTables.find((r) => Number(r.dataTableID) === tbID)
    setSelectedRow(selRow)
    switch (option) {
      case 'Edit current entity content':
        setEditWarning(true)
        setShowPop(true)
        break
      case 'New entity':
        const page = generatePath(ROUTES.DataMappingEditNewEntity, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemId,
          entityID: selRow?.dataEntityID,
          dataTableId: tbID,
        })
        navigate(page)
        break
      case 'Remap to existing entity':
        const RemapPage = generatePath(ROUTES.DataMappingEditExistingRemap, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemId,
          entityID: selRow?.dataEntityID,
          dataTableId: tbID,
        })
        navigate(RemapPage)
        break
    }
  }

  const getTableColumns = (id: number) => {
    const tbID = Number(id)
    // const tbIds = selectedTableIDs.includes(tbID)
    //   ? selectedTableIDs.filter((tid) => tid != tbID)
    //   : [...selectedTableIDs, tbID]

    // setSelectedTableID(tbIds)
    // const tbIds = tableIds.includes(tbID) ? tableIds.filter((tid) => tid != tbID) : [...tableIds, tbID]

    const tbIds = tableIds == tbID ? undefined : tbID

    updateSelectedTableIds(tbIds)
    // const filteredTables = rightTable.filter((rt) => tbIds.includes(Number(rt.id)))
    // setRightTableFilter(filteredTables.length ? filteredTables : rightTable)
  }

  const closePopup = (close: boolean) => {
    setSelectedRow(undefined)
    setNewEntity(undefined)
    setIsNewEntity(false)
    setEditOptions('editContents')
    setContextPromptText('')
    setShowPop(close)
    setEditWarning(close)
    //refreshEntityData()
  }

  const onChangeNewEntity = (name: string, value: string) => {
    setNewEntity((prv) => ({ ...prv, [name]: value } as Entity))
  }

  const updateEntity = async () => {
    if (selectedRow) {
      const { dataEntityID, dataEntityName } = selectedRow
      const updatedEntity = await updateEntityFunc({
        id: dataEntityID,
        dataEntityName: dataEntityName,
        description: selectedRow.description,
        profileName: 'ManualAdjustment',
        profileState: 'Success',
        coreEntity: selectedRow.coreEntity,
        entityType: selectedRow.entityType,
        dataLakeZone: 1,
      })
      if (updatedEntity) {
        const filterRowValues = rightTable.find((tb) => tb.id === String(selectedRow.dataTableID))
        if (filterRowValues) {
          const tbValues = rightTable.map((tv) =>
            tv.id != String(filterRowValues.id)
              ? tv
              : {
                  ...tv,
                  values: [
                    filterRowValues.values[0],
                    updatedEntity.dataEntityName,
                    updatedEntity.description,
                    'EDIT',
                    'VIEW',
                    'DELETE',
                  ],
                },
          )

          setRightTable(tbValues)
          setRightTableFilter(tbValues)
          refetchEntities()
        }
      }
    }
  }
  const saveNewEntityMap = async () => {
    if (newEntity) {
      const { description, dataEntityName, coreEntity } = newEntity
      const nEntity: NewEntity = {
        dataEntityName,
        description,
        profileName: 'clientApp',
        coreEntity,
      }
      const savedEntityRes = await saveNewEntity(nEntity)
      if (savedEntityRes && selectedRow) {
        const newEntityMapData = await updateEntityMapFunc({
          id: selectedRow?.mapId ?? 0,
          guidedPromt: contextPromptText,
          dataTableId: Number(selectedRow?.dataTableID),
          dataEntityId: savedEntityRes.id,
          profileName: 'clientApplication',
          mapType: '',
        })

        if (newEntityMapData) {
          setNewEntity(undefined)
          setIsNewEntity(false)
          const filteredTbv = rightTable.find((tb) => tb.id === String(selectedRow.dataTableID))
          if (filteredTbv) {
            const tbV = rightTable.map((rt) =>
              rt.id !== filteredTbv.id
                ? { ...rt }
                : {
                    ...rt,
                    values: [
                      selectedRow.dataTableName,
                      newEntity.dataEntityName,
                      newEntity.description,
                      'EDIT',
                      'VIEW',
                      'DELETE',
                    ],
                  },
            )

            setRightTableFilter(tbV)
            refetchEntities()
          }
        }
      }
    }
  }

  const saveNewMap = async () => {
    if (contextPromptText && selectedRow && selectedEntityId) {
      const { mapId, dataTableID } = selectedRow
      const savedNewMap = await updateEntityMapFunc({
        id: mapId,
        guidedPromt: contextPromptText,
        dataTableId: Number(dataTableID),
        dataEntityId: selectedEntityId,
        profileName: 'clientApplication',
        mapType: '',
      })
      const foundSelectedEntity = entities.find((et) => et.id === selectedEntityId)
      const filterRowValues = rightTableFilter.find((tb) => tb.id === String(selectedRow.dataTableID))
      if (savedNewMap && foundSelectedEntity && filterRowValues) {
        const tbValues = rightTable.map((tv) =>
          tv.id != String(dataTableID)
            ? tv
            : {
                ...tv,
                values: [
                  filterRowValues.values[0],
                  foundSelectedEntity.dataEntityName,
                  foundSelectedEntity.description,
                  'EDIT',
                  'VIEW',
                  'DELETE',
                ],
              },
        )

        setRightTable(tbValues)
        setRightTableFilter(tbValues)
        refetchEntities()
      }
    }
  }

  const onSubmit = () => {
    if (editOptions === 'editContents') {
      updateEntity()
    }
    if (editOptions === 'editMapping') {
      saveNewMap()
    }

    if (editOptions === 'newEntity') {
      saveNewEntityMap()
    }

    closePopup(!showPopUp)
  }

  const onChange = (name: string, value: string) => {
    setSelectedRow(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        } as AppProfileEntity),
    )
    setIsNewEntity(false)
    setNewEntity(undefined)
  }

  const confirmDeleteEntityMap = async () => {
    if (entityMapId) {
      const deletedMapId = await deleteEntityMapId(entityMapId)
      if (deletedMapId && selectedRow) {
        const filteredTableRows = rightTableFilter.filter((tr) => tr.id !== String(selectedRow.dataTableID))
        setRightTable(filteredTableRows)
        setRightTableFilter(filteredTableRows)
        setDeleteSuccess(true)
        refetchEntities()
        closeDeleteWarning()
        setSelectedRow(undefined)
        refreshDataTables()
      }
    }
  }

  const closeDeleteWarning = () => {
    setEntityMapId(undefined)
    setShowDeleteWarning(false)
  }

  const selectNewEntityId = (entityId: unknown) => {
    setSelectedEntityId(Number(entityId))
  }

  // const confirmGenerate = async () => {
  //   await scanAndGen(selectedTableIDs)
  // }
  const unSortedTables = useMemo(() => {
    if (!dataTables.length) return []
    return dataTables.filter((tb) => tb.isActive)
  }, [dataTables])

  useEffect(() => {
    if (unSortedTables) {
      setLeftTable(unSortedTables)
    }
  }, [unSortedTables])

  const handleFilterSelect = (id: typeof filterApplied) => {
    setFilterApplied(id)
    switch (id) {
      case 'mapped':
        const mapped = unSortedTables.filter((dt) => dt.mappingStatus && dt.mappingStatus)
        setLeftTable(mapped)
        setFilterAnchor(null)
        break
      case 'unmapped':
        const unmapped = unSortedTables.filter((dt) => !dt.mappingStatus)
        setLeftTable(unmapped)
        setFilterAnchor(null)
        break
      case 'all':
        setLeftTable(unSortedTables)
        setFilterAnchor(null)
        break
    }
  }

  const sortedLeftedTable = useMemo(() => {
    return leftTable.sort(sortTableFn)
  }, [leftTable, isTableAsc])

  const handleSelectToScan = (selectDataTable: DataTable) => {
    const toSetTables = toScanTables.some((tb) => tb.id === selectDataTable.id)
      ? toScanTables.filter((tb) => tb.id != selectDataTable.id)
      : [...toScanTables, selectDataTable]

    setToScanTables(toSetTables)
  }

  return (
    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      <ResultStatus
        severtiy="success"
        showStatus={deleteSuccess}
        closeStatus={setDeleteSuccess}
        alertMessage={`SUCCESS: Deleted Entity Map ${selectedRowEntityName}`}
      />
      {showPopUp && (
        <ResultStatus
          severtiy="warning"
          showStatus={editWarning}
          closeStatus={setEditWarning}
          alertMessage={`Warning: Editing Entity "${selectedRow?.dataEntityName}" will impact associated attributes.`}
        />
      )}
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmDeleteEntityMap}
        closeDeleteWarning={closeDeleteWarning}
        itemToDelete={selectedRow?.dataEntityName}
      />
      <DataMappingResultsEditPopUp
        showPopUp={showPopUp}
        onSubmit={onSubmit}
        closePopup={closePopup}
        editOptions={editOptions}
        setEditOptions={setEditOptions}
        onChange={onChange}
        selectedRow={selectedRow}
        selectNewEntityId={selectNewEntityId}
        selectedEntityId={selectedEntityId}
        entities={entities}
        onChangeNewEntity={onChangeNewEntity}
        newEntity={newEntity}
        contextPromptText={contextPromptText}
        setContextPromptText={setContextPromptText}
      />

      {/* <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={confirmGenerate }>
          Generate Mappings
        </Button>
      </Grid>
      <Grid item xs={15}  /> */}

      <>
        <Grid item xs={3}>
          <Card sx={{ backgroundColor: 'white' }}>
            <CardHeader
              disableTypography
              sx={{ backgroundColor: 'grey', height: 40 }}
              title={
                <>
                  <Grid item xs={12} container alignItems="baseline" spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ fontSize: 16 }} gutterBottom>
                        {'List of Tables'}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        title={`sort ${isTableAsc ? 'desc' : 'asc'}`}
                        onClick={() => setIsTableAsc(!isTableAsc)}
                      >
                        {isTableAsc ? (
                          <ArrowDownwardOutlined fontSize="small" />
                        ) : (
                          <ArrowUpwardOutlined fontSize="small" />
                        )}
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton title="Filter" onClick={({ currentTarget }) => setFilterAnchor(currentTarget)}>
                        <FilterAltOutlinedIcon fontSize="small" />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={filterAnchor}
                        open={Boolean(filterAnchor)}
                        onClose={handleFilterSelect}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          'aria-multiselectable': 'true',
                        }}
                      >
                        {['mapped', 'unmapped', 'all'].map((itm) => (
                          <MenuItem
                            selected={filterApplied === itm}
                            key={itm}
                            onClick={() => handleFilterSelect(itm as typeof filterApplied)}
                          >
                            {itm}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Grid>
                  </Grid>
                </>
              }
            />
            <Divider />
            <List sx={{ width: '100%', maxWidth: 350, marginTop: -2, marginLeft: -2 }} dense>
              {sortedLeftedTable.map((p, ix) => (
                <ListItem key={ix}>
                  {/* <ListItemButton  onClick={() => getTableColumns(p.id)}> */}
                  <ListItemIcon>
                    <Checkbox
                      sx={{
                        color: 'green',
                        '&.Mui-checked': {
                          color: green[600],
                        },
                      }}
                      edge="start"
                      tabIndex={-1}
                      disabled={p.mappingStatus}
                      onClick={() => getTableColumns(p.id)}
                      // checked={tableIds.includes(Number(p.id))}
                      checked={tableIds == Number(p.id)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      p.name.length > 15 ? (
                        <Tooltip title={p.name} placement="top-start">
                          <Typography variant="inherit">{p.name.slice(0, 20) + '...'}</Typography>
                        </Tooltip>
                      ) : (
                        <>{p.name}</>
                      )
                    }
                  />
                  {/* </ListItemButton> */}
                  {/* <Tooltip title={'Generate Mapping'} placement="top-start">
                      <InsightsIcon onClick={() => scanAndGen(p.id)}></InsightsIcon>
                    </Tooltip> */}
                  {p.mappingStatus && (
                    <CheckCircleIcon fontSize="small" color="primary" titleAccess="Generated Mapping" />
                  )}
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>
      </>

      {/* {profileEntities?.length ? (
        <>
          <Grid item xs={3}>
            <Card sx={{ backgroundColor: 'white' }}>
              <CardHeader
                disableTypography
                sx={{ backgroundColor: 'grey' }}
                title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List Of Tables'}</Typography>}
              />
              <Divider />
              <List sx={{ width: '100%', maxWidth: 360, marginTop: -2 }} dense>
                {profileEntities?.map((p, ix) => (
                  <ListItem key={ix}>
                    <ListItemButton onClick={() => getTableColumns(p.dataTableID)}>
                      <ListItemIcon>
                        <Checkbox
                          sx={{
                            color: 'green',
                            '&.Mui-checked': {
                              color: green[600],
                            },
                          }}
                          edge="start"
                          tabIndex={-1}
                          checked={selectedTableIDs.includes(Number(p.dataTableID))}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={p.dataTableName } />
                    </ListItemButton>
                    <Button onClick={() => scanAndGen(p.dataTableID)}> Generate Mapping</Button>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
        </>
      ) : (
        <></>
      )} */}
      {/* <ProgressBar loading={generating} /> */}
      {/* <AnimatedLoader height="50%" width="40%" loading={generating} /> */}
      {noEntries && !generating && (
        <>
          <Grid item xs={5}>
            <Typography gutterBottom variant="h3" align="center" color={'error'}>
              No Data Found
            </Typography>
          </Grid>
          <Grid item xs />
        </>
      )}

      <Grid item container xs={9}>
        {!noEntries && (
          <ShadowTable
            rows={rightTableFilter}
            columns={rightTableColumns}
            selectionOptions={options}
            tableActionParams={{
              onButtonClick: onButtonClick,
              onEditSelectionClick: onEditSelectionClick,
              actions: [ShadowTableAction.Editable, ShadowTableAction.Deletable],
            }}
          />
        )}
      </Grid>
    </Grid>
  )
}
