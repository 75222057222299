import { useEffect, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'
import { AddOutlined } from '@mui/icons-material'
import { useGetQueryString } from '../../hooks/queryStringHook'
import ActionPage from '../../ui-components/ActionPage'
import {
  CardHeader,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  IconButton,
} from '@mui/material'
import { AnalyticsMeasure } from '../../businessObjects'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useGetAnalyticMeasureByGroupMeasureId } from '../../hooks/analyticsMeasureHooks'
import GroupEntities from './GroupEntities'
import MeasureDetailCard from './MeasureDetailCard'
import GenerateMeasureNotebook from './GenerateMeasureNotebook'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { DataScienceModels } from './DataScienceModels'

type ViewResultsParams = {
  dataPodId: string
}

const AnalyticsMeasureResults = () => {
  const groupId = useGetQueryString('groupId')
  const measureId = useGetQueryString('measureId')
  const { dataPodId } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)

  const { analyticsGroupMeasureLoading, analyticsMeasureList } = useGetAnalyticMeasureByGroupMeasureId(
    dataPodId,
    groupId,
  )

  const [selectedAnalyticMeasure, setSelectAnalyticMeasure] = useState<AnalyticsMeasure>()
  const [collapseForm, setCollapseForm] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (measureId && analyticsMeasureList.length) {
      const preSelectMeasure: any = analyticsMeasureList?.find((p) => p.analyticsMeasureId === parseInt(measureId))
      selectAnalyticMeasure(preSelectMeasure)
    }
  }, [measureId, analyticsMeasureList])

  const selectAnalyticMeasure = (measure: AnalyticsMeasure) => {
    if (measure?.analyticsMeasureId) {
      setSelectAnalyticMeasure(measure)
    }
  }

  const onClickBack = () => {
    let groupPage = generatePath(ROUTES.DataAnalyticsGroupResults, {
      dataPodId: dataPodId,
    })

    if (groupId) {
      groupPage = groupPage + '?groupId=' + groupId
    }

    navigate(groupPage)
  }

  const editMeasure = () => {
    let editMeasurePage = generatePath(ROUTES.AddDataAnalyticsMeasuresResults, {
      dataPodId: dataPodId,
    })

    if (selectedAnalyticMeasure?.analyticsGroupId) {
      editMeasurePage = editMeasurePage + '?groupId=' + selectedAnalyticMeasure?.analyticsGroupId
    }

    if (selectedAnalyticMeasure?.analyticsMeasureId) {
      editMeasurePage = editMeasurePage + '&measureId=' + selectedAnalyticMeasure?.analyticsMeasureId
    }

    if (selectedAnalyticMeasure?.analyticsMeasureId) {
      editMeasurePage = editMeasurePage + '&action=edit'
    }

    navigate(editMeasurePage)
  }

  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  return (
    <ActionPage>
      <Grid xs={3}>
        {/* justifyContent={'center'} */}
        <Button variant="outlined" onClick={onClickBack} startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}>
          Back
        </Button>
      </Grid>
      <Grid item container xs={14} spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
        <CardHeader
          disableTypography
          title={
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
              <Typography variant="h4" color="primary">
                {'Analytics Measures'}
              </Typography>
            </Stack>
          }
        />
      </Grid>
      {/* <Grid item xs={0.5}>
        <IconButton onClick={onClickHome}>
          <HomeOutlinedIcon />
        </IconButton>
      </Grid> */}

      <Grid item container justifyContent={'center'} xs={14} spacing={2}>
        <Grid item xs={3}>
          <Card sx={{ backgroundColor: 'white' }}>
            <CardHeader
              disableTypography
              sx={{ backgroundColor: 'grey' }}
              title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List Of Analytics Measure'}</Typography>}
            />
            <Divider />
            {analyticsGroupMeasureLoading ? (
              <>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </>
            ) : (
              <List dense sx={{ p: 0, maxHeight: 500, overflowY: 'auto' }}>
                {analyticsMeasureList.length > 0 ? (
                  analyticsMeasureList?.map((p: AnalyticsMeasure, index: number) => (
                    <ListItem key={p.analyticsMeasureId + index} sx={{ p: 0 }}>
                      <ListItemButton
                        onClick={() => selectAnalyticMeasure(p)}
                        selected={selectedAnalyticMeasure?.analyticsMeasureId === p.analyticsMeasureId}
                      >
                        <ListItemText primary={p.analyticsMeasureName} />
                      </ListItemButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography sx={{ p: 1, textAlign: 'center' }}>No Data</Typography>
                )}
              </List>
            )}
          </Card>
        </Grid>

        <Grid item xs={9}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MeasureDetailCard analyticsMeasureLoading={false} selectedAnalyticMeasure={selectedAnalyticMeasure} />
          </Grid>

          {selectedAnalyticMeasure?.analyticsMeasureId && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <DataScienceModels
                dataPodId={dataPodId}
                measureId={selectedAnalyticMeasure?.analyticsMeasureId}
                measureName={selectedAnalyticMeasure?.analyticsMeasureName}
              />
            </Grid>
          )}

          {selectedAnalyticMeasure?.analyticsMeasureId && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <GenerateMeasureNotebook
                measureId={selectedAnalyticMeasure?.analyticsMeasureId}
                measureDescription={selectedAnalyticMeasure?.analyticsMeasureDescription}
                measureName={selectedAnalyticMeasure?.analyticsMeasureName}
                editMeasure={editMeasure}
                isEditOrAddMode={false}
                collapseForm={collapseForm}
                setCollapseForm={setCollapseForm}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <GroupEntities group_Id={groupId && groupId ? Number(groupId) : undefined} />
          </Grid>
        </Grid>
      </Grid>
    </ActionPage>
  )
}

export default AnalyticsMeasureResults
