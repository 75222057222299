import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Alert, AlertTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type ErrorDisplayProps = {
  errors: any;
  hasWarnings: boolean;
  expanded: boolean;
  onToggleExpand: () => void;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ errors = [], hasWarnings = false, expanded, onToggleExpand }) => {
  return (
    <div style={{ margin: '16px 0' }}>
      <Accordion expanded={expanded} onChange={onToggleExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="error-list-content"
          id="error-list-header"
        >
          {hasWarnings ? (
            <Alert severity="warning" icon={<WarningIcon />} style={{ flex: 1 }}>
              <AlertTitle>Warning</AlertTitle>
              {errors.length} issues found
            </Alert>
          ) : 
          (
            <></>
            // <Alert severity="success" icon={<CheckCircleIcon />} style={{ flex: 1 }}>
            //   <AlertTitle>Success</AlertTitle>
            //   No errors found
            // </Alert>
          )}
        </AccordionSummary>

        <AccordionDetails>
          {hasWarnings && (
            <Typography component="ul">
              {errors.map((error:any, index:any) => (
                <li key={index}>{error}</li>
              ))}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ErrorDisplay;