import React, { Component, useEffect, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

import Logo from 'ui-icons/LogoBlue.svg'

import StepperComponent from 'pages/Authentication/SignUp/StepperComponent'
import EmailAddress from 'pages/Authentication/SignUp/EmailAddress'
import AccountDetails from 'pages/Authentication/SignUp/AccountDetails'
import UserDetails from 'pages/Authentication/SignUp/UserDetails'
import PricingTiers from 'pages/Authentication/SignUp/PricingTiers'
import Confirmation from 'pages/Authentication/SignUp/Confirmation'
import { LucidAccount, UserAccount } from 'businessObjects'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import { callMsGraph } from 'graph'
import { graphConfig } from 'authConfig'
import { environment } from 'utils/environment'
import { AuthenticationResult } from '@azure/msal-browser'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import config from 'amplifyconfiguration.json';
import { Authenticator } from '@aws-amplify/ui-react';

const accountSteps = [
  {
    label: 'Email Address',
    description: 'Sign up to get access to Lucid Data Hub Application.',
    component: EmailAddress,
  },
  {
    label: 'Account Details',
    description: 'Enter your Account Information to get access to Lucid Data Hub Application.',
    component: AccountDetails,
  },
  {
    label: 'User Details',
    description: 'Enter your User Information to get access to Lucid Data Hub Application.',
    component: UserDetails,
  },
  {
    label: 'Pricing Tiers',
    description: 'Select a user plan to get access to Lucid Data Hub Application',
    component: PricingTiers,
  },
  {
    label: 'Confirmation',
    description: 'Review and submit information',
    component: Confirmation,
  },
].filter(step => !(environment.isEnterpriseApp && step.label === 'Pricing Tiers'))

const userSteps = [
  {
    label: 'User Details',
    description: 'Enter your User Information to get access to Lucid Data Hub Application.',
    component: UserDetails,
  },
  {
    label: 'Pricing Tiers',
    description: 'Select a user plan to get access to Lucid Data Hub Application',
    component: PricingTiers,
  },
  {
    label: 'Confirmation',
    description: 'Review and submit information',
    component: Confirmation,
  },
].filter(step => !(environment.isEnterpriseApp && step.label === 'Pricing Tiers'))

const SignUpHome = () => {
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()
  // const { authStatus } = useAuthenticator(context => [context.authStatus]);
  const { accountId, lucidUserId, lucidAccount } = useLucidAuthContext()
  let steps = lucidAccount == null ? accountSteps : userSteps
  const [activeStep, setActiveStep] = useState<number>(0)
  const [lucidAccountInput, setLucidAccountInput] = useState<LucidAccount>({
    accountId: '',
    accountName: '',
    accountDescription: '',
    accountTenantId: '',
    registeredUserId: 0,
    planId: environment.isEnterpriseApp ? Number(environment.enterprisePlanId) : 0,
  })
  const [userAccount, setUserAccount] = useState<UserAccount>({
    id: 0,
    accountId: '',
    accountTenantId: '',
    email: '',
    fullName: '',
    countryCode: '',
    phoneNumber: '',
    country: '',
    planId: environment.isEnterpriseApp ? Number(environment.userPlanId) : 0,
  })

  const awsAuth = environment.authenticator === "AWS" ? useAuthenticator(context => [context.authStatus, context.user]) : null;

  const navigate = useNavigate()

   // Azure Authentication Handler
  const handleAzureAuth = () => {
    if (!isAuthenticated) return;

    //updating user account whether account exists or not, coz we use this in both cases.
    setUserAccount({
      ...userAccount,
      accountId: accounts[0].localAccountId,
      accountTenantId: accounts[0].tenantId,
      email: accounts[0].username,
      fullName: accounts[0].name || '',
    });

    if (!accountId) {
      // if(environment.isAdminRestricted) {
      const accessToken = instance.acquireTokenSilent({
        scopes: ['user.read'],
        account: accounts[0],
      })

      accessToken.then((val: any) => {
        callMsGraph(val.accessToken, graphConfig.graphOrgEndPoint).then((res) =>
          setLucidAccountInput({
            ...lucidAccountInput,
            accountName: res.value[0].displayName || '',
            accountTenantId: accounts[0].tenantId,
          }),
        )
      })
      // }

      //As there is no account navigating to accounts page.
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    } 

    //Lucid account exists but no user.
    else if (isAuthenticated && accountId && !lucidUserId) {
      setActiveStep(0)
      setLucidAccountInput({
        ...lucidAccountInput,
        accountTenantId: accounts[0].tenantId,
        accountId: accountId,
      })
    }
  };

  

  // AWS Authentication Handler
  const handleAWSAuth = async () => {
    if (awsAuth && awsAuth.authStatus !== 'authenticated') return;

    try {
      const userAttributes = await fetchUserAttributes();
      setUserAccount({
        ...userAccount,
        accountId: userAttributes.sub || '', 
        accountTenantId: config.aws_user_pools_id,
        email: userAttributes.email || '',
        fullName: userAttributes.name || ''
      });

      if (!accountId) {
        setLucidAccountInput({
          ...lucidAccountInput,
          accountName: '',
          accountTenantId: config.aws_user_pools_id,
        });

        // Move to next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else if (accountId && !lucidUserId) {
        setActiveStep(0);
        setLucidAccountInput({
          ...lucidAccountInput,
          accountTenantId: config.aws_user_pools_id,
          accountId: accountId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchAuthDetails = () => {
  //   if(environment.authenticator === "AWS") {
  //     const { authStatus } = useAuthenticator(context => [context.authStatus]);

  //     return [authStatus]
  //   }

  //   return [isAuthenticated, accountId]
  // }

  // useEffect(() => {
  //   if (environment.authenticator === "Azure") {
  //     handleAzureAuth();
  //   } else if (environment.authenticator === "AWS") {
  //     handleAWSAuth();
  //   }
  // }, [fetchAuthDetails()])

  useEffect(() => {
    if (environment.authenticator === "Azure") {
      handleAzureAuth();
    } else if (environment.authenticator === "AWS") {
      handleAWSAuth();
    }
  }, [isAuthenticated, accountId, awsAuth])

  const handleNext = async () => {
    if (activeStep == steps.length - 1) {
      navigate(ROUTES.DataPods)
      setActiveStep(0)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep == 0) {
      return
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  //If account exists we use user registration.
  //else account registration steps.
  const StepComponent = steps[activeStep]?.component

  const getStepComponent = () => {
    const component = (
      <StepComponent
        onNext={handleNext}
        onBack={handleBack}
        lucidAccount={lucidAccountInput}
        setLucidAccount={setLucidAccountInput}
        userAccount={userAccount}
        setUserAccount={setUserAccount}
        setActiveStep={setActiveStep}
      />
    )

    if (environment.authenticator === 'AWS') {
      if(StepComponent === EmailAddress) {
        return component
      } else {
        <Authenticator hideSignUp>
          {component}
        </Authenticator>
      }
    }

    return component

  }

  return (
    <Box style={{ display: 'flex', height: '100vh' }}>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '30%',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Grid
          item
          container
          sx={{
            xs: 12,
            mt: 12,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img src={Logo} alt="lucidLogo" height={62} width={194} />
        </Grid>
        <Grid
          item
          container
          sx={{
            xs: 12,
            mt: 12,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <StepperComponent steps={steps} activeStep={activeStep} />
        </Grid>
      </Grid>
      <Grid
        sx={{
          width: '70%',
          padding: '5%',
        }}
        container
      >
        {/* {getStepComponent()} */}
        <StepComponent
          onNext={handleNext}
          onBack={handleBack}
          lucidAccount={lucidAccountInput}
          setLucidAccount={setLucidAccountInput}
          userAccount={userAccount}
          setUserAccount={setUserAccount}
          setActiveStep={setActiveStep}
        />
      </Grid>
    </Box>
  )
}

export default SignUpHome
