import { useState, useEffect } from "react";
import { ConvertedFiles } from "businessObjects";
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext";
import { convertedFilesService } from "apiServices/convertedFiles";

export const useGetConvertedFiles = (datapodId?: string) => {
  const { getBearerToken } = useLucidAuthContext();
  const [isFetchingConvertedFiles, setIsFetchingConvertedFiles] = useState<boolean>(true);
  const [errorFetchingConvertedFiles, setErrorFetchingConvertedFiles] = useState<string | null>(null);

  const fetchConvertedFiles = async (serviceConnectionId?: number) => {
    try {
      const token = await getBearerToken();
      if (token && datapodId && serviceConnectionId) {
        const { data, status } = await convertedFilesService.getConvertedFiles(token, datapodId, serviceConnectionId);
        if (status === 200) {
          return data as ConvertedFiles[]
        } else {
          setErrorFetchingConvertedFiles("Failed to fetch converted files.");
        }
      }
    } catch (error) {
      setErrorFetchingConvertedFiles("An error occurred while fetching converted files.");
    } finally {
      setIsFetchingConvertedFiles(false);
    }
  };

  return { fetchConvertedFiles, isFetchingConvertedFiles, errorFetchingConvertedFiles };
};

export const useAddConvertedFiles = (datapodId?: string) => {
  const { getBearerToken } = useLucidAuthContext();
  const [isAddingConvertedFiles, setisAddingConvertedFiles] = useState<boolean>(false);
  const [errorAddingConvertedFiles, setErrorAddingConvertedFiles] = useState<string | null>(null);
  const [successAddingConvertedFiles, setSuccessAddingConvertedFiles] = useState<boolean>(false);

  const addConvertedFiles = async (convertedFiles: ConvertedFiles[]) => {
    setisAddingConvertedFiles(true);
    setErrorAddingConvertedFiles(null);
    setSuccessAddingConvertedFiles(false);

    try {
      const token = await getBearerToken();
      if (token && datapodId && convertedFiles && convertedFiles.length > 0) {
        const { status, data } = await convertedFilesService.addConvertedFiles(token, datapodId, convertedFiles);
        if (status === 200) {
          setSuccessAddingConvertedFiles(true)
          return data
        } else {
          setErrorAddingConvertedFiles("Failed to add converted files.");
        }
      }
    } catch (error) {
      setErrorAddingConvertedFiles("An error occurred while adding converted files.");
    } finally {
      setisAddingConvertedFiles(false);
    }
  };

  return { addConvertedFiles, isAddingConvertedFiles, errorAddingConvertedFiles, successAddingConvertedFiles };
};
