import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  FormControl,
  FormLabel,
  Box,
  Grid,
  Link,
  useTheme,
} from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'

import TextFormInput from 'ui-components/TextForm/TextFormInput'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useGetLucidAccountByTenantId } from 'hooks/lucidAccountHooks'
import { ROUTES } from 'Routes/constants'
import { LucidAccount, UserAccount } from 'businessObjects'
import { LucidTextLabel } from 'ui-components/LucidTextLabel'
import constants from 'pages/constants'
import { environment } from 'utils/environment'

type Props = {
  onNext: () => void
  onBack: () => void
  lucidAccount: LucidAccount
  setLucidAccount: React.Dispatch<React.SetStateAction<LucidAccount>>
  userAccount: UserAccount
  setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>
}

const EmailAddress = ({ onNext, onBack, lucidAccount, setLucidAccount }: Props) => {
  // const { control, handleSubmit } = useForm()

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)

  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { getLucidAccountByTenantId } = useGetLucidAccountByTenantId()

  const navigate = useNavigate()

  const isFormValid = acceptTerms

  const onSubmit = async () => {
    if (environment.authenticator === 'Azure' && !isAuthenticated) {
      try {
        await instance.loginRedirect({
          scopes: environment.isAdminRestricted ? ['user.read', 'openid', 'profile', 'Group.Read.All'] : [],
        })
      } catch {
        //Navigating to login error page
      }
    }

    onNext()
  }

  const onRequestAccess = () => {
    window.location.href = 'https://www.luciddatahub.com/get-started'
  }

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 5,
      }}
      item
    >
      <Grid item >
        <MailOutlinedIcon color="primary" />
      </Grid>
      <Grid item >
        <Typography variant="h2" gutterBottom>
          Authentication
        </Typography>
        <Typography variant="body1" gutterBottom>
          Select an authentictor to get access to Lucid Data Hub.
        </Typography>
      </Grid>

      <form onSubmit={onSubmit} style={{height: '100%', width: '100%'}}>
        <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Grid
            item xs={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // width: '30%',
              gap: 3,
            }}
          >
            <FormControl component="fieldset" margin="normal">
              <FormLabel id="authenticator-radio-group">
                <LucidTextLabel labelText={'Authenticator Provider'} required={true} />
                {/* Authenticator Provider <span style={{ color: 'red' }}>*</span> */}
              </FormLabel>

              <RadioGroup aria-labelledby="authenticator-radio-group" defaultValue="Azure Entra ID" row>
                <FormControlLabel value="Azure Entra ID" control={<Radio />} label="Azure Entra ID" />
                <FormControlLabel value="AWS IAM" control={<Radio />} label="AWS IAM" />
              </RadioGroup>
            </FormControl>

            <FormControlLabel
              label={
                <>
                  <Typography>
                    {/* <small> */}
                      {'I agree to '}
                      <span>
                        {/* https://stackoverflow.com/questions/51937086/how-do-i-link-to-local-pdf-file-in-a-create-react-app-project */}
                        <Link href={require('ui-components/eula.pdf')} target="blank">
                          Terms and Conditions/ EULA
                        </Link>
                      </span>
                    {/* </small> */}
                  </Typography>

                  {/* <Button onClick={() => window.open('eula.pdf', '_blank')}>Terms and Conditions</Button> */}
                </>
              }
              control={
                <Checkbox
                  checked={acceptTerms}
                  onChange={() => setAcceptTerms(!acceptTerms)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
          </Grid>

          <Grid item sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
            {/* <Button onClick={onBack} variant="outlined">
              Back
            </Button> */}
            <Button type="submit" disabled={!isFormValid} variant="contained" color="primary" sx={{ ml: 2 }}>
              Sign in
            </Button>
            <Button onClick={onRequestAccess} variant="contained" color="primary" sx={{ ml: 2 }}>
              Request Access
            </Button>
          </Grid>
        </Grid>
      </form> 
    </Grid>
  )
}

export default EmailAddress
