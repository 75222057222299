import { sendLocalLLmRequests } from 'requestManager/localLLmRequests'
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'
import { ConversionMetricsRequest, GenericConversionMetricsRequest } from 'businessObjects'

type DataProfilingBody = {
  dataPodId: string
  dataSystemId: number
  dataTableId?: number
}

type CanonicalBody = {
  dataPodId: string
  dataEntityId: number
}

const dataPodPaths = {
  generatemappings: 'GenerateMappings',
  DataProfiling: 'DataProfiling',
  DataTableScan: 'DataTableScan',
  LoadCanonical: 'LoadCanonical',
  MappingByColumn:'GenerateMappingsByColumn',
  extractData: 'extractEntityInfo',
  embedToModel: 'embedToModel',
  convertToPyspark:'convertContentToPySpark',
  comparisionMetrics:'compareConversion',
  genericComparisionMetrics:'genericCodeComparison'
}

const generateMappings = async (dataPodId: string, dataSystemID: number, dataTableID: number, token?: string) => {
  const cmBody = {
    dataPodId: dataPodId,
    dataSystemId: dataSystemID,
    dataTableId: dataTableID,
  }
  return await sendGenApiRequest(dataPodPaths.generatemappings, 'POST', token ?? 'Lucid123', cmBody)
}

//DataProfiling

const getNotebookStringBySystemId = async (body: DataProfilingBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.DataProfiling, 'POST', token ?? 'Lucid123', body)
}

const getNotebookStringBySystemIdAndTableId = async (body: DataProfilingBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.DataTableScan, 'POST', token ?? 'Lucid123', body)
}

const loadCanonicalNotebookStringByEntityId = async (body: CanonicalBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.LoadCanonical, 'POST', token ?? 'Lucid123', body)
}
const generateMappingByColumn = async (body: DataProfilingBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.MappingByColumn, 'POST', token ?? 'Lucid123', body)
}
const generateCustomEnrichedData = async(file:File, datapodId:string) =>{
  const formData = new FormData();
  formData.append('file', file);       
  const reqUrl = dataPodPaths.extractData + `?datapodId=${datapodId}`
  return await sendLocalLLmRequests(reqUrl,'POST',formData)
}
const generateEmbeddings = async(datapodId:string) =>{
  const reqUrl = dataPodPaths.embedToModel + `?datapodId=${datapodId}`
  return await sendLocalLLmRequests(reqUrl,'POST')
}
const getPysparkCode= async(file:File,conversionTargetType:string, userEnteredPrompt:string) =>{
  const formData = new FormData();
  formData.append('file', file);
  const content = await file.text() 
  formData.append('content', content)
  formData.append('conversionTargetType', conversionTargetType)
  formData.append('userEnteredPrompt', userEnteredPrompt)
  const reqUrl = dataPodPaths.convertToPyspark
  return await sendLocalLLmRequests(reqUrl,'POST',formData)
}
const getConversionCodeMetrics = async(body:ConversionMetricsRequest) =>{
  const reqUrl = dataPodPaths.comparisionMetrics
  return await sendLocalLLmRequests(reqUrl,'POST',body)
}
const getGenericConversionCodeMetrics = async(body:GenericConversionMetricsRequest) =>{
  const reqUrl = dataPodPaths.genericComparisionMetrics
  return await sendLocalLLmRequests(reqUrl,'POST',body)
}
export const genServices = {
  generateMappings,
  getNotebookStringBySystemId,
  getNotebookStringBySystemIdAndTableId,
  loadCanonicalNotebookStringByEntityId,
  generateMappingByColumn,
  generateCustomEnrichedData,
  getPysparkCode,
  getConversionCodeMetrics,
  getGenericConversionCodeMetrics,
  generateEmbeddings
}
