import { AddOutlined } from '@mui/icons-material'
import { TextField, InputAdornment, IconButton, Button, Typography, Pagination, Box, Card, CardContent, CardHeader, CardMedia, Tooltip, Container } from '@mui/material'
import Grid from '@mui/material/Grid'
import { DataPod, getDataHubResponse, PaginationParams } from 'businessObjects'
import { useGetDataPods } from 'hooks/datapodHooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, generatePath, Link } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import ActionPage from 'ui-components/ActionPage'
import AnimatedLoader from 'ui-components/AnimatedLoader'
import DataTable from 'ui-components/DataTable/DataTable'
import { DataItemAction } from 'ui-components/DataTable/DataTableTypes'
import { DataTableRow, DataTableColumn } from 'ui-components/ExtendedShadowTable'
import NoEntries from 'ui-components/NoEntries'
import ToggleView, { ViewType } from 'ui-components/ToggleView/ToggleView'
import { setSessionPodId } from 'utils/tokens'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useGetSubjectAreas } from 'hooks/subjectAreaHooks'
import DatapodThumbnail from '../../ui-icons/DatapodThumbnail.svg'
import { useGetAllDataHubs } from 'hooks/dataHubHooks'

export const DataHubHomePage = () => {
    const [dataPods, setDataPods] = useState<DataPod[]>([])
  const [dataPodRows, setDataPodRows] = useState<DataTableRow[]>([])
  const [searchDataPod, setSearchDataPod] = useState<string>()
  const [pages, setPages] = useState<number>(0)
  const [cardView, setCardView] = useState<ViewType>('Grid')
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    recordsPerPage: cardView === 'Grid' ? 3 : 6,
    pageNumber: 1,
  })
  const [fetchDataHubs, dataHubs] = useGetAllDataHubs(
    { updatePageCount: setPages },
    searchDataPod,
    !searchDataPod ? paginationParams.recordsPerPage : 100,
    !searchDataPod ? paginationParams.pageNumber : 1,
  )
  const navigate = useNavigate()
  const handleAddDatahub = () => {
    navigate(ROUTES.AddDataHub)
  }
  useEffect(()=>{
    if(!dataHubs?.length){
      fetchDataHubs()
    }
    if(dataHubs?.length){
      const tRows = dataHubs.map((d) => ({
        id: d.id,
        values: [d.id, d.dataHubName, d.dataHubDesc],
      })) as DataTableRow[]
      setDataPodRows(tRows)
    }
  },[dataHubs])

  useEffect(() => {
    fetchDataHubs()
  }, [paginationParams])

  // useEffect(() => {
  //   if (allDatapods?.length) {
  //     // console.log({ dataPods })
  //     setDataPods(allDatapods)
  //     // const tRows = allDatapods.map((d) => ({
  //     //   id: d.id,
  //     //   values: [d.id, d.name, d.description],
  //     // })) as DataTableRow[]
  //     // setDataPodRows(tRows)
  //   }
  //   // if (searchDataPod ) {
  //   //   setDataPodRows([])
  //   //   setDataPods([])
  //   // }
  // }, [allDatapods, paginationParams.pageNumber, searchDataPod])

  const onPageChange = (selectedPage: number) => {
    setPaginationParams((prev) => ({
      ...prev,
      pageNumber: selectedPage,
    }))
  }

  // const handleTableView = () => {
  //   setCardView(false)
  //   setDataPods([])
  //   setPaginationParams({ pageNumber: 1, recordsPerPage: 6 })
  //   setTableView(true)
  // }

  // const handleCardView = () => {
  //   setCardView(true)
  //   setDataPods([])
  //   setDataPodRows([])
  //   setTableView(false)
  //   setPaginationParams({ pageNumber: 1, recordsPerPage: 3 })
  // }

  const onDataPodSelect = (podId: string) => {
    const overViewPage = generatePath(ROUTES.ViewDataHub, { dataHubId: podId })
    navigate(overViewPage)
  }
  const dataPodColumns: DataTableColumn[] = useMemo(() => {
    return [
      {
        label: 'Data Pod Id',
      },
      {
        label: 'Data Pod Name',
      },
      {
        label: 'Description',
      },
    ]
  }, [])

  const clickTableButton = (id: string, action: DataItemAction): void => {
    if (action === 'view') {
      onDataPodSelect(id)
      //navigate(generatePath(ROUTES.DataSystemProfiling, { dataPodId: id }))
    }
  }

  // const showTableView = () => {
  //   setCardView(false)
  //   getPods(searchDataPod)
  //   setPaginationParams({ pageNumber: 1, recordsPerPage: 6 })
  // }

  // const showCardView = () => {
  //   setCardView(true)
  //   getPods(searchDataPod)
  //   setPaginationParams({ pageNumber: 1, recordsPerPage: 3 })
  // }

  const handleView = (vt: ViewType) => {
    if (vt !== cardView) {
      const pageParms = vt === 'Grid' ? { pageNumber: 1, recordsPerPage: 3 } : { pageNumber: 1, recordsPerPage: 6 }
      setCardView(vt)
      // searchDataPod && getPods(searchDataPod)
      setPaginationParams(pageParms)
    }
  }


  return (
    <ActionPage>
      {/* <ProgressBar loading={loading} /> */}

      <Grid item container alignItems="flex-end" spacing={1}>
        <Grid item xs={7} />
        {/* <TextFormInput value={searchDataPod ?? ''} placeholderText="search" changeHandle={(podName) => setSearchDataPod(podName)} columnSize={3}  searchType /> */}
        {/* <Grid item xs={2}>
          <TextField
            placeholder="Search"
            value={searchDataPod ?? ''}
            onChange={({ target }) => setSearchDataPod(target.value)}
            color="primary"
            size="small"
            type="search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: searchDataPod ? (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearchDataPod('')}>
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
          />
        </Grid> */}
        <Grid item xs={2}/>
        <Grid item xs={3} container alignItems="center" alignContent="flex-end" spacing={0.5}>
          <ToggleView viewType={cardView} handleToggle={handleView} />
          {/* <IconButton color={!cardView ? 'primary' : 'default'} onClick={showTableView}>
              <TableRowsOutlinedIcon />
            </IconButton>
            <IconButton color={cardView ? 'primary' : 'default'} onClick={showCardView}>
              <GridViewOutlinedIcon />
            </IconButton> */}
          <Grid item xs>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<AddOutlined />}
              onClick={handleAddDatahub}
            >
              Add data hub
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* {!allDatapods?.length && searchDataPod ? (
        <Grid item>
          <Typography align="center">No Matching Entry</Typography>
        </Grid>
      ) : (
        <></>
      )} */}
      {/* <AnimatedLoader height="50%" width="40%" loading={loading} /> */}
      {dataHubs?.length ? (
        <>
          {cardView === 'Grid' ? (
            <DatapodCardDetails
              datapods={dataHubs}
              totalPages={pages}
              selectPage={onPageChange}
              onSelectPod={onDataPodSelect}
            />
          ) : (
            <>
              <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
                <Grid item container xs={12} />
                <Grid item xs={12}>
                  <DataTable
                    columns={dataPodColumns}
                    rows={dataPodRows}
                    allowedActions={{ selectable: true }}
                    filterColumn={1}
                    //filterString={searchDataPod}
                    onButtonClicked={clickTableButton}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={12} />
                <Grid item xs={3} />
                <Grid item xs={5} />
                <Grid item xs={4}>
                  <Pagination
                    count={pages}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    onChange={(_, page) => setPaginationParams((prev) => ({ ...prev, pageNumber: page }))}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </ActionPage>
  )
}
type DataPodCardProp = {
    dataPod: getDataHubResponse
    onClick: (id: string) => void
    onClickPodSelect: (id: string) => void
  }
  const DataPodCard = ({ dataPod, onClick, onClickPodSelect }: DataPodCardProp) => {
    const navigate = useNavigate()
    // const [getSubjAreas, subjAreas] = useGetSubjectAreas({})
    // useEffect(() => {
    //   if (dataPod.id) {
    //     getSubjAreas(dataPod.id)
    //   }
    // }, [dataPod.id])
  
    // const dataPodSubjArea = useMemo(() => {
    //   if (subjAreas?.length) {
    //     return subjAreas
    //       .map((s) => s.subjectAreaName)
    //       .slice(0, 40)
    //       .join(', ')
    //   }
    //   return ''
    // }, [subjAreas])
  
    return (
      <>
        <Card sx={{ width: 380, height: 380, borderRadius: 3 }}>
          <CardHeader
            title={
              <Grid container alignContent={'center'} alignItems={'baseline'} spacing={4}>
                <Grid item xs={8}>
                  {dataPod.dataHubName.length > 25 ? (
                    <Tooltip title={dataPod.dataHubName} placement="top-start">
                      <Typography variant="h4">{dataPod.dataHubName.slice(0, 20) + '...'}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="h4">{dataPod.dataHubName}</Typography>
                  )}
                </Grid>
                {/* <Grid item xs={4}>
                  <Link to={ROUTES.ViewDataHub + `?dataHubId=${dataPod.id}`}>
                    <Typography variant="body1">View Details</Typography>
                  </Link>
                </Grid> */}
              </Grid>
            }
          />
          <CardContent>
            <CardMedia component="img" sx={{ height: 98, borderRadius: '6px' }} image={DatapodThumbnail}></CardMedia>
            <Box sx={{ mt: 2, height: 130 }}>
              <Grid container spacing={0.5}>
                <Grid item xs={16} container alignContent={'center'} alignItems={'baseline'}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Data Hub Id:</Typography>
                  </Grid>
  
                  <Grid item xs={8}>
                    <Typography variant="body2">{dataPod.id}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={16} container alignContent={'center'} alignItems={'baseline'}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Data Hub Name:</Typography>
                  </Grid>
  
                  <Grid item xs={8}>
                    <Typography variant="body2">{dataPod.dataHubName}</Typography>
                  </Grid>
                </Grid>
                {/* <Grid item xs={16} container alignContent={'center'} alignItems={'baseline'}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Industry Name:</Typography>
                  </Grid>
  
                  <Grid item xs={8}>
                    <Typography variant="body2">{dataPod.industryName}</Typography>
                  </Grid>
                </Grid> */}
                {/* <Grid item xs={16} container alignContent={'center'} alignItems={'baseline'}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Data Domain</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {dataPodSubjArea.length > 80 ? (
                      <Tooltip title={<Typography variant="body2">{dataPodSubjArea}</Typography>} placement="bottom">
                        <Typography variant="body2">{dataPodSubjArea.slice(0, 30) + '...'}</Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body2">{dataPodSubjArea}</Typography>
                    )}
                  </Grid>
                </Grid> */}
              </Grid>
            </Box>
          </CardContent>
          <Grid item container spacing={2}>
            <Grid item xs={0.5} />
            <Grid item xs={4}>
              <Button variant="contained" onClick={() => navigate(generatePath(ROUTES.ViewDataHub, { dataHubId:dataPod.id })) }>
                {/* <Link to={ROUTES.ViewDataHub + `?dataHubId=${dataPod.id}`}> */}
                    <Typography variant="body1" color={'white'}>Select </Typography>
                  {/* </Link> */}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </>
    )
  }
  type DataHubCardProps = {
    datapods: getDataHubResponse[]
    totalPages: number
    selectPage: (page: number) => void
    onSelectPod: (podId: string) => void
  }

const DatapodCardDetails = ({ datapods, totalPages, selectPage, onSelectPod }: DataHubCardProps) => {
    const navigate = useNavigate()
  
    const viewDataPodSummary = (dataPodId: string) => {
      navigate(ROUTES.AddDataPod + `?dataPodId=${dataPodId}`)
    }
  
    return (
      <>
        <Grid item xs={15} md={15} container spacing={4}>
          <Grid item xs={8} />
          <Grid item xs={4} container spacing={5}></Grid>
        </Grid>
  
        <Container maxWidth="xl">
          <Grid item container xs={12} md={23} spacing={12}>
            <Grid item container spacing={6} />
            <Grid item container spacing={2}>
              {datapods.map((d) => (
                <Grid item key={d.id}>
                  <DataPodCard dataPod={d} onClick={viewDataPodSummary} onClickPodSelect={onSelectPod} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
        <Grid item container sx={{ paddingTop: 35, paddingLeft: -1 }} spacing={8}>
          <Grid item xs={8.5} />
          <Grid item xs={3.5}>
            <Pagination
              count={totalPages !== 0 ? totalPages : 1}
              color="primary"
              variant="outlined"
              hidePrevButton={datapods.length <= 3}
              hideNextButton={datapods.length <= 3}
              onChange={(_, page) => selectPage(page)}
            />
          </Grid>
        </Grid>
      </>
    )
  }