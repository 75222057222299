import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Box, Checkbox, Collapse, Grid, IconButton, InputAdornment, Menu, ListItemText, MenuItem, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Child } from 'businessObjects'
import TuneIcon from '@mui/icons-material/Tune'

interface SearchDropDownProps {
  parentList: { id: number; name: string }[]
  filteredChildren: Child[]
  loading: boolean
  error: String | null
  setSelectionInput: React.Dispatch<React.SetStateAction<string>>
  selectionInput: string
  selectedChildren: { [parentId: number]: number[] }
  setSelectedChildren?: React.Dispatch<React.SetStateAction<{ [parentId: number]: number[] }>>
  selectedParents: number[]
  setSelectedParents: React.Dispatch<React.SetStateAction<number[]>>
  filterIcon?: boolean
}

interface Parent {
  id: number;
  name: string;
}

interface ChildrenMap {
  [parentId: number]: Child[]
}

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

// If your requirement is not to use children, pass empty array to filteredChildren. 
const SearchDropDown: React.FC<SearchDropDownProps> = ({
  parentList,
  filteredChildren,
  loading,
  error,
  setSelectionInput,
  selectionInput,
  selectedChildren,
  setSelectedChildren,
  selectedParents,
  setSelectedParents,
  filterIcon
}) => {
  const [searchText, setSearchText] = useState('')
  const [suggestedChildren, setSuggestedChildren] = useState<ChildrenMap>({})
  const [expandedParent, setExpandedParent] = useState<number | null>(null)
  const [showOptions, setShowOptions] = useState(true)
  const debouncedSearchText = useDebounce(searchText, 500)
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null)
  const [filterOption, setFilterOption] = useState('')
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [measureName, setMeasureName] = useState('');

  const filteredParents = useMemo(() => {
    if (!debouncedSearchText) {
      return parentList
    }

    return parentList.filter((parent) =>
      parent.name.toLowerCase().includes(debouncedSearchText.toLowerCase()),
    )
  }, [parentList, debouncedSearchText])

  const filteredParentsIconGroup = useMemo(() => {
    if (filterOption === 'measures' && measureName) {
      // Filter parents based on whether they have a child with the provided measure name
      return parentList.filter((parent) => {
        const children = suggestedChildren[parent.id] || [];
        return children.some((child) =>
          child.childName.toLowerCase().includes(measureName.toLowerCase())
        );
      });
    }

    // Default filtering by group names if not filtering by measures
    return parentList.filter((parent) =>
      parent.name.toLowerCase().includes(debouncedSearchText.toLowerCase())
    );
  }, [parentList, debouncedSearchText, filterOption, measureName, suggestedChildren]);


  const toggleParentExpand = (parentId: number) => {
    setExpandedParent((prevParentId) => (prevParentId === parentId ? null : parentId))
  }

  const handleFocus = () => {
    setShowOptions(true)
  }

  const handleChildSelect = useCallback(
    (parent: Parent, child: Child) => {
      const { id: parentId, name: parentName } = parent
      const { childId, childName } = child
      setSelectedChildren && setSelectedChildren((prevChildren) => {
        const parentChildren = prevChildren[parentId] || []
        const isSelected = parentChildren.includes(childId)
        const newParentChildren = isSelected
          ? parentChildren.filter((c) => c !== childId)
          : [...parentChildren, childId]
        return {
          ...prevChildren,
          [parentId]: newParentChildren,
        }
      })
      const selectedText = `@${parentName}.${childName}`
      setSelectionInput((prev) => {
        const selections = prev.split(' ').filter(Boolean);
        const isSelected = selections.includes(selectedText);

        if (isSelected) {
          return selections.filter(s => s !== selectedText).join(' ');
        } else {
          return [...selections, selectedText].join(' ');
        }
      });
    },
    [setSelectedChildren, setSelectionInput],
  )


  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget)
  }

  const handleFilterChange = (filterValue: string) => {
    setFilterOption(filterValue)
    setFilterAnchorEl(null)
  }
  const handleCloseFilterMenu = () => {
    setFilterAnchorEl(null)
  }

  useEffect(() => {
    const newSelectedChildren: { [parentId: number]: number[] } = {};
    const selections = selectionInput.split(' ').filter(Boolean);

    parentList.forEach(parent => {
      const parentChildren = selections
        .filter(selection => selection.startsWith(`@${parent.name}.`))
        .map(selection => {
          const childName = selection.split('.')[1];
          const child = suggestedChildren[parent.id]?.find(c => c.childName === childName);
          return child?.childId;
        })
        .filter((id): id is number => id !== undefined);

      if (parentChildren.length > 0) {
        newSelectedChildren[parent.id] = parentChildren;
      }
    });

    setSelectedChildren && setSelectedChildren(newSelectedChildren);
  }, [selectionInput, parentList, suggestedChildren]);

  useEffect(() => {
    if (filteredChildren.length > 0) {
      const groupedChildren: ChildrenMap = {}

      filteredChildren.forEach((child: Child) => {
        const parentId = child.parentId
        if (!parentId) return

        if (!groupedChildren[parentId]) {
          groupedChildren[parentId] = []
        }

        groupedChildren[parentId].push(child)
      })

      setSuggestedChildren(groupedChildren)
    }
  }, [filteredChildren])

  const childrenList = Object.keys(suggestedChildren).length

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, parent: Parent) => {
    const isChecked = event.target.checked;

    setSelectedParents(prevSelected => {
      const newSelected = isChecked
        ? [...prevSelected, parent.id]
        : prevSelected.filter(id => id !== parent.id);

      setSelectionInput(prev => {
        const selections = prev.split(' ').filter(Boolean);
        if (isChecked) {
          if (prev.endsWith('@'))
            return prev.concat(`${parent.name} `)
          else
            return prev.concat(`@${parent.name} `)
        } else {
          const filteredString = selections.filter(s => s !== `@${parent.name}`).join(' ');

          if (filteredString.endsWith('@')) {
            const temp = filteredString.slice(0, filteredString.length - 1);
            return temp.length == 0 ? temp : temp.concat(' ')
          }

          return filteredString
        }
      });

      return newSelected;
    });
  };

  const handleSearchInputChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    setSearchText(value);

    if (filterOption === 'measures') {
      setMeasureName(value);
    }
  };

  return (
    <Grid style={{ width: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {/* Container with relative positioning */}
      <div style={{
        position: 'relative',
        height: '400px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        {/* Fixed search bar container */}
        <div style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 2,
          padding: '8px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }}>
          <Box display="flex" gap={2} alignItems="center">
            <TextField
              value={filterOption === 'measures' ? measureName : searchText}
              onChange={handleSearchInputChange}
              onFocus={handleFocus}
              placeholder={filterOption === 'measures' ? "Search Measures ..." : "Search Groups..."}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'white' }}
            />
            {filterIcon &&
              <IconButton onClick={handleFilterIconClick}>
                <TuneIcon />
              </IconButton>
            }
          </Box>
        </div>

        <div 
        ref={containerRef} 
        style={{
          height: 'calc(100% - 64px)', // Adjust based on search bar height
          overflowY: 'auto',
          position: 'relative'
        }} >
          {showOptions &&
            <MenuItem style={{ display: 'block' }}>
              {loading ? (
                <MenuItem>Loading...</MenuItem>
              ) : error ? (
                <MenuItem>{error}</MenuItem>
              ) : filteredParentsIconGroup.length === 0 ? (
                <MenuItem>No results found</MenuItem>
              ) : (
                filteredParentsIconGroup.map((parent) => (
                  <MenuItem key={parent.id} value={parent.id} style={{ display: 'block' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {childrenList === 0 && <Checkbox
                        onChange={(event) => handleChange(event, parent)}
                        checked={selectedParents.includes(parent.id)}
                      />}
                      <ListItemText
                        primary={parent.name}
                        onClick={() => toggleParentExpand(parent.id)}
                        style={{ cursor: 'pointer' }}
                      />
                      {childrenList > 0 && (
                        <IconButton onClick={() => toggleParentExpand(parent.id)}>
                          {expandedParent === parent.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      )}
                    </div>

                    {childrenList > 0 && expandedParent === parent.id && (
                      <Collapse in={expandedParent === parent.id}>
                        {!(suggestedChildren[expandedParent]?.length > 0) ? (
                          <MenuItem>No Children Found</MenuItem>
                        ) : (
                          suggestedChildren[expandedParent]?.map((child) => (
                            <MenuItem
                              key={child.childId}
                              style={{ paddingLeft: '24px' }}
                              onClick={() => handleChildSelect(parent, child)}
                            >
                              <Checkbox
                                checked={(selectedChildren[parent.id] || []).includes(child.childId)}
                              />
                              <ListItemText primary={child.childName} />
                            </MenuItem>
                          ))
                        )}
                      </Collapse>
                    )}
                  </MenuItem>
                ))
              )}
            </MenuItem>
          }
        </div>
        <Menu
          anchorEl={filterAnchorEl}
          open={Boolean(filterAnchorEl)}
          onClose={handleCloseFilterMenu}
          sx={{ width: '50%' }}
        >
          <MenuItem onClick={() => handleFilterChange('groups')}>Groups</MenuItem>
          <MenuItem onClick={() => handleFilterChange('measures')}>Measures</MenuItem>
        </Menu>
      </div>
    </Grid>
  )
}

export default SearchDropDown
