import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import { DataContextDatapodDetails } from './DataContextDatapodDetails'
import { CardContent, Grid, Typography } from '@mui/material'
import { DataContextSubjectArea } from './DataContextSubjectArea'
import { DataContextDataSystem } from './DataContextDataSystem'
import { DataContextSummary } from './DataContextSummary'
import { DataContextService } from './DataContextService'
import { useEffect, useState } from 'react'
import { useCreateDataPod, useGetAllDataPods, useUpdateDataPod, useCreateDataSource } from '../../hooks/datapodHooks'
import { DataPodInfo, DataSystem, Industry, SubjectArea } from '../../businessObjects'
import { useLucidAuthContext } from '../../LucidAuthContext/LucidAuthContext'
import ResultStatus from '../../ui-components/ResultStatus'
import ActionPage from '../../ui-components/ActionPage'
import { useGetIndustries } from '../../hooks/industryHooks'
import { useDeleteSubjectAreaByID, useGetSubjectAreas } from '../../hooks/subjectAreaHooks'
import { useDeleteDataSystemById, useGetAllDataSystems } from '../../hooks/dataSystemHooks'
import { userGenerateSubjectAreas } from '../../hooks/genAIApiHooks'
import { generatePath, useNavigate } from 'react-router-dom'
import { useGetQueryString } from '../../hooks/queryStringHook'
import {
  ServiceConnectionType,
  useGetServiceConnectionsByDataPod,
  usePostServiceConnection,
} from '../../hooks/serviceConnectionHooks'
import { ROUTES } from '../../Routes/constants'
import { isValidEntry, isValidJson } from '../../utils/constants'
import DataPodDetailRibbon from '../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { DataSource } from './DataSource'
import { DeployDataLake } from './DeployDataLake'
import { usePostDataLake } from '../../hooks/dataEngineeringHooks'
import constants, { DataPodCreation } from 'pages/constants'
import { dataPodInit, validateDataPodValues } from './DataContextUtils'
import { useAssignRole } from 'hooks/rolesHooks'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import { setSessionPodId } from 'utils/tokens'

// type DataSourcePayload = { [key: string]: any }

export const DataPodContext = () => {
  const dataPodIdQueryString = useGetQueryString('dataPodId')
  const [activeStep, setActiveStep] = useState(0)
  const { accountId, lucidUserId } = useLucidAuthContext()
  const navigate = useNavigate()

  // datapods
  const [datapodInp, setDatapodInp] = useState<DataPodInfo>(dataPodInit)
  const [currentDataPodId, setCurrentDataPodId] = useState<string>('')
  const [dataPodSaveError, setDataPodSaveError] = useState<boolean>()

  // data source location details
  // const [createDataSource] = useCreateDataSource(currentDataPodId)
  // const [dataSource, setDataSource] = useState<DataSourcePayload>()

  const [industries, setIndustries] = useState<Industry[]>([])
  const [selIndustry, setSelIndustry] = useState<Industry>()

  const [subjectAreaDetails, setSubjectAreaDetails] = useState<SubjectArea[]>([])

  const [dataSystems, setDataSystems] = useState<DataSystem[]>([])

  const [getDataPod, dataPodInfo] = useGetAllDataPods({})

  const [getIndustries, industriesList] = useGetIndustries()
  const [submitDataPod, newDataPod] = useCreateDataPod()
  const [updatedDataPod] = useUpdateDataPod()

  const { assignRole } = useAssignRole()

  // step 1 --> Data Domain
  // Note All subject Area labels in the UI are changed to Data Domain
  const [getSubjectAreas, subjAreas] = useGetSubjectAreas({})
  const { deleteSubjAreaID } = useDeleteSubjectAreaByID()

  // step 2 - DataService
  const { connections, getServiceConnections: refreshConnections } = useGetServiceConnectionsByDataPod(currentDataPodId)
  // const [serviceInfo, setServiceInfo] = useState<ServiceConnectionType>()
  // const { postComplete, addServiceConnection, updateServiceConnection, postError } =
  //   usePostServiceConnection(currentDataPodId)

  //Deploy Data Lake step
  const { addDataLakes, postDataLakeStatus } = usePostDataLake(currentDataPodId || '')

  // step 3 --> DataSystems
  const [getDataSystems, allDataSystems] = useGetAllDataSystems({})
  const { softDeleteDataSystem } = useDeleteDataSystemById()

  // call addDatapodApi within the async to fetch datapod id
  const saveDataPod = async (): Promise<void> => {
    if (accountId && lucidUserId) {
      if (!currentDataPodId) {
        const dataPodSaveParams = {
          ...datapodInp,
          accountId: accountId,
          dataPodCode: '121',
          dataLandingStorageAccount: '121',
          dataLandingStorageAccountKey: '121',
          userId: lucidUserId,
          roleId: constants.defaultRoleId,
        }
        if (validateDataPodValues(dataPodSaveParams)) {
          await submitDataPod(dataPodSaveParams)
          const selInd = industries.find((ind) => ind.id === datapodInp.industryId)
          selInd && setSelIndustry(selInd)
        }
      } else {
        saveUpdatedDataPod()
      }
    }
  }

  const saveUpdatedDataPod = async (): Promise<void> => {
    if (accountId) {
      const updatedPod: DataPodInfo = {
        ...datapodInp,
        accountId: accountId,
      }
      if (validateDataPodValues(updatedPod)) {
        await updatedDataPod(currentDataPodId, updatedPod)
        const selInd = industries.find((ind) => ind.id === datapodInp.industryId)
        selInd && setSelIndustry(selInd)
        navigate(ROUTES.AddDataPod + `?dataPodId=${currentDataPodId}`)
      }
    }
  }

  const refetchSubjectAreas = async () => {
    await getSubjectAreas(currentDataPodId)
  }

  const refetchDataSystems = async () => {
    await getDataSystems(currentDataPodId)
  }

  // const [skipped, setSkipped] = useState(new Set<number>())

  useEffect(() => {
    if (dataPodIdQueryString) {
      setCurrentDataPodId(dataPodIdQueryString)
      getDataPod(dataPodIdQueryString)
      getSubjectAreas(dataPodIdQueryString)
      getDataSystems(dataPodIdQueryString)
      setActiveStep(4)
    }
  }, [dataPodIdQueryString])

  useEffect(() => {
    if (allDataSystems) {
      setDataSystems(allDataSystems)
    }
    if (subjAreas) {
      setSubjectAreaDetails(subjAreas)
    }
  }, [allDataSystems, subjAreas])

  useEffect(() => {
    if (!industries.length) {
      getIndustries()
    }
  }, [])

  useEffect(() => {
    if (industriesList?.length) {
      setIndustries(industriesList)
    }
  }, [industriesList])

  // const isStepOptional = (step: number) => {
  //   return step === 1
  // }

  useEffect(() => {
    if (newDataPod) {
      setCurrentDataPodId(newDataPod.id)
      // fetchSubjectAreas(newDataPod.id)
      // getSubjectAreas(newDataPod.id)
      // getDataSystems(newDataPod.id)
      const industrySelected = industries.find((ind) => ind.id === newDataPod.industryId)
      setSelIndustry(industrySelected)
    }
  }, [newDataPod])

  useEffect(() => {
    if (!selIndustry && dataPodInfo?.length) {
      setDatapodInp(dataPodInfo[0])
      const industryId = dataPodInfo[0].industryId
      const industryName = dataPodInfo[0].industryName
      if (industryId && industryName) {
        const industryData = {
          id: industryId,
          industryName: industryName,
        }
        setSelIndustry(industryData)
      }
    }
  }, [dataPodInfo])

  useEffect(() => {
    if (subjAreas?.length) {
      setSubjectAreaDetails(subjAreas)
    }

    // if (postComplete?.dataPodId) {
    //   setServiceInfo({ action: 'list' } as any)
    // }

    // //if any error during service creation
    // if (!postComplete && postError) {
    //   setServiceInfo({ action: 'error', errorMsg: postError?.message } as any)
    // }
  }, [subjAreas])

  // const isStepSkipped = (step: number) => {
  //   return skipped.has(step)
  // }

  const navigateToOveriew = () => {
    setSessionPodId(currentDataPodId)
    const overviewRoute = generatePath(ROUTES.Overview, { dataPodId: currentDataPodId })
    navigate(overviewRoute)
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      await saveDataPod()
    }
    // if (activeStep === 1 && dataSource) {
    //   //call to data source api
    //   await createDataSource(dataSource).then(() => {
    //     getSubjectAreas(currentDataPodId)
    //     getDataSystems(currentDataPodId)
    //     //clearing data
    //     setDataSource({})
    //   })
    // }
    // if (activeStep === 2 && dataSource) {
    //   await addServiceConnection(dataSource.serviceConnections[0])
    //   getSubjectAreas(currentDataPodId)
    //   getDataSystems(currentDataPodId)
    //   setDataSource({})
    //   // await createDataSource(dataSource).then((res: any) => {
    //   //   getSubjectAreas(currentDataPodId)
    //   //   getDataSystems(currentDataPodId)
    //   //   // Do a POST call also to create a service connection with DL
    //   //   if (res && res?.dataSystems && res?.serviceConnections) {
    //   //     addDataLakes({
    //   //       entityids: dataSource?.entityids || res?.dataSystems?.map((a: any) => a?.id),
    //   //       serviceConnectionId: res?.serviceConnections[0]?.id,
    //   //       dataLakeZoneId: dataSource?.dataLakeZoneId,
    //   //     })
    //   //   }
    //   //   //clearing data
    //   //   setDataSource({})
    //   // })
    //   setActiveStep((prevActiveStep) => prevActiveStep + 3)
    // }

    // if (activeStep === 2 && (serviceInfo?.action === 'new' || serviceInfo?.action === 'edit')) {
    //   if (serviceInfo?.id) {
    //     await updateServiceConnection(serviceInfo)
    //   } else {
    //     await addServiceConnection(serviceInfo)
    //   }
    //   setActiveStep(activeStep)
    //   setServiceInfo({ ...serviceInfo, action: 'list' })
    //   return
    // }

    if (activeStep === 4) {
      return navigate(ROUTES.DataPods)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (dataPodIdQueryString) {
      setActiveStep(4)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    } 
  }

  const buttonDisabled = React.useMemo(() => {
    switch (activeStep) {
      case 0:
        return !accountId || !validateDataPodValues({ ...datapodInp, accountId: accountId })
      // case 1:
      //   return !dataSource
      // case 2:
      //   if (serviceInfo && Object.keys(serviceInfo).length) {
      //     return serviceInfo?.action === 'error' || !isValidJson(serviceInfo?.serviceConnectionJsonTemplate)
      //   } else {
      //     return false
      //   }
      case 1:
        return false
      case 2:
      // if (serviceInfo && Object.keys(serviceInfo).length) {
      //   console.log({ serviceInfo })
      //   return serviceInfo?.action === 'error' || !isValidJson(serviceInfo?.serviceConnectionJsonTemplate)
      // } else {
      //   return false
      // }
      default:
        false
    }
    return false
  }, [activeStep, selIndustry, datapodInp])

  const handleReset = () => {
    setActiveStep(0)
  }

  const deleteSubjArea = async (delSubjId: number) => {
    const isSubDeleted = await deleteSubjAreaID(delSubjId)
    if (isSubDeleted) {
      await refetchSubjectAreas()
      return isSubDeleted
    }
    return false
  }

  const deleteDataSystem = async (deleteSystemId: number) => {
    const isDataSystemDeleted = await softDeleteDataSystem(deleteSystemId)
    if (isDataSystemDeleted) {
      await refetchDataSystems()
      return isDataSystemDeleted
    }
    return false
  }

  const handleService = (arg: ServiceConnectionType) => {
    // try {
    //   setServiceInfo(arg)
    //   if (arg.serviceConnectionJsonTemplate) {
    //     JSON.parse(arg.serviceConnectionJsonTemplate)
    //   }
    // } catch (e) {
    //   console.log({ e }, { arg })
    //   setServiceInfo({ action: 'error', errorMsg: 'Invalid Json Service Configuration !' } as any)
    // }
  }

  const renderStep = () => {
    let activeComponent
    switch (activeStep) {
      case 0:
        //Data Context Pod Component
        activeComponent = (
          <DataContextDatapodDetails
            dataPodId={currentDataPodId}
            dataPodInitInput={datapodInp}
            industries={industries}
            saveToProceedNext={setDatapodInp}
            updateNewIndustry={setIndustries}
          />
        )
        break
      // case 1:
      //   //DataSource Component
      //   activeComponent = (
      //     <DataSource
      //       saveToProceedNext={setDataSource}
      //       subjectAreaList={subjectAreaList}
      //       dataPodId={currentDataPodId}
      //     />
      //   )
      //   break

      // case 2:
      //   // Deploy data Lake
      //   activeComponent = (
      //     <DeployDataLake
      //       saveToProceedNext={setDataSource}
      //       subjectAreaList={subjectAreaList}
      //       dataPodId={currentDataPodId}
      //     />
      //   )
      //   break
      case 1:
        //Subject Area Details
        activeComponent = (
          <DataContextSubjectArea
            subjectAreas={subjectAreaDetails}
            refetchSubjectAreas={refetchSubjectAreas}
            dataPodId={currentDataPodId}
            currentDataPodInfo={datapodInp}
            dataPodIndusty={selIndustry}
            dataSystemsList={dataSystems}
            deleteSubjArea={deleteSubjArea}
          />
        )
        break
      case 2:
        //Service Connection Details
        activeComponent = (
          <DataContextService
            dataPodId={currentDataPodId}
            connections={connections}
            refreshConnections={refreshConnections}
            // handleService={handleService}
            // serviceInfo={serviceInfo}
            dataSystemsList={dataSystems}
          />
        )
        break
      case 3:
        //data System Details
        activeComponent = (
          <DataContextDataSystem
            subjectAreas={subjectAreaDetails}
            datapodId={currentDataPodId}
            currentDataPodInfo={datapodInp}
            refetchDataSystems={refetchDataSystems}
            dataSystemsList={dataSystems}
            deleteDataSystem={deleteDataSystem}
          />
        )
        break
      case 4:
        // Data Pod Details Summary
        activeComponent = (
          <DataContextSummary
            selectedIndustry={selIndustry}
            dataPodDetails={datapodInp}
            subjectAreas={subjectAreaDetails}
            dataSystems={dataSystems}
            goToStep={setActiveStep}
            dataPodId={currentDataPodId}
            connections={connections}
          />
        )
        break
      default:
        break
    }

    return activeComponent
  }

  return (
    <ActionPage>
      <ResultStatus
        showStatus={!!dataPodSaveError}
        alertMessage="please fill required fields or invalid entries"
        severtiy="error"
        closeStatus={setDataPodSaveError}
      />
      <Box sx={{ p: 2 }}>
        
        <Grid item  xs={12} container spacing={1}>
        <Grid item xs={3}>
           <PreviousPage  onClick={()  =>navigate('/')} pageName='DataPods Home'/>
        </Grid>
        {/* <Button variant="contained" onClick={() => navigate('/')} >{'DataPods Home'} </Button> */}
          <Grid item xs={15} />
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} orientation="horizontal">
              {DataPodCreation.steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: {
                  optional?: React.ReactNode
                } = {}
                // if (isStepOptional(index)) {
                // }
                // if (isStepSkipped(index)) {
                //   stepProps.completed = false
                // }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Grid>

          <Grid item xs={12} sx={{}}>
            {/* <Typography gutterBottom variant="h1" component="span">
              {DataPodCreation.steps[activeStep]}
            </Typography>
            {activeStep !== 0 && (
              <DataPodDetailRibbon
                dataPodId={currentDataPodId}
                dataPodName={datapodInp?.name}
                dataPodIndustryName={datapodInp?.industryName || selIndustry?.industryName}
              />
            )} */}

            {renderStep()}
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent={'end'} sx={{ mt: 2 }} spacing={1}>
          {activeStep === DataPodCreation.steps.length ? (
            <Button onClick={handleReset}>Reset</Button>
          ) : (
            <>
              <Grid item>
                <Button onClick={handleBack} disabled={activeStep === 0} variant="contained">
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleNext} variant="contained" disabled={buttonDisabled}>
                  {activeStep === DataPodCreation.steps.length - 1
                    ? 'Home'
                    : activeStep === 0
                    ? 'Save and Continue'
                    : 'Continue'}
                </Button>
              </Grid>
              <Grid item>
                {activeStep === DataPodCreation.steps.length - 1 && (
                  <Button onClick={navigateToOveriew} variant="contained" disabled={!currentDataPodId}>
                    {'Manage'}
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </ActionPage>
  )
}
