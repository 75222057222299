import { RecommendedSubjectArea } from 'pages/DataContext/DataContextSubjectArea'
import {
  getCoreEntityAttributes,
  getEnrichedCoreEntityAttributes,
  getGenAiReconcilation,
  getGenAiRelations,
  generateGenAISubjectAreas,
  NewEntityMapping,
  getEditEntityMapping,
  SchemaCompatibilty,
  SchemaCompatibiltyAttribute,
  getGenAiDataFlow,
} from '../apiServices/genAiApiServices'
import { genServices } from 'apiServices/lucidGenAIServices'
import { genDatapodServices } from 'apiServices/genAiDatapodServices'
import {
  GenAIReconcilationResponse,
  GenAIRelationResponse,
  GenAiGenerateMappingDetials,
  GenAttributeDataTypes,
  GeneratedAttributesResponse,
  Industry,
  RecommendedSubjectAreas,
  coreEntityAttributes,
  LocalLLmEntityData,
  getPySparkCodeResponse,
  ConversionMetricsRequest,
  getConversionMetricsResponse,
  GenericConversionMetricsRequest
} from '../businessObjects'
import React, { useEffect, useState } from 'react'

export const useGetGenAiReconcilation = (): {
  fetchEntity: (datapodId: string, prompt: string) => Promise<GenAIReconcilationResponse | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchEntity = async (datapodId: string, prompt?: string) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        const { data, status } = await getGenAiReconcilation(bearerToken, datapodId, prompt)
        if (status == 200) {
          return data as GenAIReconcilationResponse
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchEntity }
}

export const useGetGenAiDataFlow = (): {
  fetchEntityDataFlow: (datapodId: string) => Promise<GenAIReconcilationResponse | undefined>
  isDataFlowGenerating: boolean
} => {
  const [isDataFlowGenerating, setIsDataFlowGenerating] = useState<boolean>(false)

  // const { getBearerToken } = useLucidAuthContext()
  const fetchEntityDataFlow = async (datapodId: string, prompt?: string) => {
    try {
      const bearerToken = 'Lucid123'
      setIsDataFlowGenerating(true)
      if (bearerToken && datapodId) {
        const { data, status } = await getGenAiDataFlow(bearerToken, datapodId)
        if (status == 200) {
          return data as GenAIReconcilationResponse
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsDataFlowGenerating(false)
    }
  }
  return { fetchEntityDataFlow, isDataFlowGenerating }
}

export const userGenerateSubjectAreas = (): {
  fetchSubjectAreas: (datapodId: string) => Promise<RecommendedSubjectAreas | undefined>
} => {
  // const [subjectAreaList, setSubjectAreaList] = React.useState<RecommendedSubjectAreas>()
  const fetchSubjectAreas = async (datapodId: string) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        const { data, status } = await generateGenAISubjectAreas(bearerToken, datapodId)
        if (status == 200) {
          return data as RecommendedSubjectAreas
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchSubjectAreas }
}

export const useGetGenAiRelations = (): {
  fetchRelations: (datapodId: string, dataCoreEntityId: number) => Promise<GenAIRelationResponse | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchRelations = async (datapodId: string, dataCoreEntityId: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && dataCoreEntityId) {
        const { data, status } = await getGenAiRelations(bearerToken, datapodId, dataCoreEntityId)
        if (status == 200) {
          return data as GenAIRelationResponse
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchRelations }
}

export const useGetGenAiCoreEntityAttributes = (): {
  fetchCoreEntityAttributes: (datapodId: string, dataCoreEntityId: number) => Promise<coreEntityAttributes | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchCoreEntityAttributes = async (datapodId: string, dataCoreEntityId: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && dataCoreEntityId) {
        const { data, status } = await getCoreEntityAttributes(bearerToken, datapodId, dataCoreEntityId)
        if (status == 200) {
          return data as coreEntityAttributes
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchCoreEntityAttributes }
}
export type enrichedCoreAttributes= {
  entityId:number
  attributes:string[]
}
export const useGetGenAiEnrichedCoreEntityAttributes = (): {
  fetchEnrichedCoreEntityAttributes: (
    datapodId: string,
    coreEntityName: string,
    entityDescription: string,
    entityType: string,
    canonicalEntityIds: number[],
  ) => Promise<{
    result: 'Success' | 'Failed',
    data?: enrichedCoreAttributes[],
    warning?: string,
    missingLog?: any,
    error?: string
  } | undefined>
} => {
  const fetchEnrichedCoreEntityAttributes = async (
    datapodId: string,
    coreEntityName: string,
    entityDescription: string,
    entityType: string,
    canonicalEntityIds: number[],
  ) => {
    try {
      const bearerToken = 'Lucid123';
      if (bearerToken && datapodId && coreEntityName && entityDescription && entityType && canonicalEntityIds) {
        const { data, status } = await getEnrichedCoreEntityAttributes(
          bearerToken,
          datapodId,
          coreEntityName,
          entityType,
          entityDescription,
          canonicalEntityIds,
        );

        // Check the status code for success or failure
        if (status === 200) {
          const { result, data: modelData, warning, missingLog, error } = data;

          // Handle the different cases based on the backend response
          if (result === 'Success') {
            if (warning && !missingLog) {
              return { result, data: modelData as enrichedCoreAttributes[], warning };
            } 
            
            if (warning || missingLog) {
              return { result, data: modelData as enrichedCoreAttributes[], warning, missingLog };
            }

            return { result, data: modelData as enrichedCoreAttributes[] };
          } else if (result === 'Failed') {
            return { result, error };
          }
        }
        if (status === 400) {
          const { error } = data;
          console.log(error)
          return { result: 'Failed', error: error || 'Bad request error' };
        }
      }
    } catch (err) {
      console.error('Error fetching enriched core entity attributes:', err);
      return { result: 'Failed', error: String(err) };
    }
  };

  return { fetchEnrichedCoreEntityAttributes };
};
// export const useGetGenAiEnrichedCoreEntityAttributes = (): {
//   fetchEnrichedCoreEntityAttributes: (
//     datapodId: string,
//     coreEntityName: string,
//     entityDescription: string,
//     entityType: string,
//     canonicalEntityIds: number[],
//   ) => Promise<enrichedCoreAttributes[] | undefined>
// } => {
//   // const { getBearerToken } = useLucidAuthContext()
//   const fetchEnrichedCoreEntityAttributes = async (
//     datapodId: string,
//     coreEntityName: string,
//     entityDescription: string,
//     entityType: string,
//     canonicalEntityIds: number[],
//   ) => {
//     try {
//       const bearerToken = 'Lucid123'
//       if (bearerToken && datapodId && coreEntityName && entityDescription && entityType && canonicalEntityIds) {
//         const { data, status } = await getEnrichedCoreEntityAttributes(
//           bearerToken,
//           datapodId,
//           coreEntityName,
//           // entityDescription,
//           entityType,
//           entityDescription,
//           canonicalEntityIds,
//         )
//         if (status == 200) {
//           console.log({data})
//           return data.data as enrichedCoreAttributes[]
//         }
//       }
//     } catch (err) {
//       console.log(err)
//     }
//   }
//   return { fetchEnrichedCoreEntityAttributes }
// }

export const useRecommendSubjectAreas = (datapodId?: string, industry?: Industry) => {
  const [recommendations, setRecommendations] = useState<RecommendedSubjectArea[]>([])
  // const [recommendationsRow, setRecommendationRows] = useState<DataTableRow[]>([])
  const [recommendationError, setRecommendationError] = useState<boolean>(false)
  const [loadingRecommendations, setLoadingRecommendations] = useState<boolean>(false)
  const fetchSubjectAreas = async () => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId) {
        setLoadingRecommendations(true)
        const { data, status } = await generateGenAISubjectAreas(bearerToken, datapodId)
        if (status == 200 && data) {
          const res = data as RecommendedSubjectAreas
          if (res.result === 'Success') {
            setLoadingRecommendations(false)
            if (res.data.length) {
              setRecommendations(
                res.data.map((sa) => {
                  if (!sa.SubjectAreaName || !sa.SubjectAreaDescription) {
                    throw new Error('Missing required properties: SubjectAreaName or SubjectAreaDescription');
                  }

                  return {
                    name: sa.SubjectAreaName,
                    description: sa.SubjectAreaDescription,
                    industryname: industry?.industryName ?? '',
                    industryId: industry?.id ?? 0,
                  }
                }),
              )
            } else {
              setRecommendationError(true)
            }
          }
        }
      }
    } catch (err) {
      setLoadingRecommendations(false)
      setRecommendationError(true)
      console.log(err)
    }
  }

  useEffect(() => {
    if (datapodId && !recommendations.length) fetchSubjectAreas()
  }, [datapodId])
  return { recommendations, loadingRecommendations, recommendationError }
}

export const useGetGenAiNewMappings = (): {
  fetchNewMappings: (
    datapodId: string,
    dataSystemId: number,
    dataTableId: number,
    dataEntityName: string,
    dataEntityDescription: string,
  ) => Promise<GenAiGenerateMappingDetials | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchNewMappings = async (
    datapodId: string,
    dataSystemId: number,
    dataTableId: number,
    dataEntityName: string,
    dataEntityDescription: string,
  ) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && dataSystemId && dataTableId && dataEntityName && dataEntityDescription) {
        const { data, status } = await NewEntityMapping(
          bearerToken,
          datapodId,
          dataSystemId,
          dataTableId,
          dataEntityName,
          dataEntityDescription,
        )
        if (status == 200) {
          return data.data[0] as GenAiGenerateMappingDetials
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchNewMappings }
}
export const useGetGenAiEditMappings = (): {
  fetchAttributesForExistingEntityRemap: (
    datapodId: string,
    dataSystemId: number,
    dataTableId: number,
    dataEntityId: number,
  ) => Promise<GenAiGenerateMappingDetials | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchAttributesForExistingEntityRemap = async (
    datapodId: string,
    dataSystemId: number,
    dataTableId: number,
    dataEntityId: number,
  ) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && datapodId && dataSystemId && dataTableId && dataEntityId) {
        const { data, status } = await getEditEntityMapping(
          bearerToken,
          datapodId,
          dataSystemId,
          dataTableId,
          dataEntityId,
        )
        if (status == 200) {
          return data.data[0] as GenAiGenerateMappingDetials
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  return { fetchAttributesForExistingEntityRemap }
}
export const useGenerateAttributeTypes = (): {
  fetchAttributeTypes: (dataPodId: string, dataEntityId: number, dataTableId: number) => Promise<boolean | undefined>
} => {
  // const { getBearerToken } = useLucidAuthContext()
  const fetchAttributeTypes = async (dataPodId: string, dataEntityId: number, dataTableId: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && dataPodId && dataEntityId && dataTableId) {
        const genDataAttType: GenAttributeDataTypes = {
          dataPodId: dataPodId,
          dataEntityId: dataEntityId,
          dataTableId: dataTableId,
        }
        const { data, status } = await genDatapodServices.generateAttributeDataTypes(bearerToken, genDataAttType)
        if (status == 200) {
          return true
        }
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }
  return { fetchAttributeTypes }
}

export const useSchemaComapatibilty = (): {
  fetchSchemaComapatibilty: (dataPodId: string, dataEntityId: number) => Promise<{} | undefined>
} => {
  const fetchSchemaComapatibilty = async (dataPodId: string, dataEntityId: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await SchemaCompatibilty(bearerToken, dataPodId, dataEntityId)
        if (status == 200) {
          return true
        }
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }
  return { fetchSchemaComapatibilty }
}

export const useSchemaComapatibiltyAtrribute = (): {
  fetchSchemaComapatibiltyAtrribute: (
    dataPodId: string,
    dataEntityId: number,
    attributeId: number,
    flag: string,
    dataType: string,
  ) => Promise<{} | undefined>
} => {
  const fetchSchemaComapatibiltyAtrribute = async (
    dataPodId: string,
    dataEntityId: number,
    attributeId: number,
    flag: string,
    dataType: string,
  ) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await SchemaCompatibiltyAttribute(
          bearerToken,
          dataPodId,
          dataEntityId,
          attributeId,
          flag,
          dataType,
        )
        if (status == 200) {
          return true
        }
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }
  return { fetchSchemaComapatibiltyAtrribute }
}

export const useGenerateMappingByColumn = (): {
  getMappingsByColumn: (dataPodId: string, dataSystemId: number, dataTableId?: number) => Promise<{} | undefined>
} => {
  const getMappingsByColumn = async (dataPodId: string, dataSystemId: number, dataTableId?: number) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && dataPodId && dataSystemId && dataTableId) {
        const mappingBody = {
          dataPodId,
          dataSystemId,
          dataTableId,
        }
        const { data, status } = await genServices.generateMappingByColumn(mappingBody, bearerToken)
        if (status == 200) {
          return true
        }
      }
    } catch (err) {
      console.log(err)
      return false
    }
  }
  return { getMappingsByColumn }
}


export const useGenerateCustomEnrichedEntityDataFromFile = (): {
  getEntityDataByFile: (dataPodId: string, file:File) => Promise<LocalLLmEntityData | undefined>
  isGetEntityDataByFileLoading : boolean
} => {
  const [isGetEntityDataByFileLoading, setIsGetEntityDataByFileLoading] = useState<boolean>(false)

  const getEntityDataByFile = async (dataPodId: string, file:File) => {
    try {
      if (dataPodId && file) {
        setIsGetEntityDataByFileLoading(true)
        const { data, status } = await genServices.generateCustomEnrichedData(file, dataPodId)
        if (status == 200) {
          return data as LocalLLmEntityData
        }
      }
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      setIsGetEntityDataByFileLoading(false)
    }
  }
  return { getEntityDataByFile, isGetEntityDataByFileLoading }
}

export const useGenerateEmbeddings = (): {
  addEmbeddings: (dataPodId: string) => Promise<boolean | undefined>
  isGeneratingEmbedding : boolean
} => {
  const [isGeneratingEmbedding, setIsGeneratingEmbedding] = useState<boolean>(false)

  const addEmbeddings = async (dataPodId: string) => {
    try {
      if (dataPodId) {
        setIsGeneratingEmbedding(true)
        const { data, status } = await genServices.generateEmbeddings(dataPodId)
        if (status == 200) {
          return true
        }
      }
    } catch (err) {
      console.log(err)
      return false
    } finally {
      setIsGeneratingEmbedding(false)
    }
  }
  return { addEmbeddings, isGeneratingEmbedding }
}

export const useGetPySparkCodeForSourceScript = (): {
  getPySparkCodeByFile: (file:File,conversionTargetType:string,userEnteredPrompt:string) => Promise<getPySparkCodeResponse | undefined>
  isGetPySparkCodeLoading : boolean
} => {
  const [isGetPySparkCodeLoading, setIsGetPySparkCodeLoading] = useState<boolean>(false)

  const getPySparkCodeByFile = async (file:File,conversionTargetType:string, userEnteredPrompt:string) => {
    try {
      if (file && conversionTargetType && userEnteredPrompt) {
        setIsGetPySparkCodeLoading(true)
        const { data, status } = await genServices.getPysparkCode(file, conversionTargetType, userEnteredPrompt)
        if (status == 200) {
          console.log(data)
          return data
        }
      }
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      setIsGetPySparkCodeLoading(false)
    }
  }
  return { getPySparkCodeByFile, isGetPySparkCodeLoading }
}

export const useGetPySparkCodeMetrics = (): {
  getPySparkCodeMetrics: (body:ConversionMetricsRequest) => Promise<getConversionMetricsResponse | undefined>
  isGetPySparkCodeMetricsLoading : boolean
} => {
  const [isGetPySparkCodeMetricsLoading, setIsGetPySparkCodeMetricsLoading] = useState<boolean>(false)

  const getPySparkCodeMetrics = async (body:ConversionMetricsRequest) => {
    try {
      if (body) {
        setIsGetPySparkCodeMetricsLoading(true)
        const { data, status } = await genServices.getConversionCodeMetrics(body)
        if (status == 200) {
          console.log(data)
          return data
        }
      }
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      setIsGetPySparkCodeMetricsLoading(false)
    }
  }
  return { getPySparkCodeMetrics, isGetPySparkCodeMetricsLoading }
}

export const useGetGenericPySparkCodeMetrics = (): {
  getGenericCodeMetrics: (body:GenericConversionMetricsRequest) => Promise<getConversionMetricsResponse | undefined>
  isGetGenericCodeMetricsLoading : boolean
} => {
  const [isGetGenericCodeMetricsLoading, setIsGetGenericCodeMetricsLoading] = useState<boolean>(false)

  const getGenericCodeMetrics = async (body:GenericConversionMetricsRequest) => {
    try {
      if (body) {
        setIsGetGenericCodeMetricsLoading(true)
        const { data, status } = await genServices.getGenericConversionCodeMetrics(body)
        if (status == 200) {
          console.log(data)
          return data
        }
      }
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      setIsGetGenericCodeMetricsLoading(false)
    }
  }
  return { getGenericCodeMetrics, isGetGenericCodeMetricsLoading }
}