import { useState, useCallback, useEffect } from 'react'
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  OnNodesChange,
  OnEdgesChange,
  MarkerType,
  Controls,
  Background,
  MiniMap,
  ControlButton,
} from 'reactflow'
import 'reactflow/dist/style.css'
import './reactFlow.css'

import BiDirectionalNode from './BidirectionalNode'
import CustomNode from './CustomNode'
import CustomEdge from './CustomEdge'
import DownloadButton from './DownloadButton'

import React from 'react'
type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
const nodeTypes = {
  customNode: CustomNode,
  bidirectional: BiDirectionalNode,
}
const edgeTypes = {
  custom: CustomEdge,
}
type UpdatedNode = Node & {
  ref: string
}

type leftEdgeId = {
  leftId: string
  data: { label: string }
}
type rightEdgeId = {
  rightId: string
  data: { label: string }
  relation: string
}
const panOnDrag = [1, 2]
function createNodesFromJsonData(data: any) {
  const nodes: Node[] = []
  const numColumns = 12
  const numRows = Math.ceil(data.length / numColumns)
  data.forEach((table: any, index: any) => {
    const groupId = `group-${index}`
    const rowIndex = Math.floor(index / numColumns)
    const colIndex = index % numColumns
    const groupWidth = 330
    const horizontalSpacing = 170
    const columnLength = table.attributes.length
    let subNodeHeight = 50
    let groupHeight = (columnLength + 1) * subNodeHeight + 10
    const verticalSpacing = groupHeight + 800
    const groupPosition = {
      x: 800 + (groupWidth + horizontalSpacing) * colIndex + 40,
      y: 900 + verticalSpacing * rowIndex,
    }

    const groupNode: Node = {
      id: groupId,
      type: 'group',
      data: { label: table.entityName },
      position: groupPosition,
      style: {
        width: '300px',
        height: groupHeight,
        WebkitBoxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#F2F2F2',
        borderWidth: '0px',
        boxShadow: '3',
        borderRadius: '3',
        padding: '16px',
        overflow: 'hidden',
      },
    }
    nodes.push(groupNode)
    const customNodeId = `custom-node-${index}`
    const customNodePosition = { x: 0, y: 0 }
    const customNode: Node = {
      id: customNodeId,
      type: 'customNode',
      data: { entityName: table.entityName },
      draggable: false,
      extent: 'parent',
      parentNode: groupId,
      position: customNodePosition,
      style: {
        backgroundColor: '#316896',
        width: 300,
        color: 'white',
        padding: '16px',
        alignItems: 'center',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        lineHeight: '19.07px',
        fontWeight: 500,
        margin: 0,
      },
    }
    nodes.push(customNode)

    table.attributes.forEach((column: any, subIndex: any) => {
      const subNodeId = `${groupId}-${subIndex}`
      const subNodeLabel = column.attributeName
      const nodeType = column.type
      const subNodePosition = { x: 2, y: 60 + 50 * subIndex }
      const subNode: UpdatedNode = {
        id: subNodeId,
        data: { label: subNodeLabel, type: nodeType },
        ref: column.attributeName,
        position: subNodePosition,
        draggable: false,
        extent: 'parent',
        type: 'bidirectional',
        parentNode: groupId,
        style: {
          width: 295,
          height: subNodeHeight + 'px',
          backgroundColor: '#F7FAFC',
          color: '#202020',
          fontFamily: 'Open Sans',
          fontSize: '12px',
          lineHeight: '19.07px',
          fontWeight: 500,
          cursor: 'pointer',
        },
      }
      nodes.push(subNode)
    })
  })

  return nodes
}

function getColumnIds(nodes: any, parentId: any, columnName: any) {
  const columnNodes = nodes.filter(
    (node: any) => node.type === 'bidirectional' && node.parentNode === parentId && node.ref === columnName,
  )
  return columnNodes.map((node: any) => node.id)
}

function createEdges(relationData: any, nodes: any) {
  const groupIds = nodes.filter((node: any) => node.type === 'group').map((group: any) => group)
  const leftColumnIds: leftEdgeId[] = []
  const rightColumnIds: rightEdgeId[] = []
  relationData.forEach((relation: any) => {
    const leftTable = relation.leftEntity
    const leftTableId = groupIds.find((node: any) => node.data.label === leftTable)?.id

    if (!leftTableId) {
      console.error(`Group ID not found for table: ${leftTable}`)
      return
    }

    const rightTable = relation.rightEntity
    const rightTableId = groupIds.find((node: any) => node.data.label === rightTable)?.id

    if (!rightTableId) {
      console.error(`Group ID not found for table: ${rightTable}`)
      return
    }

    const leftColumnId = getColumnIds(nodes, leftTableId, relation.leftAttributeName)
    const leftNode = nodes.find((node: any) => node.id === leftColumnId[0])
    // const leftEdge: leftEdgeId = {
    //   leftId: leftColumnId[0],
    //   data: { label: leftNode.data.label },
    // }
    if (leftNode) {
      const leftEdge: leftEdgeId = {
        leftId: leftColumnId[0],
        data: { label: leftNode.data.label },
      }
      leftNode.data.source = true
      leftColumnIds.push(leftEdge)
    }
    // leftColumnIds.push(leftEdge)
    const rightColumnId = getColumnIds(nodes, rightTableId, relation.rightAttributeName)
    const rightNode = nodes.find((node: any) => node.id === rightColumnId[0])
    // const rightEdge: rightEdgeId = {
    //   rightId: rightColumnId[0],
    //   data: { label: rightNode.data.label },
    //   relation: relation.cardinality,
    // }
    if (rightNode) {
      const rightEdge: rightEdgeId = {
        rightId: rightColumnId[0],
        data: { label: rightNode.data.label },
        relation: relation.cardinality,
      }
      rightNode.data.target = true
      rightColumnIds.push(rightEdge)
    }
    // rightColumnIds.push(rightEdge)
  })

  return { leftColumnIds, rightColumnIds }
}

function generateEdges(leftColumnIds: leftEdgeId[], rightColumnIds: rightEdgeId[]) {
  const edges: Edge[] = []
  leftColumnIds.forEach((leftColumnId, index) => {
    const rightColumnId = rightColumnIds[index]
    if(rightColumnId)
    {
      const edge = {
        id: `edge-${leftColumnId.leftId}-${rightColumnId.rightId}-${index}`,
        data: {
          label: rightColumnId.relation,
        },
        label: rightColumnId.relation,
        source: leftColumnId.leftId,
        target: rightColumnId.rightId,
        type: 'custom',
        style: {
          strokeWidth: 2,
          stroke: 'green',
        },
      }
      edges.push(edge)
    }

  })

  return edges
}

const ErDiagramFlow = (data: any) => {
  const nodeData = data.nodeData
  const edgeData = data.edgeData
  let initialNodeValues: any = []
  let initialEdges: any = []
  if (nodeData.length && edgeData.length) {
    initialNodeValues = createNodesFromJsonData(nodeData)
  }
  const [nodes, setNodes] = useState<Node[]>([])
  const [edges, setEdges] = useState<Edge[]>([])
  const [hidden, setHidden] = useState(false)
  const [snowFlake, setSnowFlake] = useState(false)

  // if (initialNodeValues.length) {
  //   const { leftColumnIds, rightColumnIds } = createEdges(edgeData, initialNodeValues)
  //   initialEdges = generateEdges(leftColumnIds, rightColumnIds)
  //   useEffect(() => {
  //     setNodes(initialNodeValues)
  //     setEdges(initialEdges)
  //   }, [])
  // }
  useEffect(() => {
    if (nodeData.length && edgeData.length) {
      const { leftColumnIds, rightColumnIds } = createEdges(edgeData, initialNodeValues)
      initialEdges = generateEdges(leftColumnIds, rightColumnIds)
      setNodes(initialNodeValues)
      setEdges(initialEdges)
    }
  }, [nodeData, edgeData])
  const onNodesChange: OnNodesChange = useCallback(
    (changes: any) => setNodes((nodes) => applyNodeChanges(changes, nodes)),
    [setNodes],
  )
  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  )
  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) => addEdge({ ...params, type: 'smart', markerEnd: { type: MarkerType.Arrow } }, eds)),
    [setEdges],
  )
  const hide = (hidden: boolean) => (node: any) => {
    node.hidden = hidden
    return node
  }
  useEffect(() => {
    const groupNodeCounts: any = {}
    const nodeData = nodes.filter((node) => {
      if (!node.data.source && !node.data.target && node.type === 'bidirectional') {
        return false
      }
      return { ...node }
    })
    nodes.forEach((node) => {
      if ((node.data.source || node.data.target) && node.type === 'bidirectional') {
        const groupId = node.parentNode
        if (groupId) {
          if (groupNodeCounts[groupId]) {
            groupNodeCounts[groupId]++
          } else {
            groupNodeCounts[groupId] = 1
          }
        }
      }
    })
    const updatedNodes = nodeData.map((node, index: number) => {
      if (node.type === 'group') {
        const groupId = node.id
        const subNodeHeight = 50
        const groupCount = groupNodeCounts[groupId] || 0
        if (groupCount === 0) {
          return {
            ...node,
            style: {
              ...node.style,
              height: 0,
            },
          }
        }
        const heightReduction = (groupCount + 1) * subNodeHeight + 10
        const newHeight = heightReduction
        return {
          ...node,
          style: {
            ...node.style,
            height: newHeight,
          },
        }
      } else if (node.type === 'bidirectional') {
        const nodePosition = node.position
        const parent = node.parentNode
        if (parent) {
          const parentGroupCount = groupNodeCounts[parent] || 0
          const subNodeHeight = 50
          let attributeIndex = 0
          for (const otherNode of nodeData) {
            if (otherNode.parentNode === parent && otherNode.type === 'bidirectional') {
              attributeIndex++
              if (otherNode === node) {
                break
              }
            }
          }
          const updatedPosition = {
            x: nodePosition.x,
            y: parentGroupCount === 0 ? 0 : 10 + subNodeHeight * attributeIndex,
          }
          return {
            ...node,
            position: updatedPosition,
          }
        }
      }
      return node
    })

    if (hidden === true) {
      setNodes(updatedNodes)
    } else {
      const { leftColumnIds, rightColumnIds } = createEdges(edgeData, initialNodeValues)
      initialEdges = generateEdges(leftColumnIds, rightColumnIds)
      setNodes(initialNodeValues)
      setEdges(initialEdges)
    }
  }, [hidden])

  const updatePos = useCallback(() => {
    setSnowFlake(!snowFlake)
    if (snowFlake) {
      const centerX = window.innerWidth / 2
      const centerY = window.innerHeight / 2
      const radius = 1000
      setNodes((nodes) => {
        const groupNodes = nodes.filter((node) => node.type === 'group')
        const angleStep = (2 * Math.PI) / groupNodes.length
        const updatedGroupNodes: any = []
        const occupiedPositions: any = {}
        groupNodes.forEach((node, index) => {
          let angle = angleStep * index
          let distance = 0
          while (occupiedPositions[`${Math.round(angle * 100)},${distance}`]) {
            if (Math.abs(radius - distance) < 100) {
              angle += 0.2
              distance = 0
            } else {
              angle += 0.01
              distance += 10
            }
          }
          const x = centerX + (radius - distance) * Math.cos(angle)
          const y = centerY + (radius - distance) * Math.sin(angle)
          occupiedPositions[`${Math.round(angle * 100)},${distance}`] = true
          updatedGroupNodes.push({
            ...node,
            position: { x, y },
          })
        })

        const updatedNodes = nodes.map((node) => (node.type === 'group' ? updatedGroupNodes.shift() || node : node))
        return updatedNodes
      })
    }
  }, [snowFlake])

  const handleNodeClick = useCallback(
    (event: any, object: any) => {
      if (object.type === 'bidirectional') {
        const selectedNodeId = object.id
        setEdges((edges: Edge[]) => {
          return edges.map((el) => {
            if (el.source === selectedNodeId || el.target === selectedNodeId) {
              return {
                ...el,
                ...(el.style = { stroke: 'red' }),
                animated: true,
              }
            } else if (el.source !== selectedNodeId || el.target !== selectedNodeId) {
              return {
                ...el,
                animated: false,
              }
            }
            return el
          })
        })
      }
    },
    [edges],
  )
  return (
    <div style={{ width: '100vw', height: '4100%' }} className="reactComponentMount">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        minZoom={0.099}
        panOnScroll
        selectionOnDrag
        panOnDrag={panOnDrag}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        snapToGrid={true}
        onNodeClick={handleNodeClick}
      >
        {/* <Controls position="top-right">
          <ControlButton>
            <DownloadButton />
          </ControlButton>
        </Controls>
        <MiniMap position="top-left" /> */}
        <Controls position="top-left">
          <ControlButton>
            <DownloadButton />
          </ControlButton>
        </Controls>
        <MiniMap
          zoomable
          pannable
          position="top-right"
          style={{ height: 100, top: '0', backgroundColor: '#2E92DA' }}
          title={'Mapping Mini Map'}
        />
        <Background />
        <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 4 }}>
          <div>
            <label htmlFor="Hide columns with no relation">
              Show tables and key columns
              <input
                id="ishidden"
                type="checkbox"
                checked={hidden}
                onChange={(event) => setHidden(event.target.checked)}
                className="react-flow__ishidden"
              />
            </label>
          </div>
        </div>
        <button onClick={updatePos} style={{ position: 'absolute', left: 10, top: 30, zIndex: 4 }}>
          Rearrange tables
        </button>
      </ReactFlow>
    </div>
  )
}
export default ErDiagramFlow
