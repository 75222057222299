import { genDatapodServices } from 'apiServices/genAiDatapodServices'
import { genServices } from 'apiServices/lucidGenAIServices'
import { HttpStatusCode } from 'axios'
import {
  DataQualityRuleRecomm,
  GenAttributeDataTypes,
  GenBusinessKey,
  GenDQRecommendationReq,
  GeneratedDQRecommedations,
} from 'businessObjects'
import { genDataPodServices } from 'genapiservices/genDataPodServices'
import { useEffect, useState } from 'react'

export const useGenerateBusinessKey = () => {
  const generateBusinessKey = async (reqGenBusiness: GenBusinessKey) => {
    try {
      const bearerToken = 'Lucid123'

      if (bearerToken && reqGenBusiness.dataPodId && reqGenBusiness.entityId) {
        const { status } = await genDatapodServices.generateBusiniessKey(bearerToken, reqGenBusiness)

        return status == HttpStatusCode.Ok
      }
    } catch (err) {
      console.log(err)
    }
  }

  return { generateBusinessKey }
}

export const useGenerateAttributeDataTypes = () => {
  const generateAttributeDataTypes = async (reqGenAttributeDataTypes: GenAttributeDataTypes) => {
    try {
      const bearerToken = 'Lucid123'
      const { dataEntityId, dataPodId, dataTableId } = reqGenAttributeDataTypes
      if (bearerToken && dataEntityId && dataPodId && dataTableId) {
        const { status } = await genDatapodServices.generateAttributeDataTypes(bearerToken, reqGenAttributeDataTypes)

        return status == HttpStatusCode.Ok
      }
    } catch (err) {
      console.log(err)
    }
  }

  return { generateAttributeDataTypes }
}

export const useGenerateDataQualityRecommendation = (dataPodId?: string, entityId?: number) => {
  // const [generatedDataQualityRules, setGeneratedDataQualityRules] = useState<DataQualityRuleRecomm[]>([])
  const [loadingDQRules, setLoadingDQRules] = useState<boolean>(false)
  const generateDataQualityRules = async (attributeIds: number[]) => {
    try {
      const bearerToken = 'Lucid123'
      setLoadingDQRules(true)
      if (bearerToken && entityId && dataPodId && entityId) {
        const req: GenDQRecommendationReq = {
          dataPodId: dataPodId,
          dataEntityId: entityId,
          attribute_ids_list: attributeIds.length ? attributeIds : [],
        }
        const { data, status } = await genDataPodServices.recommendDQRecommendations(req, 'Lucid123')
        setLoadingDQRules(false)
        if (status == HttpStatusCode.Ok) {
          return data.data as DataQualityRuleRecomm[]
          // setGeneratedDataQualityRules(data.data)
        }
      }
    } catch (err) {
      setLoadingDQRules(false)

      console.log(err)
    }
  }
  // useEffect(() => {
  //   if (dataPodId && entityId) {
  //     generateDataQualityRules([])
  //   }
  // }, [dataPodId, entityId])
  // return { generateDataQualityRules, generatedDataQualityRules, loadingDQRules }
  return { generateDataQualityRules, loadingDQRules }
}

export const useGenAIPrimaryKeySelector = (dataPodId?: string) => {
  const generatePrimaryKeys = async (dataTableIds: string[]) => {
    try {
      const bearerToken = 'Lucid123'
      if (bearerToken && dataPodId && dataTableIds.length > 0) {

        const { data, status } = await genDataPodServices.primaryKeySelector(dataPodId, dataTableIds, 'Lucid123')
        if (status == HttpStatusCode.Ok) {
          return true 
          // setGeneratedDataQualityRules(data.data)
        }
      }
    } catch (err) {
      console.log(err)
    }
    return false
  }
  return { generatePrimaryKeys }
}