import { useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../Routes/constants'
import { clearTokens } from '../utils/tokens'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { environment } from 'utils/environment'

export const AccountInfo = () => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [name, setName] = useState<string>()

  const awsAuth = environment.authenticator === "AWS" ? useAuthenticator(context => [context.authStatus, context.user]) : null;

  useEffect(() => {
    if(awsAuth && awsAuth.user) {
      setName(awsAuth.user.username)
    } else if (account && account.name) {
      setName(account.name.split(' ')[0])
    }
  }, [account, awsAuth])
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleLogout = () => {
    clearTokens()
    setAnchorEl(null)
    instance.logoutRedirect().then(() => {
      navigate(ROUTES.Home)
    })
  }

  return (
    <>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} color="inherit">
        <AccountCircle />
      </IconButton>
      <Menu id="menu-appbar" anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem disabled key={'accountUser'}>{`${name} `}</MenuItem>
        <MenuItem disableTouchRipple onClick={handleLogout} key="logoutRedirect">
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
