import { useNavigate, useLocation, Route, Routes } from 'react-router-dom'

// MSAL imports
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'
import { CustomNavigationClient } from './utils/NavigationClient'

import LucidHomePage from './ui-components/LucidHomePage'
import { useCallback, useEffect, useMemo } from 'react'

import LucidDataDrawer from './ui-components/Drawer/LucidDataDrawer'
import { entries } from './ui-components/Drawer/NavigationDrawerStyles'
import { PageLayout } from './ui-components/PageLayout'
import { Toolbar, Grid, Typography, Box, CardContent } from '@mui/material'
import { ROUTES } from './Routes/constants'

import MainLayout from './ui-components/MainLayout'

import { DataContextHome } from './pages/DataContext/DataContextHome'
import { DataPodContext } from './pages/DataContext/DataPodContext'
import LucidAuthContextProvider, { useLucidAuthContext } from './LucidAuthContext/LucidAuthContext'

import { innerPageContainer, mainPageContainer, outerPageContainer } from './styles/globalStyles'
// import { AccountHome } from './pages/Accounts/AccountHome'
// import { AccountLayout } from './pages/Accounts/AccountLayout'
import Overview from './pages/Overview/Overview'
import { SourceDataModels } from './pages/DataProcess/SourceDataModels'
import { DataProfilingProcess } from './pages/DataProcess/DataProfilingProcess'
import { DataProfilingViewResults } from './pages/DataProcess/DataProfilingViewResults'
import { DataMappingResults } from './pages/DataProcess/DataMapping/DataMappingResults'
import { DataMappingAttributes } from './pages/DataProcess/DataMapping/DataMappingAttributes'
import { DiscoverRelationships } from './pages/DataProcess/DataModeling/DiscoverRelationships'
import { DataModelingEnterpriseModels } from './pages/DataProcess/DataModeling/DataModelingEnterpriseModels'
import { EntityRelationships } from './pages/DataProcess/DataModeling/EntityRelationships'
import { EntityReconciliation } from './pages/DataProcess/DataModeling/EntityReconciliation'
import { CanonicalModel } from './pages/DataProcess/DataModeling/CanonicalModel'

// Data Engineering
import { DataEngineeringResults } from './pages/DataProcess/DataEngineering/DataEngineeringResults'
import { AllTransformers } from './pages/DataProcess/DataModeling/AllTransformers'
import { CanonicalModelAttributeDetails } from './pages/DataProcess/DataModeling/CanonicalModelAttributeDetails'
import { DataMapping } from './pages/DataProcess/DataMapping/DataMapping'
import { DataProfiling } from './pages/DataProcess/DataProfiling'
import CustomEnrichedModel from './pages/DataProcess/DataModeling/CustomEnrichedModel'
import { SourceToTargetMapping } from './pages/DataProcess/GenerativeIntegrations/SourceToTargetMapping'
import { DataModeling } from './pages/DataProcess/DataModeling/DataModeling'

import { DataEngineeringPipelineMonitoring } from './pages/DataProcess/DataEngineering/DataEngineeringPipelineMonitoring'
import { DataEngineeringPipelineSchedules } from './pages/DataProcess/DataEngineering/DataEngineeringPipelineSchedules'

// Data Engineering
import DataLakeHome from './pages/DataProcess/DataLake'
import { DataLakeResults } from './pages/DataProcess/DataLake/DataLakeResults'

// Data Analytics
import DataAnalyticsHome from './pages/DataAnalytics'
import AnalyticsGroupResults from './pages/DataAnalytics/AnalyticsGroupResults'
import AnalyticsMeasureResults from './pages/DataAnalytics/AnalyticsMeasureResults'
import AddAnalyticsMeasure from './pages/DataAnalytics/AddAnalyticsMeasure'
import { MeasuresDataPreview } from './pages/DataAnalytics/MeasuresDataPreview'

//Settings
import SettingsHome from './pages/Settings'
import ListServiceConnection from './pages/Settings/ServiceConnection'
import ManageRoles from './pages/Settings/Roles/ManageRoles'
import DataEngineeringManageSchedules from './pages/DataProcess/DataEngineering/DataEngineeringManageSchedules'
import { CusomAttribute } from './pages/DataProcess/DataModeling/CustomAttribute/CustomAttribute'
import CustomEnrichedModelTable from './pages/DataProcess/DataModeling/CustomEnrichedModelTable'
import ManageConfiguration from './pages/Settings/Configurations/ManageConfiguration'
import { useGetSessionPodInfo } from './hooks/getSessionPodInfo'
import DataPodDetailRibbon from './ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { DatapodDetails } from './ui-components/DatapodDetails'
import { DataQuality } from './pages/DataProcess/DataModeling/DataQualtiy'
import SignUpHome from 'pages/Authentication/SignUp/SIgnUpHome'
import { useGetAllLucidAccountByTenant } from 'hooks/lucidAccountHooks'
import VnetConfiguration from 'pages/Settings/VnetConifurations/VnetConfiguration'
import DataContextManageAccess from 'pages/DataContext/DataContextManageAccess'
import { CanonicalColumnProfileResults } from 'pages/DataProcess/DataModeling/CanonicalColumnProfileResults'
import DataMappingEditNewEntity from 'pages/DataProcess/DataMapping/DataMappingEditNewEntity'
import { environment } from 'utils/environment'
import { DataMappingEditExistingRemap } from 'pages/DataProcess/DataMapping/DataMappingEditExistingRemap'
import { DataQualityRecommendation } from 'pages/DataProcess/DataModeling/DataQualityRecommendation'
import { CanonicalDataView } from 'pages/DataProcess/DataModeling/CanonicalDataView'
import { EnrichedDataPreview } from 'pages/DataProcess/DataModeling/EnrichedDataPreview'
import { TaskLogs } from 'pages/Logs/TaskLogs'
import { EntityDataFlow } from 'pages/DataProcess/DataModeling/EntityDataFlow'
import { DataHubHomePage } from 'pages/DataHubs/DataHubHomePage'
import { AddDataHubs } from 'pages/DataHubs/AddDataHubs'
import { ViewDataHubs } from 'pages/DataHubs/ViewDataHubs'
import { FetchEntity } from 'pages/DataHubs/FetchEntity'
import { DataModelingLakeMigrationModels } from 'pages/DataProcess/DataModeling/DataModelingLakeMigrationModels'
//AWS imports
import { Authenticator } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { AWSLoginComponents } from 'ui-components/AWSLogin'
import { CombinedDataQualities } from 'pages/DataProcess/DataModeling/CombinedDataQualities'
import ComparingMigrationFiles from 'pages/DataProcess/DataModeling/ComparingMigrationFiles'

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  if (environment.authenticator === 'Azure') {
    return (
      <MsalProvider instance={pca}>
        <LucidAuthContextProvider>
          <Pages />
        </LucidAuthContextProvider>
      </MsalProvider>
    )
  }

  return (
    <Authenticator loginMechanisms={['email']} components={AWSLoginComponents} hideSignUp>
      {({ signOut, user }) => (
        <LucidAuthContextProvider>
          <Pages />
        </LucidAuthContextProvider>
      )}
    </Authenticator>
  )
}

function Pages() {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const { accountId, lucidUserId, isUserAdmin } = useLucidAuthContext()
  const location = useLocation()
  const navigate = useNavigate()
  const activeTab: string = useMemo(() => {
    const selected = entries.filter((drawerItem) => location.pathname.includes(drawerItem.link))
    return selected.length ? selected[selected.length - 1].link : ''
  }, [location])

  const appItemClick = useCallback(
    (path: string): void => {
      navigate('/' + path)
    },
    [navigate],
  )

  if (environment.authenticator === "Azure") {
    if (!isAuthenticated || !accountId || !lucidUserId) {
      return <SignUpHome />;
    }
  } else if (environment.authenticator === "AWS") {
    const { authStatus, user } = useAuthenticator(context => [context.authStatus, context.user]);
    if (authStatus !== 'authenticated' || !accountId || !lucidUserId) {
      return <SignUpHome />;
    }
  }

  // // Helper function to determine if Azure or AWS authentication is valid
  // const isAzureAuthInvalid = environment.authenticator === "Azure" && (!isAuthenticated || !accountId || !lucidUserId);
  // const isAWSAuthInvalid = environment.authenticator === "AWS" && (authStatus !== 'authenticated' || !accountId || !lucidUserId);

  // if (isAzureAuthInvalid || isAWSAuthInvalid) {
  //   return <SignUpHome />;
  // }

  return (
    <>
      <PageLayout>
        <LucidDataDrawer onClick={appItemClick} activeLink={activeTab} entries={entries} />
        <MainLayout>
          <div style={outerPageContainer}>
            <Toolbar />

            <Box style={innerPageContainer} sx={{ boxShadow: 2 }}>
              <div style={mainPageContainer}>
                <Routes>
                  {/* <Route path={ROUTES.Account} element={<AccountHome />} /> */}
                  {/* <Route path={ROUTES.AccountLayout} element={<AccountLayout />} /> */}
                  <Route path={ROUTES.DataHubs} element={<><DataHubHomePage /></>} />
                  <Route path={ROUTES.AddDataHub} element={<><AddDataHubs /></>} />
                  <Route path={ROUTES.ViewDataHub} element={<><ViewDataHubs /></>} />
                  <Route path={ROUTES.EditDataHub} element={<><AddDataHubs /></>} />
                  <Route path={ROUTES.DataPods} element={<DataContextHome />} />
                  <Route path={ROUTES.AddDataPod} element={<DataPodContext />} />
                  <Route path={ROUTES.DatapodsManageAccess} element={<DataContextManageAccess />} />

                  <Route path={ROUTES.Overview} element={<Overview />} />
                  <Route path={ROUTES.overviewSession} element={<Overview />} />

                  <Route
                    path={ROUTES.DataSystemProfiling}
                    element={
                      <>
                        <DatapodDetails />
                        <DataProfilingProcess />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataSourceModels}
                    element={
                      <>
                        <DatapodDetails />
                        <SourceDataModels />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataProfiling}
                    element={
                      <>
                        <DatapodDetails />
                        <DataProfiling />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataMapping}
                    element={
                      <>
                        <DatapodDetails />
                        <DataMapping />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModeling}
                    element={
                      <>
                        <DatapodDetails />
                        <DataModeling />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataQualityRecommendations}
                    element={
                      <>
                        <DatapodDetails />
                        <DataQualityRecommendation />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataProfilingViewResults}
                    element={
                      <>
                        <DatapodDetails />
                        <DataProfilingViewResults />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataMappingResults}
                    element={
                      <>
                        <DatapodDetails />
                        <DataMappingResults />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataMappingAttribues}
                    element={
                      <>
                        <DatapodDetails />
                        <DataMappingAttributes />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataMappingEditNewEntity}
                    element={
                      <>
                        <DatapodDetails />
                        <DataMappingEditNewEntity />
                      </>
                    }
                  ></Route>
                  <Route
                    path={ROUTES.DataMappingEditExistingRemap}
                    element={
                      <>
                        <DatapodDetails />
                        <DataMappingEditExistingRemap />
                      </>
                    }
                  ></Route>
                  <Route
                    path={ROUTES.DataModelingCanonicalModelFetchEntity}
                    element={
                      <>
                        <DatapodDetails />
                        <FetchEntity />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModelAddAttribute}
                    element={
                      <>
                        <DatapodDetails />
                        <CusomAttribute />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModelEditCustomAttribute}
                    element={
                      <>
                        <DatapodDetails />
                        <CusomAttribute />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModelAddDataQuality}
                    element={
                      <>
                        <DatapodDetails />
                        <DataQuality />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModelCombinedDataQuality}
                    element={
                      <>
                        <DatapodDetails />
                        <CombinedDataQualities />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalColumnProfileInfo}
                    element={
                      <>
                        <DatapodDetails />
                        <CanonicalColumnProfileResults />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalDataPreview}
                    element={
                      <>
                        <DatapodDetails />
                        <CanonicalDataView />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModelEditDataQuality}
                    element={
                      <>
                        <DatapodDetails />
                        <DataQuality />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingResults}
                    element={
                      <>
                        <DatapodDetails />
                        <DiscoverRelationships />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingEnterpriseModels}
                    element={
                      <>
                        <DatapodDetails />
                        <DataModelingEnterpriseModels />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingLakeMigrationModels}
                    element={
                      <>
                        <DatapodDetails />
                        <DataModelingLakeMigrationModels />
                      </>
                    }
                  />
                   <Route
                    path={ROUTES.ComparingMigrationFiles}
                    element={
                      <>
                        <DatapodDetails />
                        <ComparingMigrationFiles />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.CustomEnrichedModel}
                    element={
                      <>
                        <DatapodDetails />
                        <CustomEnrichedModel />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.EditCustomEnrichedModel}
                    element={
                      <>
                        <DatapodDetails />
                        <CustomEnrichedModel />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingEnterpriseEnrichedTables}
                    element={
                      <>
                        <DatapodDetails />
                        <CustomEnrichedModelTable />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingEntityRelationships}
                    element={
                      <>
                        <DatapodDetails />
                        <EntityRelationships />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingEntityReconciliation}
                    element={
                      <>
                        <DatapodDetails />
                        <EntityReconciliation />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingEntityDataFlow}
                    element={
                      <>
                        <DatapodDetails />
                        <EntityDataFlow />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModel}
                    element={
                      <>
                        <DatapodDetails />
                        <CanonicalModel />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingEnrichedDataPreview}
                    element={
                      <>
                        <DatapodDetails />
                        <EnrichedDataPreview />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.SourceToTargetMapping}
                    element={
                      <>
                        <DatapodDetails />
                        <SourceToTargetMapping />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingCanonicalModelAttributeDetails}
                    element={
                      <>
                        <DatapodDetails />
                        <CanonicalModelAttributeDetails />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataModelingAllTransformers}
                    element={
                      <>
                        <DatapodDetails />
                        <AllTransformers />
                      </>
                    }
                  />
                  {!environment.isAdvanceEnergy && (
                    <>
                      <Route
                        path={ROUTES.DataEngineeringResults}
                        element={
                          <>
                            <DatapodDetails />
                            <DataEngineeringResults />
                          </>
                        }
                      />
                      <Route
                        path={ROUTES.DataEngineering}
                        element={
                          <>
                            <DatapodDetails />
                            <DataEngineeringResults />
                          </>
                        }
                      />
                      <Route
                        path={ROUTES.DataEngineeringPipelineSchedules}
                        element={
                          <>
                            <DatapodDetails />
                            <DataEngineeringPipelineSchedules />
                          </>
                        }
                      />
                      <Route
                        path={ROUTES.DataEngineeringManageSchedules}
                        element={
                          <>
                            <DatapodDetails />
                            <DataEngineeringManageSchedules />
                          </>
                        }
                      />
                      <Route
                        path={ROUTES.DataEngineeringPipelineMonitoring}
                        element={
                          <>
                            <DatapodDetails />
                            <DataEngineeringPipelineMonitoring />
                          </>
                        }
                      />
                    </>
                  )}
                  <Route
                    path={ROUTES.DataLakeHome}
                    element={
                      <>
                        <DatapodDetails />
                        <DataLakeHome />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataLakeResults}
                    element={
                      <>
                        <DatapodDetails />
                        <DataLakeResults />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataAnalyticsHome}
                    element={
                      <>
                        <DatapodDetails />
                        <DataAnalyticsHome />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataAnalyticsGroupResults}
                    element={
                      <>
                        <DatapodDetails />
                        <AnalyticsGroupResults />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataAnalyticsMeasuresResults}
                    element={
                      <>
                        <DatapodDetails />
                        <AnalyticsMeasureResults />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.AddDataAnalyticsMeasuresResults}
                    element={
                      <>
                        <DatapodDetails />
                        <AddAnalyticsMeasure />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.DataAnalyticsMeasuresDataPreview}
                    element={
                      <>
                        <DatapodDetails />
                        <MeasuresDataPreview />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.Logs}
                    element={
                      <>
                        <DatapodDetails />
                        <TaskLogs />
                      </>
                    }
                  />
                  <Route
                    path={ROUTES.Documentation}
                    element={
                      <Grid item container alignItems="center">
                        {/* <ProfileContent onClick={() => {}} /> */}
                        <Typography gutterBottom>Documentation</Typography>
                      </Grid>
                    }
                  />
                  <Route
                    path={ROUTES.Milestoneplan}
                    element={
                      <Grid item container alignItems="center">
                        <Typography gutterBottom>Milestoneplan</Typography>
                      </Grid>
                    }
                  />
                  <Route
                    path={ROUTES.SystemAnaylsis}
                    element={
                      <Grid item xs={12} container alignItems="center">
                        {/* <Grid item xs={12}>
                        {' '}
                        <Typography variant="h1" gutterBottom>
                          Headline1
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h2" gutterBottom>
                          Headline2
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h3" gutterBottom>
                          Headline3
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h4" gutterBottom>
                          Headline4
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h5" gutterBottom>
                          Headline5
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="h6" gutterBottom>
                          Headline6
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="subtitle1" gutterBottom>
                          Subtitle1
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="subtitle2" gutterBottom>
                          Subtitel2
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="body1" gutterBottom>
                          Body1
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="body2" gutterBottom>
                          Body2
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="button" gutterBottom>
                          Button
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="caption" gutterBottom>
                          Caption
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Typography variant="overline" gutterBottom>
                          Overline
                        </Typography>
                      </Grid> */}
                      </Grid>
                    }
                  />
                  {isUserAdmin && (
                    <>
                      <Route path={ROUTES.Settings} element={<SettingsHome />} />
                      <Route path={ROUTES.ListServiceConnection} element={<ListServiceConnection />} />
                      <Route path={ROUTES.ManageConfiguration} element={<ManageConfiguration />} />
                      <Route path={ROUTES.ManageRoles} element={<ManageRoles />} />
                      <Route path={ROUTES.ManageVnetConfiguration} element={<VnetConfiguration />} />
                    </>
                  )}
                </Routes>
              </div>
            </Box>
          </div>
        </MainLayout>
      </PageLayout>
    </>
  )
}

export default App
