import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { AppProfileAttribute, AppProfileEntity, AttributeProfile, Entity, NewAttribute, EntityRelationship, RelationShipResponse} from '../../../businessObjects'
import { useEffect, useMemo, useState } from 'react'
import { environment } from '../../../utils/environment'
import { number, z } from 'zod'
import { ChatOpenAI } from 'langchain/chat_models/openai'
import { PromptTemplate } from 'langchain/prompts'
import { LLMChain } from 'langchain/chains'
import { StructuredOutputParser, OutputFixingParser } from 'langchain/output_parsers'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import { GridDeleteIcon } from '@mui/x-data-grid'
import InfoIcon from '@mui/icons-material/Info'
import { isValidDescription, isValidEntry } from '../../../utils/constants'
import { useParams } from 'react-router-dom'
import { useGetProfileAttibuteOutput } from '../../../hooks/dataProfilingHooks'
import { useGetDataAttributesByEntityId } from 'hooks/dataAttrbutesHooks'

type DeletePopUpProps = {
  showDeleteWarning: boolean
  confirmDeleteMap: () => Promise<void>
  closeDeleteWarning: () => void
  itemToDelete: any
  relationToDelete?:RelationShipResponse
}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
  entityID: string
  tableID: string
}

type DataMappingAttributesEditPopUpProps = {
  showPopUp: boolean
  onSubmit: () => Promise<void>
  closePopup: (close: boolean) => void
  editOptions: 'editContents' | 'editMapping' | 'newAttribute'
  setEditOptions: React.Dispatch<React.SetStateAction<'editContents' | 'editMapping' | 'newAttribute'>>
  selectedAttributeRow: AttributeProfile | undefined
  onChange: (name: string, value: unknown) => void
  changeBusinessKey: (value: boolean) => void
  setSelectAttributeId: React.Dispatch<React.SetStateAction<number | undefined>>
  newAttibute: NewAttribute | undefined
  onChangeNewAttribute: (name: string, value: string) => void
  attributeUpdated?:boolean
  setAttributeUpdated?: React.Dispatch<React.SetStateAction<boolean>>
  entityIdArgument?: number
}

type DataMappingResultsEditPopUpProps = {
  showPopUp: boolean
  onSubmit: () => void
  closePopup: (close: boolean) => void
  editOptions: 'editContents' | 'editMapping' | 'newEntity'
  setEditOptions: React.Dispatch<React.SetStateAction<'editContents' | 'editMapping' | 'newEntity'>>
  onChange: (name: string, value: string) => void
  selectedRow: AppProfileEntity | undefined
  selectNewEntityId: (entityId: string) => void
  selectedEntityId: number | undefined
  entities: Entity[]
  onChangeNewEntity: (name: string, value: string) => void
  newEntity: Entity | undefined
  contextPromptText: string
  setContextPromptText: React.Dispatch<React.SetStateAction<string>>
}

type DataMappingAttributesAddCustomAttributeProps = {
  showPopUp: boolean
  addAttributeSubmit: (dataColumnId: number | undefined) => Promise<boolean | undefined>
  closePopup: (close: boolean) => void
  newAttibute: NewAttribute | undefined
  onChangeNewAttribute: (name: string, value: string) => void
}

export const DeletePopUp = ({
  showDeleteWarning,
  confirmDeleteMap,
  closeDeleteWarning,
  itemToDelete,
  relationToDelete
}: DeletePopUpProps) => {
  return (
    <LucidPopUp
      openPopUp={showDeleteWarning}
      onConfirm={confirmDeleteMap}
      closePopup={closeDeleteWarning}
      headingText={
        <Typography variant="h4" gutterBottom>
          Are you sure, you want delete ?
        </Typography>
      }
      solidCancelButton
      confirmText="Confirm"
      cancelText="Cancel"
    >
      <Grid item xs={12} container justifyContent={'center'} alignItems={'stretch'} spacing={2}>
        <Grid item xs={1} />
        {itemToDelete && 
        <Grid item xs={2}>
          <Typography sx={{ fontStyle: 'oblique' }} variant="subtitle2" gutterBottom>
            {itemToDelete}
          </Typography>
        </Grid>}
        {relationToDelete && 
        <Grid item>
          <Typography sx={{ fontStyle: 'oblique' }} variant="subtitle2" gutterBottom textAlign={'left'}>
            {relationToDelete.leftEntityName}:{relationToDelete.leftAttributeName} {"===>"} {relationToDelete.rightEntityName}:{relationToDelete.rightAttributeName}
          </Typography>
        </Grid>}
        <Grid item xs={10} />
        <Grid item xs={9}>
          <Typography variant="body2">Deleting the selected will effect all its dependencies.</Typography>
        </Grid>
      </Grid>
    </LucidPopUp>
  )
}

export const DataMappingAttributesEditPopUp = ({
  showPopUp,
  onSubmit,
  closePopup,
  editOptions,
  setEditOptions,
  selectedAttributeRow,
  onChange,
  changeBusinessKey,
  setSelectAttributeId,
  newAttibute,
  onChangeNewAttribute,
  attributeUpdated,
  setAttributeUpdated,
  entityIdArgument,
}: DataMappingAttributesEditPopUpProps) => {
  const { dataPodId, dataSystemID, entityID, tableID } = useParams<ViewResultsParams>()
  const [reMapAttibId, setReMapAttribId] = useState<number>()
  
  const classifcationTypes = ['Sensitive Data' , 'Confidential Data', 'Public Data' , 'Internal Data', 'IIS']
  const dataTypes = ['String', 'Integer', 'Boolean']

  const { attributeProfiles, refetchAttributes} = useGetDataAttributesByEntityId(entityID ? Number(entityID) : entityIdArgument)

  const entityAttributes = useMemo(() => {
    if (!attributeProfiles?.length) return []
    return attributeProfiles.sort((p1, p2) => (p1.dataAttributeName > p2.dataAttributeName ? 1 : -1))
  }, [attributeProfiles])
  
  useEffect(()=>{
    if(attributeUpdated && setAttributeUpdated){
      refetchAttributes()
      setAttributeUpdated(false)
    }
  },[attributeUpdated])
  useEffect(() => {
    if (showPopUp && selectedAttributeRow?.id) {
      setReMapAttribId(selectedAttributeRow?.id)
    } else if(showPopUp && !selectedAttributeRow?.id) {
      setEditOptions('editMapping')
    }
  }, [showPopUp, selectedAttributeRow])

  const invalidEntries = useMemo(() => {
    if (editOptions === 'editContents') {
      return (
        !isValidDescription(selectedAttributeRow?.description) || !isValidEntry(selectedAttributeRow?.dataAttributeName)
      )
    }
    if (editOptions === 'newAttribute') {
      return (
        !isValidEntry(newAttibute?.dataAttributeName) ||
        !isValidDescription(newAttibute?.description) ||
        !newAttibute?.dataAttributeDataType ||
        !newAttibute.dataClassification
      )
    }
  }, [editOptions, selectedAttributeRow?.dataAttributeName, selectedAttributeRow?.description, newAttibute])

  const resetNewAttributes = () => {
    onChangeNewAttribute('dataAttributeName', '')
    onChangeNewAttribute('description', '')
    onChangeNewAttribute('dataAttributeDataType', '')
    onChangeNewAttribute('dataClassification', '')
  }

  const onRadioSelect = (radioType: unknown) => {
    const editType = radioType as typeof editOptions

    setEditOptions(editType)
    if (editType === 'newAttribute') {
      onChangeNewAttribute('dataClassification', classifcationTypes[0])
      onChangeNewAttribute('dataAttributeDataType', dataTypes[0])
      onChangeNewAttribute('isBusinessKey', 'false')
    } else {
      resetNewAttributes()
    }
  }

  const onClose = () => {
    resetNewAttributes()
    closePopup(!showPopUp)
  }
  return (
    <LucidPopUp
      openPopUp={showPopUp}
      onConfirm={onSubmit}
      closePopup={onClose}
      headingText={<Typography variant="h4">{'Edit and Fine-Tune'}</Typography>}
      confirmText="Save"
      showCloseIcon
      disabledConfirm={invalidEntries}
    >
      <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={2}>
        <Grid item xs={9}>
          <Typography color={'text.primary'} variant="subtitle2">
            <span style={{ color: 'red' }}>*</span> Indicates a required field
          </Typography>
        </Grid>
        {showPopUp && (
          <Grid item xs={9}>
            <FormControl>
              <FormLabel id="search By Label">Edit Options</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={editOptions}
                name="radio-buttons-group"
                onChange={({ target }) => onRadioSelect(target.value)}
              >
                {reMapAttibId && <FormControlLabel value={'editContents'} control={<Radio />} label={'Edit current Attribute content'} /> }
                <FormControlLabel value={'editMapping'} control={<Radio />} label={'Remap to existing Attributes'} />
                <FormControlLabel value={'newAttribute'} control={<Radio />} label={'New Attribute'} />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={10}>
          <Typography color={'text.primary'} variant="subtitle1">
            <span style={{ color: 'red' }}>*</span> Attribute Name
          </Typography>
        </Grid>
        <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={2}>
          {editOptions === 'editContents' && (
            <>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-select-industry"
                  placeholder="Attribute"
                  color="secondary"
                  size="small"
                  value={selectedAttributeRow?.dataAttributeName ?? ''}
                  error={!isValidEntry(selectedAttributeRow?.dataAttributeName)}
                  helperText={!isValidEntry(selectedAttributeRow?.dataAttributeName) ? 'Invalid Entry' : ''}
                  onChange={({ target }) => onChange('dataAttributeName', target.value)}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography color={'text.primary'} variant="subtitle1">
                  <span style={{ color: 'red' }}>*</span> Attribute Description
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-select-industry"
                  placeholder="Description"
                  color="secondary"
                  size="small"
                  rows={3}
                  multiline
                  value={selectedAttributeRow?.description ?? ''}
                  error={!isValidDescription(selectedAttributeRow?.description)}
                  helperText={!isValidDescription(selectedAttributeRow?.description) ? 'Invalid Entry' : ''}
                  onChange={({ target }) => onChange('description', target.value)}
                />
              </Grid>
              {/* <Grid item xs={10}>
                <Typography color={'text.primary'} variant="subtitle1">
                  <span style={{ color: 'red' }}>*</span> 
                  Is Business Key
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedAttributeRow?.isBusinessKey ?? false}
                      onChange={({ target }) => changeBusinessKey(target.checked)}
                    />
                  }
                  label={<>Business Key</>}
                  disableTypography
                />
              </Grid> */}
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Classification
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Attribute Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  select
                  onChange={({ target }) => onChange('dataClassification', target.value)}
                  value={selectedAttributeRow?.dataClassification ?? ''}
                >
                  {classifcationTypes.map((dtype) => (
                    <MenuItem key={dtype} value={dtype}>
                      {dtype}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          )}

          {editOptions === 'editMapping' && (
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                placeholder="Attribute"
                color="secondary"
                size="small"
                select
                value={reMapAttibId ?? 0}
                onChange={({ target }) => {
                  const tid = Number(target.value)
                  setSelectAttributeId(tid)
                  setReMapAttribId(tid)
                }}
              >
                {entityAttributes.map((pa) => (
                  <MenuItem key={pa.id} value={pa.id}>
                    {pa.dataAttributeName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {/* <Grid item xs={4}>
          <Button onClick={() => setIsNewAtrr(true)} startIcon={<Add />}>
            New Attribute
          </Button>
        </Grid> */}
        </Grid>

        <Grid item container xs={11} justifyContent={'center'} alignItems={'stretch'} spacing={1}>
          {editOptions === 'newAttribute' && (
            <>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-select-industry"
                  placeholder="Attribute"
                  color="secondary"
                  size="small"
                  value={newAttibute?.dataAttributeName ?? ''}
                  error={!isValidEntry(newAttibute?.dataAttributeName)}
                  helperText={!isValidEntry(newAttibute?.dataAttributeName) ? 'Invalid Entry' : ''}
                  onChange={({ target }) => onChangeNewAttribute('dataAttributeName', target.value)}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Attribute Description
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-select-industry"
                  placeholder="Description"
                  color="secondary"
                  size="small"
                  rows={3}
                  multiline
                  value={newAttibute?.description ?? ''}
                  error={!isValidDescription(newAttibute?.description)}
                  helperText={!isValidDescription(newAttibute?.description) ? 'Invalid Entry' : ''}
                  onChange={({ target }) => onChangeNewAttribute('description', target.value)}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> isBusinessKey
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-select-industry"
                  placeholder="Description"
                  color="secondary"
                  size="small"
                  select
                  multiline
                  value={newAttibute?.isBusinessKey ?? false}
                  onChange={({ target }) => onChangeNewAttribute('isBusinessKey', target.value)}
                >
                  <MenuItem value={'true'}>true</MenuItem>
                  <MenuItem value={'false'}>false</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={11}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Datatype
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Attribute Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  select
                  onChange={({ target }) => onChangeNewAttribute('dataAttributeDataType', target.value)}
                  value={newAttibute?.dataAttributeDataType ?? ''}
                >
                  {dataTypes.map((dtype) => (
                    <MenuItem key={dtype} value={dtype}>
                      {dtype}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={11}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Classification
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Attribute Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  select
                  onChange={({ target }) => onChangeNewAttribute('dataClassification', target.value)}
                  value={newAttibute?.dataClassification ?? ''}
                >
                  {classifcationTypes.map((dtype) => (
                    <MenuItem key={dtype} value={dtype}>
                      {dtype}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          )}
        </Grid>
        {/* {isNewAttr && (
        <Grid item xs={9} container spacing={1}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Attribute Name"
              variant="outlined"
              color="secondary"
              size="small"
              onChange={({ target }) => onChangeNewAttribute('dataAttributeName', target.value)}
              value={newAttibute?.dataAttributeName ?? ''}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Column Name"
              variant="outlined"
              color="secondary"
              size="small"
              onChange={({ target }) => onChangeNewAttribute('dataColumnName', target.value)}
              value={newAttibute?.dataColumnName ?? ''}
            />
          </Grid>

          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Attribute description"
              variant="outlined"
              color="secondary"
              size="small"
              onChange={({ target }) => onChangeNewAttribute('description', target.value)}
              value={newAttibute?.description ?? ''}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="outlined-basic"
              placeholder="Attribute Name"
              variant="outlined"
              color="secondary"
              size="small"
              select
              onChange={({ target }) => onChangeNewAttribute('dataAttributeDataType', target.value)}
              value={newAttibute?.dataAttributeDataType ?? ''}
            >
              {['string', 'int', 'boolean'].map((dtype) => (
                <MenuItem key={dtype} value={dtype}>
                  {dtype}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={saveNewAttribute} startIcon={<SaveOutlined />}>
              Save Entity
            </Button>
          </Grid>
        </Grid>
      )} */}

        {/* <Grid item xs={9}>
        <Typography color={'text.primary'}>
          <span style={{ color: 'red' }}>*</span> Attribute Description
        </Typography>
      </Grid> */}
        {/* <Grid item xs={9}>
        <TextField
          fullWidth
          id="outlined-basic"
          placeholder="description"
          variant="outlined"
          color="secondary"
          size="small"
          onChange={({ target }) => onChange('description', target.value)}
          value={selectedAttributeRow?.description ?? ''}
        />
      </Grid> */}

        {/* {isNewEntity && (
          <Grid item xs={9} container spacing={1}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="New Entity Name"
                variant="outlined"
                color="secondary"
                size="small"
                onChange={({ target }) => onChangeNewAttribute('dataEntityName', target.value)}
                value={newEntity?.dataEntityName ?? ''}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter description"
                variant="outlined"
                color="secondary"
                size="small"
                onChange={({ target }) => onChangeNewAttribute('description', target.value)}
                value={newEntity?.description ?? ''}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={saveNewEntityMap} startIcon={<SaveOutlined />}>
                Save Entity
              </Button>
            </Grid>
          </Grid>
        )} */}

        {/* <Grid item xs={9}>
        <Typography color={'text.primary'}>
          <span style={{ color: 'red' }}>*</span> Fine Tune
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <TextField
          fullWidth
          id="outlined-basic"
          helperText="Please provide a detailed context why you are making this change, so that the LLM model will remember this context for future processing"
          placeholder={'send a Prompt'}
          variant="outlined"
          color="secondary"
          rows={3}
          multiline
          value={contextPromptText}
          onChange={({ target }) => setContextPromptText(target.value)}
        />
      </Grid> */}
      </Grid>
    </LucidPopUp>
  )
}

export const DataMappingResultsEditPopUp = ({
  showPopUp,
  onSubmit,
  closePopup,
  editOptions,
  setEditOptions,
  onChange,
  selectedRow,
  selectNewEntityId,
  selectedEntityId,
  entities,
  onChangeNewEntity,
  newEntity,
  contextPromptText,
  setContextPromptText,
}: DataMappingResultsEditPopUpProps) => {
  const invalidEntries = useMemo(() => {
    if (editOptions === 'editContents') {
      return !isValidDescription(selectedRow?.description) || !isValidEntry(selectedRow?.dataEntityName)
    }
    if (editOptions === 'newEntity') {
      return !isValidEntry(newEntity?.dataEntityName) || !isValidDescription(newEntity?.description)
    }
    if (editOptions === 'editMapping') {
      return !isValidEntry(contextPromptText) || !selectedEntityId
    }
  }, [
    editOptions,
    selectedRow?.dataEntityName,
    selectedRow?.description,
    newEntity?.dataEntityName,
    newEntity?.description,
    contextPromptText,
    selectedEntityId,
  ])

  const resetNewEntity = () => {
    onChangeNewEntity('dataEntityName', '')
    onChangeNewEntity('description', '')
    setContextPromptText('')
    selectNewEntityId('')
  }

  const onRadioSelect = (radioType: unknown) => {
    const editType = radioType as typeof editOptions
    resetNewEntity()
    setEditOptions(editType)
  }

  const onClose = () => {
    resetNewEntity()
    closePopup(!showPopUp)
  }
  return (
    <LucidPopUp
      openPopUp={showPopUp}
      onConfirm={onSubmit}
      closePopup={onClose}
      headingText={<Typography variant="h4">Edit and Fine-Tune</Typography>}
      confirmText="Save"
      disabledConfirm={invalidEntries}
      showCloseIcon
    >
      <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
        <Grid item xs={10}>
          <Typography color={'text.primary'} variant="caption">
            <span style={{ color: 'red' }}>*</span> Indicates a required field
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography color={'text.primary'}>Table Name</Typography>
        </Grid>

        <Grid item xs={10}>
          <TextField
            fullWidth
            id="outlined-select-industry"
            placeholder="Table Name"
            color="secondary"
            size="small"
            disabled
            // rows={2}
            // multiline
            value={selectedRow?.dataTableName ?? ''}
            //onChange={({ target }) => onChange('dataSystemDesc', target.value)}
          />
        </Grid>

        <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
          {editOptions === 'editContents' && (
            <>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Entity Name
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="edit-current-entityName-id"
                  placeholder="Enter Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onChange={({ target }) => onChange('dataEntityName', target.value)}
                  value={selectedRow?.dataEntityName ?? ''}
                  error={!isValidEntry(selectedRow?.dataEntityName)}
                  helperText={!isValidEntry(selectedRow?.dataEntityName) ? ' Invalid Entry' : ''}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Entity Description
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="edit-entity-description-id"
                  placeholder="Enter Description"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  rows={3}
                  multiline
                  error={!isValidDescription(selectedRow?.description)}
                  helperText={!isValidDescription(selectedRow?.description) ? ' Invalid Entry' : ''}
                  onChange={({ target }) => onChange('description', target.value)}
                  value={selectedRow?.description ?? ''}
                />
              </Grid>
            </>
          )}
          {editOptions === 'editMapping' && (
            <>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Entity Name
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  placeholder="Enter Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  select
                  onChange={({ target }) => selectNewEntityId(target.value)}
                  value={selectedEntityId ?? 0}
                >
                  {entities.map((et) => (
                    <MenuItem key={et.id} value={et.id}>
                      {et.dataEntityName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </>
          )}
        </Grid>
        {editOptions === 'newEntity' && (
          <>
            <Grid item xs={10}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Entity Name
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="edit-new-entity-id"
                placeholder="New Entity Name"
                variant="outlined"
                color="secondary"
                size="small"
                onChange={({ target }) => onChangeNewEntity('dataEntityName', target.value)}
                value={newEntity?.dataEntityName ?? ''}
                error={!isValidEntry(newEntity?.dataEntityName)}
                helperText={!isValidEntry(newEntity?.dataEntityName) ? ' Invalid Entry' : ''}
              />
            </Grid>

            <Grid item xs={10}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Entity Description
              </Typography>
            </Grid>

            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Enter description"
                variant="outlined"
                color="secondary"
                size="small"
                rows={4}
                multiline
                onChange={({ target }) => onChangeNewEntity('description', target.value)}
                value={newEntity?.description ?? ''}
                error={!isValidDescription(newEntity?.description)}
                helperText={!isValidDescription(newEntity?.description) ? ' Invalid Entry' : ''}
              />
            </Grid>

            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEntity?.coreEntity}
                    onChange={() => onChangeNewEntity('coreEntity', `${!newEntity?.coreEntity}`)}
                  />
                }
                label={
                  <>
                    <Typography color={'text.primary'}>
                      {/* <span style={{ color: 'red' }}>*</span> */}
                      Is Core Entity
                    </Typography>
                  </>
                }
                disableTypography
              />
            </Grid>
          </>
        )}
        {editOptions === 'editMapping' && (
          <>
            <Grid item xs={10}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Fine Tune
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Please provide a detailed context why you are making this change, so that the LLM model will remember this context for future processing"
                variant="outlined"
                color="secondary"
                size="small"
                rows={3}
                multiline
                value={contextPromptText}
                error={!isValidDescription(contextPromptText)}
                helperText={!isValidDescription(contextPromptText) ? 'Enter valid Input' : ''}
                onChange={({ target }) => setContextPromptText(target.value)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </LucidPopUp>
  )
}

export const DataMappingAttributesAddCustomAttribute = ({
  showPopUp,
  addAttributeSubmit,
  closePopup,
  newAttibute,
  onChangeNewAttribute,
}: DataMappingAttributesAddCustomAttributeProps) => {
  // const steps = ['Add Attribute', 'Add Attribute Transformer (Optional)']
  // const [skipped, setSkipped] = useState(new Set<number>())
  const [generateQueryButton, setGenerateQueryButton] = useState<string>('Generate Query')
  const [userInput, setUserInput] = useState<string>('')
  const [queryContainer, setQueryContainer] = useState(false)
  const [query, setQuery] = useState('')
  const [attributeTypeOption, setAttributeTypeOption] = useState<'simpleAttribute' | 'compositeAttribute'>(
    'simpleAttribute',
  )
  const [selectedColumnId, setSelectedColumnId] = useState<number | undefined>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { dataPodId, dataSystemID, entityID, tableID } = useParams<ViewResultsParams>()
  const { profileAttributes } = useGetProfileAttibuteOutput(
    dataPodId,
    Number(dataSystemID),
    Number(entityID),
    Number(tableID),
    undefined,
    {  
      recordsPerPage: 1000,
      pageNumber: 1
    },
  )
  const entityAttributes = useMemo(() => {
    if (!profileAttributes?.length) return []
    return profileAttributes.sort((p1, p2) => (p1.dataAttributeName > p2.dataAttributeName ? 1 : -1))
  }, [profileAttributes])

  const outputParser = StructuredOutputParser.fromZodSchema(
    z
      .object({
        fields: z.object({
          query: z.string().describe(`generate pyspark code to concatenate attributes.`),
        }),
      })
      .describe(
        `An object which contains the generate pyspark code to concatenate attributes. Assume that user selects multitple values of compound attribute. For example, address is compound attribute combining street, city, country, Zip attributes.`,
      ),
  )

  const chatModel = new ChatOpenAI({
    temperature: 0,
    azureOpenAIApiKey: environment.azureOpenAIApi.key,
    azureOpenAIApiVersion: environment.azureOpenAIApi.version,
    azureOpenAIApiInstanceName: environment.azureOpenAIApi.instanceName,
    azureOpenAIApiDeploymentName: environment.azureOpenAIApi.deploymentName,
  })

  const outputFixingParser = OutputFixingParser.fromLLM(chatModel, outputParser)

  const prompt = new PromptTemplate({
    template: `Answer the user's question as best you can:\n{format_instructions}\n{query}`,
    inputVariables: ['query'],
    partialVariables: {
      format_instructions: outputFixingParser.getFormatInstructions(),
    },
  })

  const answerFormattingChain = new LLMChain({
    llm: chatModel,
    prompt,
    outputKey: 'records', // For readability - otherwise the chain output will default to a property named "text"
    outputParser: outputFixingParser,
  })

  const generateQuery = async (userQuestion: string) => {
    if (!userQuestion.trim()) return
    setQueryContainer(false)
    setGenerateQueryButton('Generating...')
    const queryString = userQuestion.replaceAll('@', ' ').split(' ').join(',')

    const response = await answerFormattingChain.call({
      query: `${queryString}`,
    })
    // console.log({ response })
    setQuery(response.records.fields.query)
    setGenerateQueryButton('Generate Query')
    setQueryContainer(true)
  }

  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }
  // console.log({ userInput })

  const handleSelectMention = (columnName: string) => {
    setUserInput(userInput.replace(/@$/, `@${columnName}`))
    setAnchorEl(null)
  }

  const onConfirmSave = async () => {
    const res = await addAttributeSubmit(selectedColumnId)
    if (res && res) {
      setUserInput('')
      setAttributeTypeOption('simpleAttribute')
      setSelectedColumnId(undefined)
      onCloseCustomAttribute()
    }
  }

  const columnMenuItems = useMemo(() => {
    if (!entityAttributes.length) return
    return entityAttributes.filter(
      (ea, ix) => entityAttributes.findIndex((ex) => ex.dataAttributeID === ea.dataAttributeID) === ix,
    )
  }, [entityAttributes.length])

  const onCloseCustomAttribute = () => {
    setUserInput('')
    setAttributeTypeOption('simpleAttribute')
    setSelectedColumnId(undefined)
    closePopup(false)
  }

  return (
    <LucidPopUp
      openPopUp={showPopUp}
      // onConfirm={onSubmit}
      closePopup={onCloseCustomAttribute}
      headingText={<Typography variant="h4">{'Add Custom Attribute'}</Typography>}
      // confirmText="Save"
      showCloseIcon
    >
      <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={2}>
        <Grid item container xs={11} justifyContent={'center'} alignItems={'stretch'} spacing={1}>
          <>
            <Grid item xs={11} marginBottom={2}>
              <Typography color={'text.primary'} variant="subtitle2">
                <span style={{ color: 'red' }}>*</span> Indicates a required field
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <FormControl>
                <FormLabel>
                  <Typography color={'text.primary'}>
                    <span style={{ color: 'red' }}>*</span> Attribute type
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={attributeTypeOption}
                  name="radio-buttons-group"
                  onChange={({ target }) => setAttributeTypeOption(target.value as typeof attributeTypeOption)}
                >
                  <FormControlLabel
                    value={'simpleAttribute'}
                    control={<Radio />}
                    label={'Simple Attribute'}
                    title="These cannot be divided any further. They represent the most basic attributes that are indivisible. For example, a customer's first name might be a simple attribute."
                  />
                  <FormControlLabel
                    value={'compositeAttribute'}
                    control={<Radio />}
                    label={'Composite Attribute'}
                    title="These can be divided into smaller sub-parts, which represent more basic attributes with independent meanings. For example, a full name attribute might be divided into first name and last name, or an address might be broken down into street, city, state, and ZIP code."
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={11}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Attribute Name
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                placeholder="Attribute"
                color="secondary"
                size="small"
                value={newAttibute?.dataAttributeName ?? ''}
                error={!isValidEntry(newAttibute?.dataAttributeName)}
                helperText={!isValidEntry(newAttibute?.dataAttributeName) ? 'Invalid Entry' : ''}
                onChange={({ target }) => onChangeNewAttribute('dataAttributeName', target.value)}
                required
              />
            </Grid>
            <Grid item xs={11}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Attribute Description
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                placeholder="Description"
                color="secondary"
                size="small"
                rows={3}
                multiline
                value={newAttibute?.description ?? ''}
                error={!isValidDescription(newAttibute?.description)}
                helperText={!isValidDescription(newAttibute?.description) ? 'Invalid Entry' : ''}
                onChange={({ target }) => onChangeNewAttribute('description', target.value)}
                required
              />
            </Grid>
            <Grid item xs={11}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> isBusinessKey
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                placeholder="Description"
                color="secondary"
                size="small"
                select
                multiline
                value={newAttibute?.isBusinessKey ?? false}
                onChange={({ target }) => onChangeNewAttribute('isBusinessKey', JSON.parse(target.value))}
                required
              >
                <MenuItem value={'true'}>true</MenuItem>
                <MenuItem value={'false'}>false</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={11}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Datatype
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Attribute Name"
                variant="outlined"
                color="secondary"
                size="small"
                select
                onChange={({ target }) => onChangeNewAttribute('dataAttributeDataType', target.value)}
                value={newAttibute?.dataAttributeDataType ?? ''}
                required
              >
                {['string', 'int', 'boolean'].map((dtype) => (
                  <MenuItem key={dtype} value={dtype}>
                    {dtype}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={11}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Classification
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="outlined-basic"
                placeholder="Attribute Name"
                variant="outlined"
                color="secondary"
                size="small"
                select
                onChange={({ target }) => onChangeNewAttribute('dataClassification', target.value)}
                value={newAttibute?.dataClassification ?? ''}
                required
              >
                {['internal', 'external'].map((dtype) => (
                  <MenuItem key={dtype} value={dtype}>
                    {dtype}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {attributeTypeOption === 'simpleAttribute' && (
              <Grid item xs={11}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Column Name
                </Typography>
              </Grid>
            )}
            {attributeTypeOption === 'simpleAttribute' && (
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  // placeholder="Attribute Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  select
                  // onChange={({ target }) => onChangeNewAttribute('dataClassification', target.value)}
                  onChange={({ target }) => setSelectedColumnId(Number(target.value))}
                  // value={newAttibute?.dataClassification ?? ''}
                  value={selectedColumnId ?? ''}
                  required
                >
                  {columnMenuItems?.map((data) => (
                    <MenuItem key={data.dataAttributeID} value={data.dataAttributeName}>
                      {data.dataColumnName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </>
          {attributeTypeOption === 'compositeAttribute' && (
            <>
              <Grid item xs={11} sx={{ marginTop: 2 }}>
                <TextField
                  label="Type @ to mention..."
                  value={userInput}
                  fullWidth
                  onChange={transformationTextChangeHandler}
                />
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                  {columnMenuItems?.map((ea) => (
                    <MenuItem key={ea.dataColumnId} onClick={() => {
                      handleSelectMention(ea.dataColumnName)
                      setAnchorEl(null)
                    }
                    }>
                      {ea.dataColumnName}
                    </MenuItem>
                  ))}
                </Menu>
                {/* <TextField
                label="Transformation Text"
                variant="outlined"
                multiline
                fullWidth
                onChange={transformationTextChangeHandler}
                // onChange={(e) => setUserInput(e.target.value)}
                // value={userInput}
                sx={{ marginBottom: 1 }}
              />
              {showColumnList && (
                <ul>
                  {entityAttributes.map((ea) => (
                    <li key={ea.dataColumnId} onClick={() => handleSelectMention(ea.dataColumnName)}>
                      {ea.dataColumnName}
                    </li>
                  ))}
                </ul>
              )} */}

                <TextField
                  label="Transformation Query"
                  variant="outlined"
                  multiline
                  fullWidth
                  // onChange={(e) => setQuery(e.target.value)}
                  // value={query}
                  sx={{ marginBottom: 1, marginTop: 4 }}
                />
              </Grid>
              <Grid item xs={11}>
                <TextField multiline rows={4} fullWidth label={'Prompt text'} />
              </Grid>
              <Grid item xs={11}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    generateQuery(userInput)
                  }}
                >
                  {generateQueryButton}
                </Button>
              </Grid>
            </>
          )}
          <Grid container item justifyContent={'end'} xs={6} marginTop={2}>
            <Button variant="contained" color="primary" fullWidth title="Save Custom Attribute" onClick={onConfirmSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LucidPopUp>
  )
}
