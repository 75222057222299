import React from 'react'
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  Alert,
} from '@mui/material'

import { useEffect, useMemo, useState } from 'react'
import { DataPodInfo, DataSystem, DataSystemInput, SubjectArea } from '../../businessObjects'
import NoEntries from '../../ui-components/NoEntries'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import DataTable from '../../ui-components/DataTable/DataTable'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import {
  useCreateDataSystem,
  useDeleteDataSystemById,
  useGetDataSystemTypes,
  useUpdateDataSystem,
} from '../../hooks/dataSystemHooks'
import { useQueryServiceConnections } from '../../hooks/serviceConnectionHooks'
import ServiceNameWithIcon from '../../ui-components/ServiceNameWithIcon'
import { isValidDescription, isValidEntry } from '../../utils/constants'
import { useGetAllDataTables } from '../../hooks/dataTableHooks'

type Props = {
  subjectAreas: SubjectArea[]
  refetchDataSystems: () => Promise<void>
  dataSystemsList: DataSystem[]
  //dataSystemInp?: DataSystemInput
  datapodId: string
  currentDataPodInfo: DataPodInfo
  deleteDataSystem: (deleteSystemId: number) => Promise<boolean>
  //handleChange: (datasys: DataSystemInput) => void
}

export const DataContextDataSystem = ({
  refetchDataSystems,
  dataSystemsList,
  subjectAreas,
  datapodId,
  currentDataPodInfo,
  deleteDataSystem,
}: Props) => {
  const [dataSystems, setDataSystems] = useState<DataTableRow[]>([])
  const [dataPodDataSystems, setDataPodDataSystems] = useState<DataSystem[]>([])
  const [editDataSystem, setEditDataSystem] = useState<DataSystem>()
  const [newDataSystem, setNewDataSystem] = useState<DataSystemInput>()
  const [dSubjAreas, setDSubjAreas] = useState<SubjectArea[]>([])
  const [editMode, setEditMode] = useState<boolean>(false)
  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const [deleteSystemId, setDeleteSystemId] = useState<number>()
  const [openPop, setOpenPop] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteError, setDeleteError] = useState<string>("")

  const [saveNewDataSystem] = useCreateDataSystem()
  const [updateDataSystem] = useUpdateDataSystem()
  const { dataTables } = useGetAllDataTables(deleteSystemId ? datapodId : undefined, deleteSystemId)
  //service connections by datapodId
  const { connections } = useQueryServiceConnections(datapodId, 'DS')

  const dataSystemColumns: DataTableColumn[] = [
    { label: 'Subject Area' },
    { label: 'Data System' },
    { label: 'Description' },
    { label: 'Data System Type' },
    { label: 'Service Connection Name' },
    { label: 'Actions' },
  ]

  const [systemType, setSystemType] = React.useState('Source')

  const { allDataSystemTypes } = useGetDataSystemTypes()

  const systemTypes = useMemo(() => {
    if (!allDataSystemTypes.length) return []
    return allDataSystemTypes
  }, [allDataSystemTypes])

  const defSysType = useMemo(() => {
    return allDataSystemTypes.length ? allDataSystemTypes.filter((syst) => syst.dataSystemType1 == systemType)[0].id : 0
  }, [allDataSystemTypes])

  const handleChange = (systTyp: unknown) => {
    setSystemType(systTyp as typeof systemType)
    const systemTypeId = systemTypes.filter((syst) => syst.dataSystemType1 == systTyp)[0].id

    onChange('dataSystemType', String(systemTypeId))
  }

  const saveDataSystem = async () => {
    setLoading(true)
    if (newDataSystem) {
      const dataSystemParams: DataSystemInput = {
        ...newDataSystem,
        industryID: currentDataPodInfo.industryId,
        dataSystemType: newDataSystem.dataSystemType ?? defSysType,
      }
      await saveNewDataSystem(datapodId, dataSystemParams)
      await refetchDataSystems()
      setLoading(false)
    }
  }

  const confirmUpdate = async () => {
    if (datapodId && editDataSystem && newDataSystem) {
      const toUpdate: DataSystem = {
        ...editDataSystem,
        subjectAreaId: newDataSystem.subjectAreaId,
        dataSystemName: newDataSystem.name,
        dataSystemDesc: newDataSystem.description,
        dataSystemType: newDataSystem.dataSystemType ?? defSysType,
        serviceConnectionId: parseInt(newDataSystem.serviceConnectionId),
      }
      await updateDataSystem(toUpdate, datapodId)
      await refetchDataSystems()
    }
  }

  useEffect(() => {
    if (dataSystemsList && subjectAreas.length) {
      setDataPodDataSystems(dataSystemsList)
      const tRows = dataSystemsList.map((sa) => ({
        id: `${sa.id}`,
        values: [
          subjectAreas.find((sb) => sb.id === sa.subjectAreaId)?.subjectAreaName ?? '',
          sa.dataSystemName,
          sa.dataSystemDesc,
          systemTypes.find((st) => st.id === sa.dataSystemType)?.dataSystemType1 ?? '',
          sa.serviceConnectionName,
        ],
      })) as DataTableRow[]
      setDataSystems(tRows)
    }
    if (subjectAreas) {
      setDSubjAreas(subjectAreas)
    }
  }, [dataSystemsList, subjectAreas, systemTypes])

  const onChange = (name: string, value: string | number) => {
    setNewDataSystem(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as DataSystemInput),
    )
  }

  const clickTableButton = (id: string, action: DataItemAction): void => {
    if (action === 'edit') {
      setEditMode(true)
      const editDS = dataPodDataSystems.find((ds) => ds.id === Number(id))
      setEditDataSystem(editDS)
      const editDataSystem: DataSystemInput = {
        name: editDS?.dataSystemName ?? '',
        subjectAreaId: editDS?.subjectAreaId ?? 0,
        container: editDS?.container ?? '',
        industryID: editDS?.industryId ?? 0,
        description: editDS?.dataSystemDesc ?? '',
        serviceConnectionId: editDS?.serviceConnectionId?.toString() ?? '',
        dataSystemType: editDS?.dataSystemType ?? 0,
      }
      const editSystemType = systemTypes.find((st) => st.id === editDS?.dataSystemType)?.dataSystemType1 ?? ''
      setSystemType(editSystemType)
      setNewDataSystem(editDataSystem)
      setOpenPop(true)
    }
    if (action === 'delete') {
      setDeleteSystemId(Number(id))
      setOpenWarning(true)
    }
  }

  const confirmSaveDataSystem = async () => {
    if (!editDataSystem) {
      await saveDataSystem()
    } else {
      confirmUpdate()
      setEditMode(false)
    }

    setOpenPop(false)
    setSystemType('Source')
  }

  const onClickAdd = () => {
    setNewDataSystem(undefined)
    setOpenPop(true)
  }

  const onClosePopUp = () => {
    setOpenPop(false)
    setNewDataSystem(undefined)
    setEditMode(false)
  }

  const confirmDelete = async () => {
    if (deleteSystemId) {
      try {
        const deleteSuccess = await deleteDataSystem(deleteSystemId)
        if (deleteSuccess) {
          setOpenWarning(false)
          const filteredRows = dataSystems.filter((ds) => ds.id !== String(deleteSystemId))
          setDataSystems(filteredRows)
          setDeleteSystemId(undefined)
          setDeleteError("")
        } else {
          setDeleteError(`Not allowed to Delete DataSystem with ID: ${deleteSystemId} as a Service Connection is already assigned!`)
        }
      } catch (error: any) {
        setDeleteError(error?.error || "Failed to delete Data System")
      }
    }
  }

  const closeDeleteWarning = () => {
    setDeleteSystemId(undefined)
    setOpenWarning(false)
    setDeleteError("")
  }

  const disableUpdateSubmit = useMemo(() => {
    return (
      !isValidDescription(newDataSystem?.description) ||
      !isValidEntry(newDataSystem?.name) ||
      !newDataSystem?.subjectAreaId ||
      !newDataSystem.serviceConnectionId
    )
  }, [newDataSystem])

  return (
    <>
      <LucidPopUp
        openPopUp={openWarning}
        closePopup={closeDeleteWarning}
        headingText={
          <Typography variant="h4" color={'error'}>
            Area you sure to delete?
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={dataTables?.some((ds) => ds.dataSystemId === deleteSystemId)}
        onConfirm={confirmDelete}
      >
        <Typography>
          Delete will effect all dependencies. Please delete ProfileEntities or DataTables before proceeding this
          action.
        </Typography>
        {deleteError && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Alert 
                severity="error"
                sx={{ 
                  '& .MuiAlert-message': { 
                    wordBreak: 'break-word' 
                  } 
                }}
              >
                {deleteError}
              </Alert>
            </Grid>
          )}
      </LucidPopUp>
      <Grid item container spacing={2}>
        {!dataSystems.length ? (
          <NoEntries
            onClick={() => setOpenPop(true)}
            headingText="No Data Source Created"
            bodyText="Data Sources in the context of the selected subject areas refer to specific database systems or operational data source software responsible for managing the data related to each subject area. Each data source is identified by the name of the database or software utilized to store and handle the respective data "
            buttonText="Add New data source"
          />
        ) : (
          <Grid item container spacing={2} sx={{ mb: 2, mt: 2 }}>
            <Grid item container spacing={2}>
              <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <Button variant="contained" onClick={onClickAdd}>
                  {'Add New data source'}
                </Button>
              </Grid>
              <DataTable
                columns={dataSystemColumns}
                rows={dataSystems}
                allowedActions={{ editable: true, deletable: true }} //sortable: true  when added the table does not reload.
                onButtonClicked={clickTableButton}
              />
            </Grid>
          </Grid>
        )}

        <LucidPopUp
          openPopUp={openPop}
          onConfirm={confirmSaveDataSystem}
          closePopup={onClosePopUp}
          headingText={
            <Typography variant="h1" alignContent="left">
              {!editMode ? 'Add New Data Source' : 'Update Data Source'}
            </Typography>
          }
          confirmText="Save"
          disabledConfirm={disableUpdateSubmit}
          showCloseIcon={true}
        >
          <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 12 }} color={'text.primary'}>
                * Indicates a required field
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>Data Source Name</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                select
                label="Select Domain Info"
                color="secondary"
                size="small"
                value={newDataSystem?.subjectAreaId ?? 0}
                onChange={({ target }) => onChange('subjectAreaId', target.value)}
                required
              >
                {dSubjAreas.map((subjArea) => (
                  <MenuItem key={subjArea.id} value={subjArea.id}>
                    {subjArea.subjectAreaName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>Name</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Name"
                variant="outlined"
                required
                color="secondary"
                size="small"
                onChange={({ target }) => onChange('name', target.value)}
                value={newDataSystem?.name ?? ''}
                error={!isValidEntry(newDataSystem?.name)}
                helperText={!isValidEntry(newDataSystem?.name) ? 'Invalid Name' : ''}
                aria-required
              />
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>* Description</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter description"
                variant="outlined"
                color="secondary"
                required
                rows={4}
                multiline
                value={newDataSystem?.description ?? ''}
                onChange={({ target }) => onChange('description', target.value)}
                error={!isValidDescription(newDataSystem?.description)}
                helperText={!isValidDescription(newDataSystem?.description) ? 'Invalid Entry' : ''}
              />
            </Grid>
            {/* <Grid item xs={9}>
              <Typography color={'text.primary'}>Container</Typography>
            </Grid> */}
            {/* <Grid item xs={9}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter container Name"
                variant="outlined"
                color="secondary"
                size="small"
                onChange={({ target }) => onChange('container', target.value)}
                value={newDataSystem?.container ?? ''}
                error={!isValidDescription(newDataSystem?.container)}
                required
              />
            </Grid> */}
            <Grid item xs={9}>
              <Typography color={'text.primary'}>Service Connection</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="outlined-select-industry"
                select
                label="Select Service Connection Name"
                color="secondary"
                size="small"
                value={newDataSystem?.serviceConnectionId ?? ''}
                onChange={({ target }) => onChange('serviceConnectionId', target.value)}
                error={!isValidDescription(newDataSystem?.serviceConnectionId)}
                required
              >
                {connections.map((con) => (
                  <MenuItem key={con?.id} value={con?.id?.toString()}>
                    {con?.connectionName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={9}>
              <FormControl>
                <FormLabel id="radio-buttons-group-label">System Type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={systemType}
                  onChange={({ target }) => handleChange(target.value)}
                >
                  <FormControlLabel value="Source" control={<Radio />} label="Source" />
                  <FormControlLabel value="Target" control={<Radio />} label="Target" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </LucidPopUp>
      </Grid>
    </>
  )
}
