import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAxiosRequestConfig } from '../apiServices/apiserviceHelpers'
import { appInsights } from '../utils/ApplicationInsightsService'
import { environment } from 'utils/environment'
// import { environment } from '../utils/environment'

/** @POST: requires input params 
@PUT:  requires input params */

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

/** @POST: requires input params 
@PUT:  requires input params */
export const sendLocalLLmRequests = async <T>(
  endPoint: string,
  reqType: HttpMethod,
  params?: T,
): Promise<AxiosResponse<any, any>> => {
//   const config = {
//     headers: {
//       Authorization: `${token}`,
//       'Content-Type': 'application/json',
//     },
//   } as AxiosRequestConfig
  // const config = getAxiosRequestConfig(token)
  let resp: AxiosResponse<any, any>
  axios.interceptors.request.use((req) => {
    // appInsights.trackEvent({
    //   name: 'request',
    //   properties: { req },
    // })
    // Important: request interceptors **must** return the request.
    return req
  })
  // axios.interceptors.response.use((res) => {
  //   // Important: response interceptors **must** return the response.
  //   appInsights.trackTrace({
  //     message: 'response',
  //     properties: { res },
  //   })
  //   return res
  // })
    // const reqUrl = 'http://localhost:8000/' +endPoint
  const reqUrl = environment.selfHostedLlm  + endPoint
  // console.log("Environment Variable:", process.env.REACT_SELF_HOSTED_LLM);
  // console.log({reqUrl})
  // const reqUrl = "https://fastapi-contaierapp1.ashydune-4ca7a352.canadacentral.azurecontainerapps.io/" + endPoint
  // const reqUrl = 'https://fastapi-contaierapp1.ashydune-4ca7a352.canadacentral.azurecontainerapps.io/'  + endPoint
  // const reqUrl = 'http://4.174.136.57:8000/' +endPoint
  // const reqUrl = 'http://20.63.17.62:8000/' +endPoint
  // const reqUrl = 'http://localhost:8000/' +endPoint
  switch (reqType) {
    case 'GET':
      resp = await axios.get(reqUrl)
      break
    case 'POST':
        console.log("comes here", {params})
        console.log(reqUrl)
      resp = await axios.post(reqUrl, params)
      break
    case 'PUT':
      resp = await axios.put(reqUrl, params)
      break
    case 'DELETE':
      resp = await axios.delete(reqUrl)
      break
    default:
      return new Promise(() => undefined)
  }
  // appInsights.trackEvent({
  //   name: 'response',
  //   properties: { resp },
  // })
  //const { request, data } = resp
  return resp
}
