import { Grid, Typography, Button, TextField, MenuItem } from '@mui/material'
import { AppProfileAttribute, DataAttribute, Entity, GenAiGenerateMappingDetials, NewAttribute, NewAttributeMapBody, NewEntityMapBody } from 'businessObjects';
import { useGetProfileAttibuteOutput } from 'hooks/dataProfilingHooks';
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ActionPage from 'ui-components/ActionPage'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { ROUTES } from 'Routes/constants';
import { useAddNewEntityMap, useDeleteEntityMap, useGetEntity, useGetEntityByDataPodId } from 'hooks/entityHooks';
import { LoadingButton } from '@mui/lab';
import { isValidEntry, isValidDescription } from 'utils/constants';
import { useGenerateAttributeTypes, useGetGenAiEditMappings } from 'hooks/genAIApiHooks';
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable';
import { DataTableRow } from 'ui-components/DataTable/DataTableTypes';
import { useAddttributeMap } from 'hooks/attributeProfileHooks';
import { useAddNewDataAttribute, useGetAttributeByName } from 'hooks/dataAttrbutesHooks';
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp';

type ViewResultsParams = {
    dataPodId: string;
    dataSystemID: string;
    entityID: string;
    dataTableId: string;
};
type AttributeUserEdited = {
    attributeName: string,
    attributeDescription: string
}
type AttributeOptions = {
    attributeName: string,
    attributeDescription: string
}
export const DataMappingEditExistingRemap = () => {
    const navigate = useNavigate();
    const { dataPodId, dataSystemID, entityID, dataTableId } = useParams<ViewResultsParams>();
    const [newEntity, setNewEntity] = useState<Entity>();
    const [dataTableName, setDataTableName] = useState<string>('');
    const [currentEntityName, setCurrentEntityName] = useState<string>('');
    const { profileAttributes, refetch } = useGetProfileAttibuteOutput(
        dataPodId,
        Number(dataSystemID),
        Number(entityID),
        Number(dataTableId),
        undefined,
        {  
            recordsPerPage: 1000,
            pageNumber: 1
          },
    )
    const [attributeMaps, setAttributeMaps] = useState<AppProfileAttribute[]>()
    const [entity] = useGetEntity({}, dataPodId, Number(entityID))
    const [loading, setLoading] = useState<boolean>(false)
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
    const [genAIGeneratedData, setGenAiGeneratedData] = useState<GenAiGenerateMappingDetials>()
    const [attributeOptions, setAttributeOptions] = useState<any>()
    const [attributeDescriptionOptions, setAttributeDescriptionOptions] = useState<any>()
    const { fetchAttributesForExistingEntityRemap } = useGetGenAiEditMappings()
    const [entities] = useGetEntityByDataPodId(dataPodId, false)
    const [selectedRow, setSelectedRow] = useState<DataTableRow>()
    const [initialRows, setInitialRows] = useState<DataTableRow[]>()
    const [genAiRows, setGenAiRows] = useState<DataTableRow[]>()
    const [editing, setEditing] = useState<boolean>(false)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [atleastOneEdit, setAtleastOneEdit] = useState<boolean>(false)
    const [userEditedAttribute, setUserEditedAttribute] = useState<AttributeUserEdited>()
    const { deleteEntityMapId } = useDeleteEntityMap()
    const [addNewEntityMap, newEntityMap, createError] = useAddNewEntityMap()
    const { addAttributeMapFunc } = useAddttributeMap(dataPodId)
    const [newAttributeFunc] = useAddNewDataAttribute()
    const { fetchAttributeTypes } = useGenerateAttributeTypes()
    const [completedWholeSteps, setCompletedWholeSteps] = useState<boolean>(false)
    const [generatedOnce, setGeneratedOnce] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [errorInTableRows, setErrorInTableRows] = useState<number>()
    const [dataDictionary, setDataDictionary] = useState<any>()
    const { dataAttributeNameValidation } = useGetAttributeByName()
    const [saveButtonClick, setSaveButtonClick] = useState<boolean>(false)
    useEffect(() => {
        if (profileAttributes?.length) {
            setAttributeMaps(profileAttributes)
            setDataTableName(profileAttributes[0].dataTableName);
            setCurrentEntityName(profileAttributes[0].dataEntityName);
        } else if (entity.length) {
            setCurrentEntityName(entity[0].dataEntityName)
        }
    }, [profileAttributes]);
    useEffect(() => {
        if (newEntity && newEntity?.dataEntityName.length) {
            if (newEntity.description && newEntity.description.length) {
                setDisableSubmit(false)
            } else {
                setDisableSubmit(true)
            }
        } else {
            setDisableSubmit(true)
        }
    }, [newEntity])
    useEffect(() => {
        if (completedWholeSteps) {
            setNewEntity(undefined)
            setAtleastOneEdit(false)
            setGenAiGeneratedData(undefined)
            setErrorInTableRows(undefined)
            refetch(newEntity?.id)
        }
    }, [completedWholeSteps == true])
    const onClickBack = (event: any): void => {
        navigate(generatePath(ROUTES.DataProfilingViewResults, { dataPodId: dataPodId, dataSystemID: dataSystemID }));
    };
    const onChangeNewEntity = (name: string, value: string) => {
        const entity = entities.find((ent) => ent.dataEntityName === value)
        setNewEntity(entity)
        if (generatedOnce && genAIGeneratedData && genAIGeneratedData.attributes.length) {
            setGenAiGeneratedData(undefined)
            setErrorMessage('')
            setAtleastOneEdit(false);
            refetch()
            setGeneratedOnce(false)
        }
        // setNewEntity((prv) => ({ ...prv, [name]: value } as Entity));
    };
    const handleSubmit = async () => {
        setGenAiGeneratedData(undefined)
        setGeneratedOnce(false)
        setLoading(true)
        if (
            dataPodId &&
            dataSystemID &&
            dataTableId &&
            newEntity?.id
        ) {
            // console.log({ newEntity })
            const resp = await fetchAttributesForExistingEntityRemap(
                dataPodId,
                Number(dataSystemID),
                Number(dataTableId),
                newEntity?.id,
            )
            if (resp) {
                formatGenAIGeneratedData(resp)
                setGenAiGeneratedData(resp)
                setGeneratedOnce(true)
                setLoading(false)
            }
        }
    };

    const formatGenAIGeneratedData = (resp: GenAiGenerateMappingDetials) => {
        const genAiGeneratedData = resp;

        if(profileAttributes?.length){
            const attributeDictionary = profileAttributes.reduce((acc, attribute) => {
                const columnName = attribute.dataColumnName;
                acc[columnName] = {
                    dataColumnId: attribute.dataColumnId,
                    dataAttributeName: attribute.dataAttributeName,
                    dataAttributeDescription: attribute.dataAttributeDescription,
                };

                const genAiColumnIndex = genAiGeneratedData.columns.findIndex(col => col === columnName);

                if (genAiColumnIndex !== -1) {
                    acc[columnName].newAttribute = genAiGeneratedData.attributes[genAiColumnIndex];
                    acc[columnName].newAttributeDescription = genAiGeneratedData.attribute_description[genAiColumnIndex];
                }

                return acc;
            }, {} as { [key: string]: { dataColumnId: number; dataAttributeName: string; dataAttributeDescription: string; newAttribute?: string; newAttributeDescription?: string } });
            
            setDataDictionary(attributeDictionary)
        }
    }
    
    const leftTableColumns = useMemo(() => {

        if (genAIGeneratedData && genAIGeneratedData.columns.length) {
            // console.log("Comes in here")
            return [
                { label: 'Column Name' },
                { label: 'Current Attribute Name' },
                { label: 'Current Attribute Description' },
                { label: 'New Attribute Name', isTextField: true },
                { label: 'New Attribute Description', isTextField: true },
                { label: 'EDIT' }
            ]
        } else {
            return [
                { label: 'Column Name' },
                { label: 'Current Attribute Name' },
                { label: 'Current Attribute Description' }
            ];
        }
    }, [genAIGeneratedData])
    const leftRows = useMemo(() => {

        if (profileAttributes && profileAttributes.length && !genAIGeneratedData?.columns.length) {
            const dataTableRows = profileAttributes.map((tl) => ({
                id: `${tl.dataColumnId}`,
                values: [
                    tl.dataColumnName,
                    tl.dataAttributeName,
                    tl.dataAttributeDescription,
                ]
            })) as DataTableRow[];
            setInitialRows(dataTableRows)
            return dataTableRows;
        }

        if (genAIGeneratedData?.columns.length && initialRows?.length && !atleastOneEdit) {
            let attributeOptionsArray: { [key: string]: AttributeOptions } = {}
            let attributeDescriptionOptionsArray: { [key: string]: AttributeOptions } = {}
            const combinedRows = initialRows.map((row, index) => {
                const columnIndex = genAIGeneratedData.columns.indexOf(row.values[0]);
                if (columnIndex !== -1) {
                    return {
                        ...row,
                        values: [
                            row.values[0],
                            row.values[1],
                            row.values[2],
                            dataDictionary[row.values[0]].newAttribute.replace(/\s/g, ''),
                            dataDictionary[row.values[0]].newAttributeDescription.replace(/\s/g, ''),
                            // genAIGeneratedData.attributes[index].replace(/\s/g, ''),
                            // genAIGeneratedData.attribute_description[index],
                            'EDIT',
                        ]
                    };
                }
                return row;
            });
        
            setAttributeOptions(attributeOptionsArray);
            setAttributeDescriptionOptions(attributeDescriptionOptionsArray);
            setInitialRows(combinedRows);
            setGenAiRows(combinedRows);
            return combinedRows;
        }
        if (atleastOneEdit && initialRows?.length  && selectedRow && userEditedAttribute && saveButtonClick) {
            const updatedRows = initialRows?.map(row => {
                if (row.id === selectedRow.id) {
                    const newValues = [...row.values];
                    newValues[3] = userEditedAttribute ? userEditedAttribute.attributeName.replace(/\s/g, '') : '';
                    newValues[4] = userEditedAttribute ? userEditedAttribute.attributeDescription : '';

                    return {
                        ...row,
                        values: newValues
                    };
                }
                return row;
            });
            setInitialRows(updatedRows);
            setSelectedRow(undefined);
            return updatedRows;
        } else if (initialRows) {
            return initialRows;
        }

        return [];
    }, [profileAttributes, genAIGeneratedData, editing == true]);
    const inactiveExistingMapping = async () => {
        // console.log("Inside inactive existing mapping")
        if (attributeMaps?.length) {
            const entityMapFlag = await deleteEntityMapId(attributeMaps[0].dataEntityMapId)
            // const entityMapFlag = true
            if (entityMapFlag) {
                return true
            }
            // if (attributeMaps?.length && entityMapFlag) {
            // let count = 0
            // attributeMaps?.forEach(async (attr, idx) => {
            //     // console.log(attr.dataAttributeMapID)
            //     // console.log(idx)
            //     const temp = await deleteAttributeMapId(attr.dataAttributeMapID)
            //     if (temp) {
            //         count++
            //     }
            // })
            // console.log(count)
            // console.log(attributeMaps.length)
            // if (count == attributeMaps.length-1) {
            //     console.log("Equal number of maps where deleted")
            //     return true
            // } else {
            //     console.log("Does not match the lengths")
            //     return true
            // }
            // }
        } else {
            // console.log("There is no entity mapping")
            return true
        }
    }
    // const addNewEntityAttributes = async () => {
    //     if (newEntity && dataPodId) {
    //         // console.log({ entityResp })
    //         if (newEntity && newEntity.id && genAIGeneratedData && initialRows?.length) {
    //             // console.log("Entity is added")
    //             // console.log(entityResp)
    //             const entityCreatedId = newEntity.id
    //             const nEntMap: NewEntityMapBody = {
    //                 dataEntityId: entityCreatedId,
    //                 dataTableId: Number(dataTableId),
    //                 runId: 0,
    //                 profileName: 'generated',
    //             }
    //             // console.log({ nEntMap })
    //             const resp = await addNewEntityMap(nEntMap, dataPodId)
    //             // console.log(resp?.flag)
    //             // console.log(resp?.data)
    //             if (resp?.flag) {
    //                 let count = 0
    //                 genAIGeneratedData.attributes.forEach(async (attribute, index) => {
    //                     if (index < genAIGeneratedData.columns.length && index < genAIGeneratedData.dataClassification.length) {
    //                         const sourceColumn = genAIGeneratedData.columns[index]
    //                         const initialRow = initialRows.find((ir) => ir.values[0] === sourceColumn)
    //                         if (initialRow) {
    //                             // console.log({ initialRow })
    //                             // console.log({ index })
    //                             const attributeName = initialRow?.values[3].replace(/\s/g, '')
    //                             const attributeDescription = initialRow?.values[4];
    //                             const attributeDataClasification = genAIGeneratedData.dataClassification[index]
    //                             // console.log(`Attribute: ${attributeName}, Description: ${attributeDescription}`);
    //                             const newAttributeBody: NewAttribute = {
    //                                 dataEntityId: entityCreatedId,
    //                                 dataAttributeName: attributeName,
    //                                 dataAttributeDataType: '',
    //                                 description: attributeDescription,
    //                                 isBusinessKey: false,
    //                                 dataClassification: attributeDataClasification,
    //                                 dataAttributeType: ''

    //                             }
    //                             console.log({ newAttributeBody })
    //                             const attributeResponse = await newAttributeFunc(newAttributeBody, dataPodId)
    //                             if (attributeResponse) {
    //                                 // console.log("Inside the if of the newly created attributes")
    //                                 const newAttributeMapBody: NewAttributeMapBody = {
    //                                     dataTableId: Number(dataTableId),
    //                                     dataEntityId: newEntity.id,
    //                                     dataAttributeId: attributeResponse.id,
    //                                     runid: 0,
    //                                     dataColumnId: Number(initialRow.id),
    //                                     dataEntityMapId: resp.data.id,
    //                                 }
    //                                 console.log({ newAttributeBody })
    //                                 const atrrResp = await addAttributeMapFunc(newAttributeMapBody)
    //                                 if (atrrResp && atrrResp.id) {
    //                                     // console.log('Map is generated')
    //                                     count += 1
    //                                 }
    //                             }
    //                         }
    //                     } else {
    //                         // console.log(`Attribute: ${attribute}, Description: Not available`);
    //                     }
    //                 });
    //                 // console.log(genAIGeneratedData.columns.length)
    //                 // console.log(count)
    //                 // if (count === genAIGeneratedData.columns.length) {
    //                 //     console.log("No chance of duplicates")
    //                 // } else {
    //                 //     console.log("Chance of duplicates")
    //                 // }
    //                 const attributeTypeResp = await fetchAttributeTypes(dataPodId, newEntity.id, Number(dataTableId))
    //                 if (attributeTypeResp) {
    //                     console.log("Types have been generated")
    //                     setCompletedWholeSteps(true)
    //                 }
    //             } else {
    //                 // console.log("Entity Map was not added")
    //             }
    //         }
    //         return true
    //     } else {
    //         // console.log("something failed")
    //         return false
    //     }
    // }
    const addNewEntityAttributes = async () => {
        if (newEntity && dataPodId) {
            if (newEntity.id && genAIGeneratedData && initialRows?.length) {
                const entityCreatedId = newEntity.id;
                const nEntMap: NewEntityMapBody = {
                    dataEntityId: entityCreatedId,
                    dataTableId: Number(dataTableId),
                    runId: 0,
                    profileName: 'generated',
                };
                const resp = await addNewEntityMap(nEntMap, dataPodId);

                if (resp) {
                    let count = 0;
                    await Promise.all(genAIGeneratedData.attributes.map(async (attribute, index) => {
                        if (index < genAIGeneratedData.columns.length && index < genAIGeneratedData.dataClassification.length) {
                            const sourceColumn = genAIGeneratedData.columns[index];
                            const initialRow = initialRows.find((ir) => ir.values[0] === sourceColumn);
                            if (initialRow) {
                                const attributeName = initialRow.values[3].replace(/\s/g, '');
                                const attributeDescription = initialRow.values[4];
                                const attributeDataClassification = genAIGeneratedData.dataClassification[index];

                                const newAttributeBody: NewAttribute = {
                                    dataEntityId: entityCreatedId,
                                    dataAttributeName: attributeName,
                                    dataAttributeDataType: '',
                                    description: attributeDescription,
                                    isBusinessKey: false,
                                    dataClassification: attributeDataClassification,
                                    dataAttributeType: ''
                                };

                                const attributeValidation = await dataAttributeNameValidation(dataPodId, attributeName, newEntity.id);
                                let attributeData: DataAttribute;
                                // console.log({ attributeValidation })
                                if (attributeValidation?.exist === true && attributeValidation.dataAttribute?.id) {
                                    // console.log(attributeValidation.dataAttribute)
                                    attributeData = attributeValidation.dataAttribute

                                } else {
                                    const attributeResponse = await newAttributeFunc(newAttributeBody, dataPodId)
                                    if (attributeResponse) {
                                        attributeData = attributeResponse
                                    } else {
                                        console.log("Attribute creation failed")
                                        return
                                    }
                                }
                                // console.log({attributeData})
                                if (attributeData) {
                                    // console.log(attributeData)
                                    const newAttributeMapBody: NewAttributeMapBody = {
                                        dataTableId: Number(dataTableId),
                                        dataEntityId: newEntity.id,
                                        dataAttributeId: attributeData.id,
                                        runid: 0,
                                        dataColumnId: Number(initialRow.id),
                                        dataEntityMapId: resp.data.id,
                                    };
                                    const atrrResp = await addAttributeMapFunc(newAttributeMapBody);
                                    if (atrrResp && atrrResp.id) {
                                        count += 1;
                                    }
                                }
                            }
                        }
                    }));

                    const attributeTypeResp = await fetchAttributeTypes(dataPodId, newEntity.id, Number(dataTableId));
                    if (attributeTypeResp) {
                        // console.log("Types have been generated");
                        setCompletedWholeSteps(true);
                    }
                } else {
                    console.error("Entity Map was not added");
                }
            }
            return true;
        } else {
            console.error("Failed to add new entity attributes");
            return false;
        }
    };

    const checkDuplicates = (rows: DataTableRow[]): boolean => {
        const seen: Set<string> = new Set();

        for (const row of rows) {
            const valueAtIndex3 = row.values[3];
            const valueAtIndex4 = row.values[4];

            if (seen.has(valueAtIndex3) || seen.has(valueAtIndex4)) {
                // console.log("Values are duplicate ,", row.id)
                setErrorInTableRows(Number(row.id))
                return false;
            }

            seen.add(valueAtIndex3);
            seen.add(valueAtIndex4);
        }
        setErrorInTableRows(undefined)
        return true;
    };


    const pageConfirm = async () => {
        // console.log("inside page confirm")
        // console.log
        setErrorMessage('')
        if (initialRows?.length) {
            const validation = checkDuplicates(initialRows)
            // console.log(validation)
            if (!validation) {
                setConfirmLoading(false)
                setAtleastOneEdit(false);
                setErrorMessage('Duplicate attributes selected')
                return
            } else {
                setConfirmLoading(true)
                if (dataPodId && newEntity && newEntity.dataEntityName) {
                    // console.log({tempFlag})
                    const mapInactiveFlag = await inactiveExistingMapping()
                    if (mapInactiveFlag) {
                        const entityAttributeFlag = await addNewEntityAttributes()
                        if (entityAttributeFlag) {
                            console.log("Successfully created attributes and their maps ,along with the entity")
                            setConfirmLoading(false)
                        }
                    }
                }
            }
        }
    }
    const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
        if (actionType === ShadowTableAction.Editable) {
            const selectedRow = leftRows.find((lr) => lr.id === rowId);
            if (selectedRow) {
                // console.log(selectedRow);
                const attributeName = selectedRow.values[3].replace(/\s/g, '') ?? '';
                const attributeDescription = dataDictionary[selectedRow.values[0]].newAttributeDescription ?? '';

                setSelectedRow({
                    ...selectedRow,
                    values: selectedRow.values.map(value => value ?? ''),
                });

                const tempAttr: AttributeUserEdited = {
                    attributeName,
                    attributeDescription,
                };

                setUserEditedAttribute(tempAttr);
                setEditing(true);
            }
        }
    };
    const closeEditingPopup = () => {
        // console.log('Click on close popup')
        setEditing(false)
        setSaveButtonClick(false)

    }
    const saveChanges = () => {
        setSaveButtonClick(true)
        setEditing(false)
    }
    const onChangeNewAttribute = (name: string, value: string) => {
        setAtleastOneEdit(true);
        setUserEditedAttribute((prev: AttributeUserEdited | undefined) => {
            const updatedState: Partial<AttributeUserEdited> = {
                [name]: value,
            };

            if (prev && name === 'attributeName') {
                const matchedAttribute = genAiRows?.find(row => row.values.includes(value));
                if (matchedAttribute) {
                    updatedState.attributeDescription = matchedAttribute.values[4];
                } else {
                    updatedState.attributeDescription = '';
                }
            }

            return {
                ...prev,
                ...updatedState,
            } as AttributeUserEdited; // Type assertion to AttributeUserEdited type
        });
    };


    return (
        <>
            <ActionPage>
                <>
                    <>
                        <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                        >
                            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Typography variant="h3" color="primary" gutterBottom>
                                    Remap to Existing Entity
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={1.5}>
                                <Button
                                    variant="outlined"
                                    onClick={onClickBack}
                                    startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
                                >
                                    Back
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={2} sx={{ width: '100%' }}>
                            <Grid item xs={15} md={10} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <Grid item container flexDirection="column" xs={15} md={7}>
                                    <Grid item mb={1.5}>
                                        <Typography color="text.primary">Table Name</Typography>
                                        <TextField
                                            fullWidth
                                            id="current-table-id"
                                            placeholder="Current Table Name"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={true}
                                            value={dataTableName ?? ''}
                                        />
                                    </Grid>
                                    <Grid mb={1.5}>
                                        <Typography color="text.primary">Current Entity Name</Typography>
                                        <TextField
                                            fullWidth
                                            id="current-entity-id"
                                            placeholder="Current Entity Name"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={true}
                                            value={currentEntityName ?? ''}
                                        />
                                    </Grid>
                                    <>
                                        <Typography color="text.primary">
                                            Current Entity Description
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="current-entity-description"
                                            placeholder="Current Entity Description"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            disabled={true}
                                            rows={2}
                                            multiline
                                            value={entity[0]?.description ?? ''}
                                        />
                                    </>
                                </Grid>
                                <Grid item container flexDirection="column" xs={15} md={10}>
                                    <div>
                                        <Typography color="text.primary">
                                            <span style={{ color: 'red' }}>*</span> Existing Entity List
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="edit-new-entity-id"
                                            placeholder="New Entity Name"
                                            variant="outlined"
                                            color="secondary"
                                            size="small"
                                            onChange={({ target }) => onChangeNewEntity('dataEntityName', target.value)}
                                            value={newEntity?.dataEntityName ?? ''}
                                            error={!isValidEntry(newEntity?.dataEntityName)}
                                            helperText={!isValidEntry(newEntity?.dataEntityName) ? 'Invalid Entry' : ''}
                                            select
                                        >
                                            {entities.map((e) => (
                                                <MenuItem key={e.id} value={e.dataEntityName}>
                                                    {e.dataEntityName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <Typography color="text.primary">
                                            <span style={{ color: 'red' }}>*</span> Entity Description
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="outlined-basic"
                                            placeholder="Existing entity description"
                                            variant="outlined"
                                            color="secondary"
                                            size="medium"
                                            rows={2}
                                            multiline
                                            value={newEntity?.description ?? ''}
                                            disabled
                                        />
                                    </div>
                                    <Grid item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2} mb={2}>
                                        <LoadingButton
                                            variant="outlined"
                                            loading={loading}
                                            onClick={handleSubmit}
                                            disabled={disableSubmit}
                                        >
                                            Remap To Above Entity Attributes
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            justifyContent={'space-between'}
                            mt={2}
                        >
                            <Grid item xs={15}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }}
                                spacing={1}
                            >
                                {profileAttributes && profileAttributes.length && (
                                    <>
                                        <>
                                            <Grid item container flexDirection="column" xs={15} md={12} width='100%'>
                                                {errorMessage && (
                                                    <center>
                                                        <Typography
                                                            variant='h4'
                                                            color={'error'}
                                                            mb={2}
                                                        >
                                                            {errorMessage}
                                                        </Typography>
                                                    </center>
                                                )
                                                }
                                                <ShadowTable
                                                    rows={leftRows}
                                                    columns={leftTableColumns}
                                                    tableActionParams={{
                                                        onButtonClick: onButtonClick,
                                                        actions: [
                                                            ShadowTableAction.Editable
                                                        ],
                                                    }}
                                                    dropDownOptions={attributeOptions}
                                                />
                                                <Grid item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2} mb={2}>
                                                    <LoadingButton
                                                        loading={confirmLoading}
                                                        variant="outlined"
                                                        onClick={pageConfirm}
                                                        disabled={!genAIGeneratedData?.columns.length || editing}
                                                    >
                                                        Confirm
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </>
                </>
            </ActionPage>
            <LucidPopUp
                openPopUp={editing}
                headingText={<Typography variant="h4">Editing Suggested Mappings</Typography>}
                closePopup={closeEditingPopup}
                onConfirm={saveChanges}
                disabledConfirm={!atleastOneEdit}
                confirmText='Save'
                cancelText='Cancel'
            >
                <Grid container direction="column" justifyContent="center" alignItems="left" spacing={2}>
                    <Grid item xs={10} md={7}>
                        <Typography color="text.primary">
                            <span style={{ color: 'red' }}>*</span> Attribute Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="current-table-id"
                            placeholder="Current Table Name"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            value={userEditedAttribute?.attributeName ?? ''}
                            onChange={({ target }) => onChangeNewAttribute('attributeName', target.value)}
                            select
                        >
                            {genAIGeneratedData?.attributes.map((attr: string, index) => (
                                <MenuItem key={index} value={attr.replace(/\s/g, '')}>
                                    {attr.replace(/\s/g, '')}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={10} md={7}>
                        <Typography color="text.primary">
                            <span style={{ color: 'red' }}>*</span> Attribute Description
                        </Typography>
                        <TextField
                            fullWidth
                            id="current-entity-id"
                            placeholder="Current Entity Name"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            rows={3}
                            multiline
                            value={userEditedAttribute?.attributeDescription ?? ''}
                            disabled
                        />
                    </Grid>
                </Grid>
            </LucidPopUp>
        </>
    )
}
