import { addNotebookRequest } from 'businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const dataPath = {
  getCanonicalData: 'GetData/getCanonicalData',
  getEnrichedData: 'GetData/getEnrichedData',
  getSemanticData: 'GetData/getSemanticData',
  getSourceFileMetaData:'GetData/getSourceFileMetadata',
  addNotebook:'GetData/addNotebook'
}

export const getCanonicalData = async (
  token: string,
  dataPodID: string,
  dataEntityID?: number,
  dataEntityName?: string,
  pageNumber?: number,
) => {
  let url = dataPath.getCanonicalData
  if (dataPodID && dataEntityID) {
    url += `?dataPodId=${dataPodID}&entityId=${dataEntityID}`
  } else if (dataPodID) {
    url += `?dataPodId=${dataPodID}`
  } else if (dataEntityName) {
    url += `?entityName=${dataEntityName}`
  } else if (pageNumber) {
    url += `?PageNumber=${pageNumber}`
  }
  return sendRequest(url, 'GET', token)
}

export const getEnrichedData = async (
  token: string,
  dataPodID: string,
  dataEntityID?: number,
  dataEntityName?: string,
  pageNumber?: number,
) => {
  let url = dataPath.getEnrichedData
  if (dataPodID && dataEntityID) {
    url += `?dataPodId=${dataPodID}&entityId=${dataEntityID}`
  } else if (dataPodID) {
    url += `?dataPodId=${dataPodID}`
  } else if (dataEntityName) {
    url += `?entityName=${dataEntityName}`
  } else if (pageNumber) {
    url += `?PageNumber=${pageNumber}`
  }
  return sendRequest(url, 'GET', token)
}

export const getSemanticData = async (
  token: string,
  dataPodID: string,
  measureId?: number,
  measureName?: string,
  pageNumber?: number,
) => {
  let url = dataPath.getSemanticData
  if (dataPodID && measureId) {
    url += `?dataPodId=${dataPodID}&measureId=${measureId}`
  } else if (dataPodID) {
    url += `?dataPodId=${dataPodID}`
  } else if (measureName) {
    url += `?MeasureName=${measureName}`
  } else if (pageNumber) {
    url += `?PageNumber=${pageNumber}`
  }
  return sendRequest(url, 'GET', token)
}

export const getSourceFileMetaData = async (
  token: string,
  dataPodID: string,
  connectionID:number
) => {
  let url = dataPath.getSourceFileMetaData
 if (dataPodID && connectionID) {
   url += `?dataPodId=${dataPodID}&connectionID=${connectionID}`
  }
  else if (dataPodID && connectionID){
     url += `?dataPodId=${dataPodID}`
  }
  return sendRequest(url, 'GET', token)
}

export const addNotebook = async (
  token: string,
  dataPodID: string,
  body:addNotebookRequest,
  connectionId?: number,
) => {
  let url = dataPath.addNotebook
  if(dataPodID){
    url += `?dataPodId=${dataPodID}`
  } 
  if(connectionId) {
    url += `&connectionId=${connectionId}`
  }

  return sendRequest(url, 'POST', token, body)
}

export const dataLoads = {
  getCanonicalData,
  getEnrichedData,
  getSemanticData,
  getSourceFileMetaData,
  addNotebook
}
